import React, {useState,useEffect} from "react";
import {EtTable} from "../shared/table/table";
import {axiosInstance} from "../../config/apiTemplate";
import CircularProgress from '@mui/material/CircularProgress';

export default function CapChangeLog(props){

    const [logs,setLogs] = useState([]);
    const [capchange, setCapchange] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(()=>{
        document.title = "EPM - Capital Change Recycle Bin";

        setLoading(true);
        getLogs();

        return () =>{
            setLoading(false);  
        }
    },[props.trading_id,props.ts]);

    async function getLogs(){
        let headerForm = new FormData();
  
        headerForm.append("t_id", props.trading_id);
        const url = "api/bin/capchange.php";
    
        try {
          const response = await axiosInstance.post(url, headerForm);
          if (response.data.statusCode === 201) {
            let logs = response.data.logs;
            let capchange = response.data.capchange;
            setLogs(logs);
            setCapchange(capchange);
          }else if(response.data.statusCode === 500){
            getLogs();
          }
         setLoading(false);
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
    }

    function renderLoading(){
        return(
            <div className="text-center">
                <CircularProgress />
                <p>Fetching Data...</p>
            </div>
        )
    }

    async function deleteLog(id){
        let resp = window.confirm("Are you sure to recover this log entry?");
        if(resp){
            //delete log
        }
    }

    return(
        <div className="container-fluid pt-3">
            <div className="row">
                <div className="col">
                    <div className="card card-body">
                        {isLoading? renderLoading():<div className="container-fluid">
                            <br/>
                            <EtTable 
                                data={logs}
                                sortable={true}
                                headers={[
                                    {display:"No",sort:"no"},
                                    {display:"Contract Type",sort:"contract_type"},
                                    {display:"Contract Date",sort:"contract_date_clean"},
                                    {display:"Stock Name",sort:"stock_name"},
                                    // {display:"Quantity",sort:"quantity_clean"},
                                    {display:"Price",sort:"price_clean"},
                                    {display:"Capital Change Type",sort:"capChangeType",style:{minWidth:140}},
                                    {display:"Ratio"},
                                    {display:"Remarks",sort:"remarks"},
                                    {display:"Recover",sort:"remove"},
                                ]}
                                displayFieldKeys={[
                                    {key:"no"},
                                    {key:"contract_type",styleKey:"contract_type_color"},
                                    {key:"contract_date"},
                                    {key:"stock_name"},
                                    // {key:"quantity"},
                                    {key:"price",prepend:"RM "},
                                    {key:"capChangeType"},
                                    {key:"ratio"},
                                    {key:"remarks"},
                                    {key:"remove",style:{"fontWeight":"bold",color:"green"},isClickable:true,clickAction:deleteLog,clickProp:{"token":"removeToken"}},
                                ]}
                                showPagination={true}
                            />
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );


}