import React, {useState, useEffect} from "react";
import "./style.css";
import { axiosInstance } from "../../config/apiTemplate";
import { Modal,Button } from "react-bootstrap";
import QRCode from "react-qr-code";

function MfaOnBoard(props){

    let [qrUrl, setQrUrl ] = useState("");
    let [sk, setSk] = useState("");
    let [mfaInput, setMfaInput] = useState("");
    let [showModal, setShowModal] = useState(new Date().getTime() >= 1697385600000 ? true : false);
    let [isLoading, setLoading] = useState(false);

    useEffect(()=>{
        getUrl();
    },[]);

    async function getUrl(){

        const url = "client_api/account/get2FA.php";

        try{
            let response = await axiosInstance.post(url);
            if(response.data.status === 201){
                setQrUrl(response.data.data);
                setSk(response.data.secret_key);
            }
        }catch (e){
            console.log(e);
        }
    }

    async function authMfa(){
        setLoading(true);
        if(mfaInput === ""){
            window.alert("Cannot be empty!");
            setLoading(false);
        }else{
            let loginForm = new FormData();
            loginForm.append("inc_code", mfaInput);
            loginForm.append("isInitial", "true");
            const url = "client_api/account/verify2fa.php";
      
            try {
              const response = await axiosInstance.post(url, loginForm);
              if (response.data.status === 201) {
                if(response.data.authenticated){
                    setShowModal(false);
                    localStorage.setItem("mfa_boarded", "true");
                    props.parentProp(new Date().getTime());
                }else{
                    window.alert("Incorrect 2FA code supplied! Please input a correct one");
                }
              } else {
                window.alert(response.data.message);
                setLoading(false);
              }
            } catch (e) {
              console.log(e);
              setLoading(false);
            }
        }
      }

    function renderModal(){
        return(
            <Modal 
                backdrop="static"
                keyboard={false}
                show={showModal} onHide={() => setShowModal(false)}
            >
                <Modal.Header>
                <Modal.Title>Onboard 2FA</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>Step 1: Please download and install the Google Authenticator app on your mobile device</h6>
                    <div style={{textAlign: 'center'}}>
                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" rel="noopener noreferrer">
                            <img style={{width:'38%'}} src="https://portfolio.et-invest.com/static/media/google-play-badge.png" alt="Google Play Store" />
                        </a>
                        &nbsp;
                        <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noopener noreferrer">
                            <img style={{width:'38%'}} src="https://portfolio.et-invest.com/static/media/app-store-badge.png" alt="App Store" />
                        </a>
                    </div>
                    <hr />
                    <h6>Step 2: Please scan the QR code below using Authenticator App</h6>
                    <div style={{textAlign:"center"}}>
                        {/* <img src={qrUrl} alt="2fa" style={{width:"50%"}}/> */}
                        <QRCode
                            style={{ height: "auto", maxWidth: "50%", width: "50%" }}
                            value={qrUrl}
                            
                        />
                    </div>
                    <div className="text-center">
                        <p>OR</p>
                        <p>Copy the key below to paste into your Authenticator App</p>    
                        <p className="boldText">{sk}</p>
                    </div>
                    <hr/>
                    <h6>Step 3: Please insert the 6 digits code in Google Authenticator</h6>
                    <div className='form-group'>
                        <label htmlFor='2fa'>
                        2FA Code<span className='mandatory'>*</span>:
                        </label>
                        <input
                        type='text'
                        className='form-control'
                        autoComplete='2fa'
                        value={mfaInput}
                        onChange={(e) => {
                            setMfaInput(e.target.value);
                        }}
                        />
                    </div>
                  
                </Modal.Body>
                <Modal.Footer>
                {/* <Button variant='secondary' onClick={() => setShowModal(false)}>
                    Close
                </Button> */}
                <Button variant='primary' onClick={() => authMfa()}>
                    Submit
                </Button>
                </Modal.Footer>
            </Modal>
        )
    }


    return(
        <>
            <div className="mfaBanner" onClick={()=>setShowModal(true)}>
                <div style={{padding:10}} className="text-center">
                    <p className="marginBottomZero text-white boldText">Starting from 16 October 2023, we will be enforcing Two-Factor Authentication (2FA) for all user accounts.<br/>This added layer of security will further safeguard your personal information and enhance your overall experience on our platform.<br/>Please click here to enable.</p>
                </div>
            </div>
            {renderModal()}
        </>
    )
}

export default MfaOnBoard
