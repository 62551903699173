import React, {useState,useEffect} from "react";
import {EtTable} from "../shared/table/table";
import {axiosInstance} from "../../config/apiTemplate";
import CircularProgress from '@mui/material/CircularProgress';
import { DataGridPro,GridToolbarContainer,GridToolbarColumnsButton,GridToolbarFilterButton,GridToolbarExport,GridToolbarDensitySelector, GridOverlay,GridToolbar } from "@mui/x-data-grid-pro";
import LinearProgress  from "@mui/material/LinearProgress";
import { renderDate, formatToDateObj } from "../shared/util";
export default function InterestLog(props){

    const [logs,setLogs] = useState([]);
    const [response, setResponse] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(()=>{
        document.title = "ETSW - Interest Logs";

        setLoading(true);
        getLogs();

        return () =>{
            setLoading(false);  
        }
    },[props.trading_id,props.ts]);

    async function getLogs(){
        let headerForm = new FormData();
  
        const url = "client_api/logs/getInterest.php";
    
        try {
          const response = await axiosInstance.post(url, headerForm);
          if (response.data.status === 201) {
            let logs = response.data.interest.table;
            logs = logs.map((item,index)=>{
                return {...item,no:(index+1),id:index};
            });
            setLogs(logs);
            setResponse(response.data.interest.header);
          }
         setLoading(false);
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
    }

    function renderLoading(){
        return(
            <div className="text-center">
                <CircularProgress />
                <p>Fetching Data...</p>
            </div>
        )
    }

    function renderCustomToolbar(){
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    function renderNoRows(){
        return(
            <GridOverlay>
                <div>
                    <h6 style={{fontStyle:"italic"}}>No Transactions....</h6>
                </div>
            </GridOverlay>
        )
    }

    function loadingOverlay(){
        return(
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
                </div>
            </GridOverlay>
        )
    }

    function renderBoldText(props){
        if(props.field === "interest_received"){
            return(
                <span className="boldText" style={{fontSize:18,width:"100%"}}>RM {props.value}</span>
            );
        }else{
            return(
                <span className="boldText" style={{fontSize:18,width:"100%"}}>{props.value}</span>
            );
        }
    }

  
    return(
        <div className="container-fluid pt-3">
            <div className="row">
                <div className="col">
                    <div className="card card-body">
                    {isLoading ?renderLoading():<div className="container-fluid">
                            <div className="row justify-content-end">
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="row align-items-center justify-content-end">
                                        <div className="col">
                                            <h6 className="statusCardHeaderTitle float-right">Total Earnings</h6>
                                        </div>
                                        <div className="col">
                                            <div className="chipGreen float-right">
                                                <h6 className="marginBottomZero boldText">+ RM {response}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                     
                            <div style={{minHeight:"65vh",display:"flex"}}>
                            <div style={{ flexGrow: 1, backgroundColor: "white", marginTop: 20 }}>
                                <DataGridPro
                                    density="compact"
                                    loading={isLoading}
                                    columns={[
                                    {
                                        field: "no",
                                        headerName: "No.",
                                        flex: 1,
                                    },
                                    {
                                        field: "date_received",
                                        headerName: "Date",
                                        minWidth: 200,
                                        flex: 2,
                                        type:"date",
                                        renderCell:renderDate,
                                        valueGetter:({value})=>value && formatToDateObj(value)
                                    },
                                    {
                                        field: "interest_received",
                                        headerName: "Earnings",
                                        minWidth: 140,
                                        flex: 2,
                                        renderCell:renderBoldText
                                    },
                                    ]}
                                    pagination
                                    rows={logs}
                                    components={{
                                        Toolbar: GridToolbar,
                                        LoadingOverlay: loadingOverlay,
                                        NoRowsOverlay: renderNoRows
                                    }}
                                    componentsProps={{
                                        toolbar: {
                                          showQuickFilter: true,
                                          quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                />
                                </div></div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );


}