import React from "react";

export const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);
  
    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
            if(sortConfig.key === "quantity_clean" || 
            sortConfig.key === "pi_clean" || 
            sortConfig.key === "auc_clean" || 
            sortConfig.key === "boi_clean" || 
            sortConfig.key === "market_value_clean" || 
            sortConfig.key === "percent_pl" || 
            sortConfig.key === "pnl_clean" || 
            sortConfig.key === "pe" || 
            sortConfig.key === "dy" || 
            sortConfig.key === "current_price" || 
            sortConfig.key === "free_shares_clean"){
                a[sortConfig.key] = Number(a[sortConfig.key]);
                b[sortConfig.key] = Number(b[sortConfig.key]);
            }
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);
  
    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };
  
    return { items: sortedItems, requestSort, sortConfig };
  };

  export const getClassNamesFor = (name, sortConfig) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  