import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Screen from "../shared/screen";
import "./style.css";
import { axiosInstance } from "../../config/apiTemplate";
import Skeleton from "@mui/material/Skeleton";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { stripCommaToNum } from "../shared/util";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

am4core.useTheme(am4themes_animated);

function DashboardPage() {
  const cashRatioChart = useRef(null);
  const navChart = useRef(null);
  const [toggles, setToggle] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [isLoading, setLoading] = useState(true);
  const [portfolio, setPortfolio] = useState(localStorage.getItem("t_id"));
  const [dashboard, setDashboard] = useState({
    top_shareholdings: [],
    latest_trades: [],
    latest_dividends: [],
    chart_data: [],
    portfolio_cash_ratio: { cash: 10, stocks: 100 },
    daily_change: {
      klci_change_perc: 0,
      portfolio_change: 0,
      portfolio_change_perc: 0,
      today_value: 0,
      yesterday_value: 0,
    },
  });
  const [aggregatedData, setAggregatedData] = useState({
    initialTpv:0,
    currentTpv:0,
    abs_return:0,
    abs_return_percentage:0,
    aggregatedTPV:[],
    unconfirmed_deposit:0
  });
  let [tabIndex, setTabIndex] = useState(0);
  let productView = localStorage.getItem("productView");
  let client_code = localStorage.getItem("clientCode");

  useEffect(() => {
    document.title = "ETSW - Portfolio Dashboard";

    // Sleep 1 second
    setTimeout(() => {
      getDashboardData();
      getAggregatedData();
    }, 1000);

    // getDashboardData();
    // getAggregatedData();
  }, [portfolio]);

  useLayoutEffect(() => {
    if(tabIndex === 1){
        let x = am4core.create("cashRatio", am4charts.PieChart);
        x.padding(10, 15, 0, 15);
        x.data = [
          {
            type: "Cash",
            value: Number(dashboard.portfolio_cash_ratio.cash),
            color: am4core.color("#191d63"),
          },
          {
            type: productView === "Snowball" ? "Equities" : productView === "Digital Asset Tracker" ? "Digital Asset" : "Equities",
            value: Number(dashboard.portfolio_cash_ratio.stocks),
            color: am4core.color("#00A3E0"),
          },
        ];
    
        // Add and configure Series
        var pieSeries = x.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "type";
    
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;
        // change the cursor on hover to make it apparent the object can be interacted with
        pieSeries.slices.template.cursorOverStyle = [
          {
            property: "cursor",
            value: "pointer",
          },
        ];
    
        pieSeries.alignLabels = false;
        pieSeries.labels.template.bent = false;
        //pieSeries.labels.template.radius = 3;
        pieSeries.labels.template.padding(0, 0, 0, 0);
    
        pieSeries.ticks.template.disabled = true;
    
        // Create a base filter effect (as if it's not there) for the hover to return to
        var shadow = pieSeries.slices.template.filters.push(
          new am4core.DropShadowFilter()
        );
        shadow.opacity = 0;
    
        // Create hover state
        var hoverState = pieSeries.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists
    
        // Slightly shift the shadow and make it more prominent on hover
        var hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
        hoverShadow.opacity = 0.7;
        hoverShadow.blur = 5;
    
        pieSeries.slices.template.propertyFields.fill = "color";
    
        cashRatioChart.current = x;
        return () => {
            x.dispose();
          };
    }
  }, [dashboard,tabIndex]);

  useLayoutEffect(() => {
    if(tabIndex=== 1){
        let chart = am4core.create("chartdiv", am4charts.XYChart);

        chart.padding(0, 15, 0, 15);
        chart.colors.step = 3;
        chart.dateFormatter.dateFormat = "yyyy-MM-dd";
        var data = dashboard.chart_data;
    
        chart.data = data;
        // the following line makes value axes to be arranged vertically.
        chart.leftAxesContainer.layout = "vertical";
    
        // uncomment this line if you want to change order of axes
        //chart.bottomAxesContainer.reverseOrder = true;
    
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.ticks.template.length = 8;
        dateAxis.renderer.ticks.template.strokeOpacity = 0.1;
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.ticks.template.disabled = false;
        dateAxis.renderer.ticks.template.strokeOpacity = 0.2;
        dateAxis.renderer.minLabelPosition = 0.01;
        dateAxis.renderer.maxLabelPosition = 0.99;
        dateAxis.keepSelection = true;
        dateAxis.dateFormats.setKey("day", "MMM d, yyyy");
        dateAxis.groupData = true;
        dateAxis.minZoomCount = 5;
    
        // these two lines makes the axis to be initially zoomed-in
        // dateAxis.start = 0.7;
        // dateAxis.keepSelection = true;
    
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.zIndex = 1;
        valueAxis.renderer.baseGrid.disabled = true;
        // height of axis
        valueAxis.height = am4core.percent(65);
    
        valueAxis.renderer.gridContainer.background.fill = am4core.color("#000000");
        valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
        valueAxis.renderer.inside = true;
        valueAxis.renderer.labels.template.verticalCenter = "bottom";
        valueAxis.renderer.labels.template.padding(2, 2, 2, 2);
    
        //valueAxis.renderer.maxLabelPosition = 0.95;
        valueAxis.renderer.fontSize = "0.8em";
    
        var series1 = chart.series.push(new am4charts.LineSeries());
        series1.dataFields.dateX = "date";
        series1.dataFields.valueY = "klci";
        series1.tooltipText =
          "{name}: {valueY.formatNumber('[#0c0]+#.00|[#c00]#.##|0')}%";
        series1.name = "KLCI";
        series1.strokeWidth = 3;
        series1.tooltip.getFillFromObject = false;
        series1.tooltip.getStrokeFromObject = true;
        series1.tooltip.background.fill = am4core.color("#fff");
        series1.tooltip.background.strokeWidth = 2;
        series1.stroke = am4core.color("#ff1616");
        series1.tooltip.label.fill = series1.stroke;
    
        var series2 = chart.series.push(new am4charts.LineSeries());
        series2.dataFields.dateX = "date";
        series2.dataFields.valueY = "nav_sst";
        series2.tooltipText =
          "{name}: {valueY.formatNumber('[#0c0]+#.00|[#c00]#.##|0')}%";
        series2.name = "TOTAL PORTFOLIO RETURN";
        series2.strokeWidth = 3;
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.getStrokeFromObject = true;
        series2.tooltip.background.fill = am4core.color("#fff");
        series2.tooltip.background.strokeWidth = 2;
        series2.stroke = am4core.color("#00A3E0");
        series2.tooltip.label.fill = series2.stroke;
    
        var series3 = chart.series.push(new am4charts.LineSeries());
        series3.dataFields.dateX = "date";
        series3.dataFields.valueY = "fbm100";
        series3.tooltipText =
          "{name}: {valueY.formatNumber('[#0c0]+#.00|[#c00]#.##|0')}%";
        series3.name = "FBMT100";
        series3.strokeWidth = 3;
        series3.tooltip.getFillFromObject = false;
        series3.tooltip.getStrokeFromObject = true;
        series3.tooltip.background.fill = am4core.color("#fff");
        series3.tooltip.background.strokeWidth = 2;
        series3.stroke = am4core.color("#FFA500");
        series3.tooltip.label.fill = series3.stroke;
    
        chart.legend = new am4charts.Legend();
        chart.cursor = new am4charts.XYCursor();
    
        navChart.current = chart;
    
        // Chart for DAT
        let chart2 = am4core.create("chartdiv2", am4charts.XYChart);

        chart2.padding(0, 15, 0, 15);
        chart2.colors.step = 3;
        chart2.dateFormatter.dateFormat = "yyyy-MM-dd";
        var data = dashboard.chart_data;
    
        chart2.data = data;
        // the following line makes value axes to be arranged vertically.
        chart2.leftAxesContainer.layout = "vertical";
    
        // uncomment this line if you want to change order of axes
        //chart.bottomAxesContainer.reverseOrder = true;
    
        var dateAxis = chart2.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.ticks.template.length = 8;
        dateAxis.renderer.ticks.template.strokeOpacity = 0.1;
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.ticks.template.disabled = false;
        dateAxis.renderer.ticks.template.strokeOpacity = 0.2;
        dateAxis.renderer.minLabelPosition = 0.01;
        dateAxis.renderer.maxLabelPosition = 0.99;
        dateAxis.keepSelection = true;
        dateAxis.dateFormats.setKey("day", "MMM d, yyyy");
        dateAxis.groupData = true;
        dateAxis.minZoomCount = 5;
    
        // these two lines makes the axis to be initially zoomed-in
        // dateAxis.start = 0.7;
        // dateAxis.keepSelection = true;
    
        var valueAxis = chart2.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.zIndex = 1;
        valueAxis.renderer.baseGrid.disabled = true;
        // height of axis
        valueAxis.height = am4core.percent(65);
    
        valueAxis.renderer.gridContainer.background.fill = am4core.color("#000000");
        valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
        valueAxis.renderer.inside = true;
        valueAxis.renderer.labels.template.verticalCenter = "bottom";
        valueAxis.renderer.labels.template.padding(2, 2, 2, 2);
    
        //valueAxis.renderer.maxLabelPosition = 0.95;
        valueAxis.renderer.fontSize = "0.8em";
    
        var series1 = chart2.series.push(new am4charts.LineSeries());
        series1.dataFields.dateX = "date";
        series1.dataFields.valueY = "btc";
        series1.tooltipText =
          "{name}: {valueY.formatNumber('[#0c0]+#.00|[#c00]#.##|0')}%";
        series1.name = "BITCOIN RETURN";
        series1.strokeWidth = 3;
        series1.tooltip.getFillFromObject = false;
        series1.tooltip.getStrokeFromObject = true;
        series1.tooltip.background.fill = am4core.color("#fff");
        series1.tooltip.background.strokeWidth = 2;
        series1.stroke = am4core.color("#ff1616");
        series1.tooltip.label.fill = series1.stroke;
    
        var series2 = chart2.series.push(new am4charts.LineSeries());
        series2.dataFields.dateX = "date";
        series2.dataFields.valueY = "nav_sst";
        series2.tooltipText =
          "{name}: {valueY.formatNumber('[#0c0]+#.00|[#c00]#.##|0')}%";
        series2.name = "TOTAL PORTFOLIO RETURN";
        series2.strokeWidth = 3;
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.getStrokeFromObject = true;
        series2.tooltip.background.fill = am4core.color("#fff");
        series2.tooltip.background.strokeWidth = 2;
        series2.stroke = am4core.color("#00A3E0");
        series2.tooltip.label.fill = series2.stroke;
    
        chart2.legend = new am4charts.Legend();
        chart2.cursor = new am4charts.XYCursor();
    
        navChart.current = chart2;

        return () => {
          chart.dispose();
          chart2.dispose();
        };
    } else if (tabIndex === 0 ){
        let chart = am4core.create("chartdivTpv", am4charts.XYChart);

        chart.padding(0, 15, 0, 15);
        chart.colors.step = 3;
        chart.dateFormatter.dateFormat = "yyyy-MM-dd";
        var tpvData = aggregatedData.aggregatedTPV;
    
        chart.data = tpvData;
        // the following line makes value axes to be arranged vertically.
        chart.leftAxesContainer.layout = "vertical";
    
        // uncomment this line if you want to change order of axes
        //chart.bottomAxesContainer.reverseOrder = true;
    
        var dateAxisTpv = chart.xAxes.push(new am4charts.DateAxis());
        dateAxisTpv.renderer.grid.template.location = 0;
        dateAxisTpv.renderer.ticks.template.length = 8;
        dateAxisTpv.renderer.ticks.template.strokeOpacity = 0.1;
        dateAxisTpv.renderer.grid.template.disabled = true;
        dateAxisTpv.renderer.ticks.template.disabled = false;
        dateAxisTpv.renderer.ticks.template.strokeOpacity = 0.2;
        dateAxisTpv.renderer.minLabelPosition = 0.01;
        dateAxisTpv.renderer.maxLabelPosition = 0.99;
        dateAxisTpv.keepSelection = true;
        dateAxisTpv.dateFormats.setKey("day", "MMM d, yyyy");
        dateAxisTpv.groupData = true;
        dateAxisTpv.minZoomCount = 5;
    
        // these two lines makes the axis to be initially zoomed-in
        // dateAxis.start = 0.7;
        // dateAxis.keepSelection = true;
    
        var valueAxisTpv = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxisTpv.tooltip.disabled = true;
        valueAxisTpv.zIndex = 1;
        valueAxisTpv.renderer.baseGrid.disabled = true;
        // height of axis
        valueAxisTpv.height = am4core.percent(65);
    
        valueAxisTpv.renderer.gridContainer.background.fill = am4core.color("#000000");
        valueAxisTpv.renderer.gridContainer.background.fillOpacity = 0.05;
        valueAxisTpv.renderer.inside = true;
        valueAxisTpv.renderer.labels.template.verticalCenter = "bottom";
        valueAxisTpv.renderer.labels.template.padding(2, 2, 2, 2);
    
        //valueAxis.renderer.maxLabelPosition = 0.95;
        valueAxisTpv.renderer.fontSize = "0.8em";
        
        if(aggregatedData.aggregatedTPV.length > 0){
            let firstData = aggregatedData.aggregatedTPV[0];
            let keys = Object.keys(firstData);
            let newSeriesArray = [];
            newSeriesArray = keys.filter((item)=>item !== "date");

            newSeriesArray.map((item)=>{
                var seriesTpv = chart.series.push(new am4charts.LineSeries());
                seriesTpv.dataFields.dateX = "date";
                seriesTpv.dataFields.valueY = item;
                seriesTpv.tooltipText = "{name}: {valueY.formatNumber('RM #,###.##')}";
                seriesTpv.name = item.toUpperCase();
                seriesTpv.strokeWidth = 3;
                seriesTpv.tooltip.getFillFromObject = false;
                seriesTpv.tooltip.getStrokeFromObject = true;
                seriesTpv.tooltip.background.fill = am4core.color("#fff");
                seriesTpv.tooltip.background.strokeWidth = 2;
                // seriesTpv.stroke = am4core.color("#ff1616");
                seriesTpv.tooltip.label.fill = seriesTpv.stroke;
            });
        }
    
        chart.legend = new am4charts.Legend();
        chart.cursor = new am4charts.XYCursor();
    
        navChart.current = chart;
    
        return () => {
          chart.dispose();
        };
    }
  }, [dashboard,tabIndex, aggregatedData]);

  //  API ========================================================================================================================

  async function getDashboardData() {
    let dashboardForm = new FormData();

    dashboardForm.append("t_id", portfolio);
    const url = "client_api/dashboard/getDashboardData_copy.php";

    try {
      const response = await axiosInstance.post(url, dashboardForm);
      if (response.data.status === 201) {
        console.log(response.data);
        setDashboard(response.data);
      } else if (response.data.status === 500) {
        getDashboardData();
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  async function getAggregatedData(){
    const url = "client_api/dashboard/getAggregated_copy.php";
    try {
        const response = await axiosInstance.post(url);
        if (response.data.status === 201) {
          const temp = {
            initialTpv:response.data.initial_value,
            currentTpv:response.data.curr_value,
            abs_return:response.data.difference,
            abs_return_percentage:response.data.percentage,
            aggregatedTPV: response.data.aggregatedTPV,
            unconfirmed_deposit: response.data.unconfirmed_deposit
          };
          setAggregatedData(temp);
        } 
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
  }

  //  FUNCTIONS ========================================================================================================================

  function getTotalShareholdings() {
    if (dashboard.top_shareholdings.length > 0)
      return dashboard.top_shareholdings
        .reduce((a, b) => a + Number(b.portfolio_percent), 0)
        .toFixed(2);

    return 0;
  }

  // RENDER FUNCTIONS  ========================================================================================================================

  function renderBody() {
    return (
      <div className='container-fluid pt-4'>
        {productView === "Snowball" && renderHeaderChart()}
        {productView === "Digital Asset Tracker" && renderHeaderChartDAT()}
        <br />
        {renderHeaderTables()}
      </div>
    );
  }

  function renderHeaderChart() {
    return (
      <div className='row'>
        <div className='col'>
          <div className='container-fluid'>
            <div
              className='statusCardHeader row align-items-center justify-content-between'
              onClick={() => {}}>
              <div className='col'>
                <h6 className='statusCardHeaderTitle'>
                  PORTFOLIO RETURN VS FBMKLCI RETURN VS FBMT100 RETURN
                </h6>
              </div>
            </div>
            <div className='row'>
              <div
                className='col bg-white'
                style={{ marginLeft: 15, marginRight: 15, minHeight: 300 }}>
                <div style={{ float: "right" }}>
                  <p
                    style={{ marginBottom: 6, marginRight: 15, marginTop: 3, fontSize: 13 }}
                    className='boldText'>
                    LAST UPDATED DATE: {dashboard.last_updated_date}
                  </p>
                </div>
                <br />
                <div>
                  {dashboard.chart_data.length === 0
                  ?
                    <div className="text-center" style={{paddingTop:30,paddingBottom:15}}>
                      <h1 style={{color:'rgba(0, 0, 0, 0.6)'}}>No Data</h1>
                    </div>
                  :
                    <div
                      id='chartdiv'
                      style={{ width: "100%", minHeight: 500, height: "100%" }}
                    />
                  }
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderHeaderChartDAT() {
    return (
      <div className='row'>
        <div className='col'>
          <div className='container-fluid'>
            <div
              className='statusCardHeader row align-items-center justify-content-between'
              onClick={() => {}}>
              <div className='col'>
                <h6 className='statusCardHeaderTitle'>
                  PORTFOLIO RETURN VS BITCOIN RETURN
                </h6>
              </div>
            </div>
            <div className='row'>
              <div
                className='col bg-white'
                style={{ marginLeft: 15, marginRight: 15, minHeight: 300 }}>
                <div style={{ float: "right" }}>
                  <p
                    style={{ marginBottom: 6, marginRight: 15, marginTop: 3, fontSize: 13 }}
                    className='boldText'>
                    LAST UPDATED DATE: {dashboard.last_updated_date}
                  </p>
                </div>
                <br />
                <div>
                  {dashboard.chart_data.length === 0
                  ?
                    <div className="text-center" style={{paddingTop:30,paddingBottom:15}}>
                      <h1 style={{color:'rgba(0, 0, 0, 0.6)'}}>No Data</h1>
                    </div>
                  :
                    <div
                      id='chartdiv2'
                      style={{ width: "100%", minHeight: 500, height: "100%" }}
                    />
                  }
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderHeaderTables() {
    return (
      <div className='row'>
        <div className='col'>
          <div className='container-fluid'>
            <div className='row d-flex'>
              <div className='col-lg-4 col-md-6 col-sm-12  mb-3 flex-fill'>
                <div
                  className='statusCardHeader row align-items-center justify-content-between'
                  onClick={() => {
                    toggles[0] = !toggles[0];
                    setToggle([...toggles]);
                  }}>
                  <div className='col'>
                    <h6 className='statusCardHeaderTitle'>
                      PORTFOLIO SUMMARY
                    </h6>
                  </div>
                </div>
                {!toggles[0] && (
                  <div className='row card-body bg-white headerTableContentHeight'>
                    <table className='table'>
                      <tbody>
                        <tr>
                          <td className="text-left">{productView === "Snowball" ? "Equities" : productView === "Digital Asset Tracker" ? "Market" : "Equities" } Value</td>
                          <td>
                            RM{" "}
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              dashboard.portfolio_summary.portfolio_shares_value
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Cash</td>
                          <td>
                            RM{" "}
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              dashboard.portfolio_summary.cash_balance
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td className="text-left">Portfolio NAV</td>
                          <td>
                            RM{" "}
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              dashboard.portfolio_summary.portfolio_value
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Years Since Inception</td>
                          <td>
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              dashboard.portfolio_summary.years_since_inception
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-6 col-sm-12  mb-3 flex-fill'>
                <div
                  className='statusCardHeader row align-items-center justify-content-between'
                  onClick={() => {
                    toggles[1] = !toggles[1];
                    setToggle([...toggles]);
                  }}>
                  <div className='col'>
                    <h6 className='statusCardHeaderTitle'>
                      PORTFOLIO CASH RATIO
                    </h6>
                  </div>
                </div>
                {!toggles[1] && (
                  <div className='row card-body bg-white headerTableContentHeight'>
                    <div
                      id='cashRatio'
                      style={{
                        width: "100%",
                        minHeight: 200,
                        height: "100%",
                        paddingTop: 20,
                      }}></div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-6 col-sm-12  mb-3 flex-fill'>
                <div
                  className='statusCardHeader row align-items-center justify-content-between'
                  onClick={() => {
                    toggles[2] = !toggles[2];
                    setToggle([...toggles]);
                  }}>
                  <div className='col'>
                    <h6 className='statusCardHeaderTitle'>
                      DAILY PORTFOLIO CHANGE
                    </h6>
                  </div>
                </div>
                {!toggles[2] && (
                  <div className='row card-body bg-white headerTableContentHeight'>
                    <table className='table'>
                      <tbody>
                        <tr>
                          <td className="text-left">Yesterday's Portfolio Value</td>
                          <td className="text-right">
                            RM {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              dashboard.daily_change.yesterday_value
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Today's Portfolio Value</td>
                          <td className="text-right">
                          RM {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              dashboard.daily_change.today_value
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Portfolio Change</td>
                          <td className="text-right">
                          RM {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              dashboard.daily_change.portfolio_change
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Portfolio Change (%)</td>
                          <td className="text-right">
                          RM {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              dashboard.daily_change.portfolio_change_perc
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              

              {/* <div className='col-lg-4 col-md-6 col-sm-12  mb-3 flex-fill'>
                <div
                  className='statusCardHeader row align-items-center justify-content-between'
                  onClick={() => {
                    toggles[3] = !toggles[3];
                    setToggle([...toggles]);
                  }}>
                  <div className='col'>
                    <h6 className='statusCardHeaderTitle'>LATEST TRADES</h6>
                  </div>
                </div>
                {!toggles[3] && (
                  <div
                    className='row card-body bg-white headerTableContentHeight'
                    style={{ overflow: "auto" }}>
                    <table className='latestTradeTable'>
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Stock Name</th>
                          <th>Quantity TO COMMENT</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboard.latest_trades.map((item, index) => {
                          if (item.contract_type === "Purchase") {
                            return (
                              <tr key={index}>
                                <td className='buyColor'>BUY</td>
                                <td className='boldText'>{item.klsename}</td>
                                <td>{item.quantity} TO COMMENT</td>
                                <td>RM {item.price}</td>
                              </tr>
                            );
                          } else {
                            return (
                              <tr key={index}>
                                <td className='sellColor'>SELL</td>
                                <td className='boldText'>{item.klsename}</td>
                                <td>{item.quantity} TO COMMENT</td> 
                                <td>RM {item.price}</td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div> */}

              {productView != "Digital Asset Tracker" && (
                <>
                  <div className='col-lg-6 col-md-6 col-sm-12  mb-3 flex-fill'>
                    <div
                      className='statusCardHeader row align-items-center justify-content-between'
                      onClick={() => {
                        toggles[4] = !toggles[4];
                        setToggle([...toggles]);
                      }}>
                      <div className='col'>
                        <h6 className='statusCardHeaderTitle'>
                          LATEST DIVIDEND 
                        </h6>
                      </div>
                    </div>
                    {!toggles[4] && (
                      <div className='row card-body bg-white headerTableContentHeight'>
                        <table className='latestTradeTable'>
                          <thead>
                            <tr>
                              <th>Stock Name</th>
                              <th>Div. Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dashboard.latest_dividends.map((item, index) => {
                              return (
                                <tr>
                                  <td>{item.klsename}</td>
                                  <td>RM {item.total_net_div}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {dashboard.latest_dividends.length === 0 && (
                          <p className='text-center' style={{ width: "100%" }}>
                            No Dividends
                          </p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 col-md-6 col-sm-12  mb-3 flex-fill'>
                    <div
                      className='statusCardHeader row align-items-center justify-content-between'
                      onClick={() => {
                        toggles[5] = !toggles[5];
                        setToggle([...toggles]);
                      }}>
                      <div className='col'>
                        <h6 className='statusCardHeaderTitle'>
                          TOP SHAREHOLDINGS
                        </h6>
                      </div>
                      {/* <div className="col-lg-6 col-md-6 col-sm-6">
                                        <h6 className="statusCardHeaderTitle float-right">{isLoading?<Skeleton variant="text" />:header.portfolio_cagr}</h6>
                                    </div> */}
                    </div>
                    {!toggles[5] && (
                      <div className='row card-body bg-white headerTableContentHeight'>
                        <table className='table'>
                          <thead>
                            <tr>
                              <th>Stock Name</th>
                              <th>% of Portfolio</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dashboard.top_shareholdings.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.klsename}</td>
                                  <td>{item.portfolio_percent}%</td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan='1'>Total:</td>
                              <td className='buyColor'>
                                {getTotalShareholdings()}%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function changePortfolio(t_id) {
    setPortfolio(t_id);
  }

  function invokeRefresh() {
    setLoading(true);
  }

  function renderTabs(){
    return(
        <Tabs value={tabIndex} onChange={(e,v)=>setTabIndex(v)} centered>
            <Tab label="All Portfolios" />
            <Tab label={`Current Portfolio`} />
        </Tabs>
    )
  }

  function renderAggregatedBody(){
    return(
        <div className="aggregatedDashboardContainer">
            <div className="container-fluid">
              <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <div className="aggStatBox">
                            <h3 className="boldText mb-3">Initial Portfolios Value</h3>
                            <h4 className="boldText text-center">RM {aggregatedData.initialTpv}</h4>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <div className="aggStatBox">
                            <h3 className="boldText mb-3">Current Portfolios Value</h3>
                            <h4 className="boldText text-center">RM {aggregatedData.currentTpv}</h4>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <div className="aggStatBox">
                            <h3 className="boldText mb-3">Absolute Return</h3>
                            <h4 className="boldText text-center" style={{color:stripCommaToNum(aggregatedData.abs_return) >= 0 ?"green":"red"}}>RM {aggregatedData.abs_return}</h4>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <div className="aggStatBox">
                            <h3 className="boldText mb-3">Absolute Return %</h3>
                            <h4 className="boldText text-center" style={{color:stripCommaToNum(aggregatedData.abs_return) >= 0 ?"green":"red"}}>{aggregatedData.abs_return_percentage} %</h4>
                        </div>
                    </div>
                    {productView === "Digital Asset Tracker" && Number(aggregatedData.unconfirmed_deposit) !== 0 &&<div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <div className="aggStatBox">
                            <h3 className="boldText mb-3">Unconfirmed Cash Deposit <Tooltip placement="top" title="Amount banked in pending verification from the Operations Team."><InfoIcon/></Tooltip></h3>
                            <h4 className="boldText text-center">RM {aggregatedData.unconfirmed_deposit}</h4>
                        </div>
                    </div>}
                </div>
                <br/>
                <div className="text-center">
                    {aggregatedData.aggregatedTPV.length === 0
                    ?
                      <div className="text-center" style={{paddingTop:30,paddingBottom:15}}>
                        <h1 style={{color:'rgba(0, 0, 0, 0.6)'}}>No Data</h1>
                      </div>
                    :
                      <div
                          id='chartdivTpv'
                          style={{ width: "100%", minHeight: 500, height: "100%" }}
                      />
                    }
                    
                </div>
            </div>
        </div>
    )
  }

  return (
    <div>
      <Screen
        origin='dashboard'
        setPortfolio={changePortfolio}
        invokeRefresh={invokeRefresh}
      >
        <div style={{textAlign:'center',fontSize:30}}>
          <span>
            {
              client_code.includes("-D1-") 
              ?
              "Batik Value Strategy"
              :
              productView === "Snowball"
              ?
              "Snowball Strategy"
              :
              productView == "Digital Asset Tracker"
              ?
              "ET Digital Assets Tracker"
              :
              ""
            }
          </span>
        </div>
        {renderTabs()}
        {tabIndex === 1 && renderBody()}
        {tabIndex === 0 && renderAggregatedBody()}
        <br />
      </Screen>
    </div>
  );
}

export default DashboardPage;
