import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import Screen from "../shared/screen";
import "./style.css";
import { axiosInstance } from "../../config/apiTemplate";
import Skeleton from "@mui/material/Skeleton";
import { EtTable } from "../shared/table/table";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

function StatusPage() {
  const cashRatioChart = useRef(null);
  const navChart = useRef(null);
  const [toggles, setToggle] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [isLoading, setLoading] = useState(true);
  const [portfolio, setPortfolio] = useState(localStorage.getItem("t_id"));
  const [summary, setSummary] = useState([]);
  const [boi, setBoi] = useState({});
  const [showType, setShowType] = useState("current");
  const [status, setStatus] = useState({
    boi_footer: {},
    boi_summary: [],
    market_value: {},
    net_cash_position: {},
    portfolio_cagr: {},
    portfolio_inception: {},
    portfolio_value: {},
    ytd_return: {},
    initial_value: 0,
  });
  let productView = localStorage.getItem("productView");

  useEffect(() => {
    document.title = "ETSW - Portfolio Status";
    getStatus();
  }, [portfolio]);

  useLayoutEffect(() => {
    let x = am4core.create("cashRatio", am4charts.PieChart);
    x.padding(20, 0, 10, 0);
    x.data = [
      {
        type: "Cash",
        value: Number(status.portfolio_value.cash),
        color: am4core.color("#191d63"),
      },
      {
        type: "Equities",
        value: Number(status.portfolio_value.stocks),
        color: am4core.color("#00A3E0"),
      },
    ];

    // Add and configure Series
    var pieSeries = x.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "type";

    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    // change the cursor on hover to make it apparent the object can be interacted with
    pieSeries.slices.template.cursorOverStyle = [
      {
        property: "cursor",
        value: "pointer",
      },
    ];

    pieSeries.alignLabels = false;
    pieSeries.labels.template.bent = false;
    //pieSeries.labels.template.radius = 3;
    pieSeries.labels.template.padding(0, 0, 0, 0);

    pieSeries.ticks.template.disabled = true;

    // Create a base filter effect (as if it's not there) for the hover to return to
    var shadow = pieSeries.slices.template.filters.push(
      new am4core.DropShadowFilter()
    );
    shadow.opacity = 0;

    // Create hover state
    var hoverState = pieSeries.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists

    // Slightly shift the shadow and make it more prominent on hover
    var hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
    hoverShadow.opacity = 0.7;
    hoverShadow.blur = 5;

    pieSeries.slices.template.propertyFields.fill = "color";

    cashRatioChart.current = x;

    return () => {
      x.dispose();
    };
  }, [status]);

  useLayoutEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.XYChart);

    chart.data = status.portfolio_cagr.cagr;

    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.axisFills.template.disabled = true;
    dateAxis.renderer.ticks.template.disabled = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    //valueAxis.renderer.minWidth = 35;
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;

    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "value";
    series.strokeWidth = 4;
    series.tooltipText = "NAV: RM {valueY}";

    // set stroke property field
    series.propertyFields.stroke = "color";

    chart.cursor = new am4charts.XYCursor();

    //var scrollbarX = new am4core.Scrollbar();
    //chart.scrollbarX = scrollbarX;

    //dateAxis.start = 0.7;
    //dateAxis.keepSelection = true;

    var title = chart.titles.create();
    title.text = "30 Days NAV";
    title.fontSize = 14;
    title.marginBottom = 0;
    navChart.current = chart;

    return () => {
      chart.dispose();
    };
  }, [status]);

  async function getStatus() {
    let dashboardForm = new FormData();

    const url = "client_api/status/getStatus_copy.php";

    try {
      const response = await axiosInstance.post(url, dashboardForm);
      if (response.data.status === 201) {
        response.data.boi_summary = response.data.boi_summary.map(
          (item, index) => {
            return { no: index + 1, ...item };
          }
        );
        setStatus(response.data);
      } else if (response.data.status === 500) {
        getStatus();
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  function renderBody() {
    return (
      <div className='container-fluid pt-4'>
        {renderHeaderTables()}
        <br />
        {renderSummaryTable()}
        {renderTotalRow()}
      </div>
    );
  }

  function renderHeaderTables() {
    let ncp = status.net_cash_position;
    let mv = status.market_value;
    let pc = status.portfolio_cagr;
    let pi = status.portfolio_inception;
    let pv = status.portfolio_value;
    let yr = status.ytd_return;

    return (
      <div className='row'>
        <div className='col'>
          <div className='container-fluid'>
            <div className='row d-flex'>
              <div className='col-lg-4 col-md-6 col-sm-12 mb-3 flex-fill'>
                <div
                  className='statusCardHeader row align-items-center justify-content-between'
                  onClick={() => {
                    toggles[0] = !toggles[0];
                    setToggle([...toggles]);
                  }}>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    <h6 className='statusCardHeaderTitle'>Cash</h6>
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    <h6 className='statusCardHeaderTitle float-right'>
                      RM {isLoading ? 0 : ncp.header}
                    </h6>
                  </div>
                </div>
                {/* {!toggles[0] && (
                  <div className='row card-body bg-white'>
                    <table className='table'>
                      <tbody>
                        <tr>
                          <td>Available Cash:</td>
                          <td>
                            RM{" "}
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              ncp.available_cash
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Sales Proceeds Receivables:</td>
                          <td>
                            RM{" "}
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              ncp.sales_proceed
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Dividends Receivables:</td>
                          <td>
                            RM{" "}
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              ncp.dividends_receivable
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Purchase Payables:</td>
                          <td>
                            RM{" "}
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              ncp.purchase_payables
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Management Fee Payable:</td>
                          <td>
                            RM{" "}
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              ncp.management_fee
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )} */}
              </div>

              <div className='col-lg-4 col-md-6 col-sm-12  mb-3 flex-fill'>
                <div
                  className='statusCardHeader row align-items-center justify-content-between'
                  onClick={() => {
                    toggles[1] = !toggles[1];
                    setToggle([...toggles]);
                  }}>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    <h6 className='statusCardHeaderTitle'>
                      {productView === "Snowball" ? "Equities" : productView === "Digital Asset Tracker" ? "Market" : "Equities"} Value
                    </h6>
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    <h6 className='statusCardHeaderTitle float-right'>
                      RM {isLoading ? <Skeleton variant='text' /> : mv.header}
                    </h6>
                  </div>
                </div>
                {/* {!toggles[1] && (
                  <div className='row card-body bg-white'>
                    <table className='table'>
                      <tbody>
                        <tr>
                          <td>Total Portfolio Return:</td>
                          <td>
                            RM{" "}
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              mv.total_portfolio_return
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Portfolio Weighted P/E:</td>
                          <td>
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              mv.portfolio_weightage_pe
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Portfolio Weighted DY%:</td>
                          <td>
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              mv.portfolio_weightage_dy
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Total Number of Stocks:</td>
                          <td>
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              mv.num_of_stocks
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Trades Since Inception:</td>
                          <td>
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              mv.trade_since_inception
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )} */}
              </div>

              <div className='col-lg-4 col-md-6 col-sm-12  mb-3'>
                <div
                  className='statusCardHeader row align-items-center justify-content-between'
                  onClick={() => {
                    toggles[2] = !toggles[2];
                    setToggle([...toggles]);
                  }}>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    <h6 className='statusCardHeaderTitle'>
                      Total Portfolio Value
                    </h6>
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    <h6 className='statusCardHeaderTitle float-right'>
                      RM {isLoading ? <Skeleton variant='text' /> : pv.header}
                    </h6>
                  </div>
                </div>
                {/* {!toggles[2] && (
                  <div className='row card-body bg-white'>
                    <div
                      id='cashRatio'
                      style={{
                        width: "100%",
                        minHeight: 200,
                        height: "100%",
                        paddingTop: 20,
                      }}></div>
                  </div>
                )} */}
              </div>

              {productView != "Digital Asset Tracker" && (
                <>
                  <div className='col-lg-4 col-md-6 col-sm-12  mb-3'>
                    <div
                      className='statusCardHeader row align-items-center justify-content-between'
                      onClick={() => {
                        toggles[4] = !toggles[4];
                        setToggle([...toggles]);
                      }}>
                      <div className='col-lg-6 col-md-6 col-sm-6'>
                        <h6 className='statusCardHeaderTitle'>Absolute Return</h6>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-6'>
                        <h6 className='statusCardHeaderTitle float-right'>
                          {isLoading ? <Skeleton variant='text' /> : yr.header} %
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-4 col-md-6 col-sm-12  mb-3'>
                    <div
                      className='statusCardHeader row align-items-center justify-content-between'
                      onClick={() => {
                        toggles[5] = !toggles[5];
                        setToggle([...toggles]);
                      }}>
                      <div className='col-lg-6 col-md-6 col-sm-6'>
                        <h6 className='statusCardHeaderTitle'>Portfolio CAGR%</h6>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-6'>
                        <h6 className='statusCardHeaderTitle float-right'>
                          {isLoading ? <Skeleton variant='text' /> : pc.header} %
                        </h6>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {productView == "Digital Asset Tracker" && (
                <>
                  <div className='col-lg-4 col-md-6 col-sm-12  mb-3'>
                    <div
                      className='statusCardHeader row align-items-center justify-content-between'
                      onClick={() => {
                        toggles[4] = !toggles[4];
                        setToggle([...toggles]);
                      }}>
                      <div className='col-lg-6 col-md-6 col-sm-6'>
                        <h6 className='statusCardHeaderTitle'>Absolute Return</h6>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-6'>
                        <h6 className='statusCardHeaderTitle float-right'>
                          {isLoading ? <Skeleton variant='text' /> : yr.header} 
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-4 col-md-6 col-sm-12  mb-3'>
                    <div
                      className='statusCardHeader row align-items-center justify-content-between'
                      onClick={() => {
                        toggles[5] = !toggles[5];
                        setToggle([...toggles]);
                      }}>
                      <div className='col-lg-6 col-md-6 col-sm-6'>
                        <h6 className='statusCardHeaderTitle'>Absolute Return %</h6>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-6'>
                        <h6 className='statusCardHeaderTitle float-right'>
                          {isLoading ? <Skeleton variant='text' /> : pc.header} %
                        </h6>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className='col-lg-4 col-md-6 col-sm-12  mb-3'>
                <div
                  className='statusCardHeader row align-items-center justify-content-between'
                  onClick={() => {
                    toggles[3] = !toggles[3];
                    setToggle([...toggles]);
                  }}>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    <h6 className='statusCardHeaderTitle'>
                      Initial Capital
                    </h6>
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    <h6 className='statusCardHeaderTitle float-right'>
                      {isLoading ? <Skeleton variant='text' /> : status.initial_value}
                    </h6>
                  </div>
                </div>
                {/* {!toggles[3] && (
                  <div className='row card-body bg-white'>
                    <table className='table'>
                      <tbody>
                        <tr>
                          <td>FBMT100 on Inception:</td>
                          <td>
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              pi.fbmklci
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>No of Years Since Inception:</td>
                          <td>
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              pi.num_years_after_inception
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>No of Days Since Inception</td>
                          <td>
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              pi.num_days_after_inception
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Total Portfolio Capital:</td>
                          <td>
                            RM{" "}
                            {isLoading ? (
                              <Skeleton variant='text' />
                            ) : (
                              pi.portfolio_capital
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function changePortfolio(t_id) {
    setPortfolio(t_id);
  }

  function renderSummaryTable() {
    return (
      <div className='row'>
        <div className='col'>
          <EtTable
            origin={"status"}
            productView={productView}
            statusProps={{marketValue:status.market_value.header,percentagePortfolio:status.market_value.total_portfolio_percentage}}
            data={status.boi_summary}
            sortable={true}
            headers={
              productView != "Digital Asset Tracker"
              ?
              [
                { display: "No", sort: "no" },
                { display: "Stock Name", sort: "stock_name" },
                { display: "% Profit / Loss", sort: "pnl_perc_clean" },
                { display: "% of Portfolio", sort: "perc_of_portfolio_clean" },
              ]
              :
              [
                { display: "No", sort: "no" },
                { display: "Digital Asset", sort: "crypto" },
                { display: "% of Portfolio", sort: "perc_of_portfolio_clean" },
              ]
            }
            displayFieldKeys={
              productView != "Digital Asset Tracker"
              ?
              [
                { key: "no" },
                { key: "stock_name" },
                { key: "pnl_perc", styleKey: "pnl_color" },
                { key: "perc_of_portfolio", append: "%" },
              ]
              :
              [
                { key: "no" },
                { key: "crypto" },
                { key: "perc_of_portfolio", append: "%" },
              ]
            }
            showPagination={false}
          />
        </div>
      </div>
    );
  }

  function renderTotalRow() {
    return (
      <div className='row'>
        <div className='col'>
          <table className='totalSummaryTable'>
            <tbody>
         
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function invokeRefresh() {
    setLoading(true);
    getStatus();
  }

  return (
    <div>
      <Screen
        origin='status'
        setPortfolio={changePortfolio}
        invokeRefresh={invokeRefresh}>
        {renderBody()}
        <br />
      </Screen>
    </div>
  );
}

export default StatusPage;
