import React, {useState,useEffect} from "react";
import Screen from "../shared/screen";

import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import BuySellLog from "./buyselllog";
import DivLog from "./divlog";
import CapChangeLog from "./capchange";
import InterestLog from "./intlog";
import CashDepositsLog from "./cashlog";

import "./style.css";

const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    },
  });

function BinPage(){
    const [value, setValue] = React.useState(localStorage.getItem("binIndex")?Number(localStorage.getItem("binIndex")):0);
    const [portfolio, setPortfolio] = useState(localStorage.getItem("t_id"));
    const [timeStamp, setTS] = useState(new Date().getTime());

    const classes = useStyles();

    useEffect(()=>{
        document.title = "EPM - Recycle Bin";
    });

    function changePortfolio(t_id){
        setPortfolio(t_id);
      }
  
    function invokeRefresh(){
        setTS(new Date().getTime());
    }
  
    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem("binIndex",newValue);
    };


    return(
        <div>
            <Screen origin="bin">
            <div className="container-fluid pt-4">
                 <div className="row">
                    <div className="col">
                    <Paper className={classes.root}>
                        <Tabs
                            className="logTabs"
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            TabIndicatorProps={{
                                style: {
                                backgroundColor: "#191d63",
                                },
                            }}
                        >
                            <Tab label="Buy & Sell Log" />
                            <Tab label="Dividends" />
                            <Tab label="Capital Change" />
                            <Tab label="Interest" />
                            <Tab label="Cash Deposits & Withdrawals" />
                        </Tabs>
                        </Paper>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {value === 0 && <BuySellLog trading_id={portfolio} ts={timeStamp}/>}
                        {value === 1 && <DivLog trading_id={portfolio} ts={timeStamp}/>}
                        {value === 2 && <CapChangeLog trading_id={portfolio} ts={timeStamp}/>}
                        {value === 3 && <InterestLog trading_id={portfolio} ts={timeStamp}/>}
                        {value === 4 && <CashDepositsLog trading_id={portfolio} ts={timeStamp}/>}
                    </div>
                </div>
            </div>
            </Screen>
        </div>
    )
}

export default BinPage;