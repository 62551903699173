import React, {useState,useEffect} from "react";
import {EtTable} from "../shared/table/table";
import {axiosInstance} from "../../config/apiTemplate";
import CircularProgress from '@mui/material/CircularProgress';
import { DataGridPro,GridToolbarContainer,GridToolbarColumnsButton,GridToolbarFilterButton,GridToolbarExport,GridToolbarDensitySelector, GridOverlay,GridToolbar } from "@mui/x-data-grid-pro";
import LinearProgress  from "@mui/material/LinearProgress";
import { renderDate, formatToDateObj } from "../shared/util";
export default function CashDepositsLog(props){

    const [logs,setLogs] = useState([]);
    const [response, setResponse] = useState({});
    const [isLoading, setLoading] = useState(true);

    useEffect(()=>{
        document.title = "ETSW - Cash Deposits & Withdrawals Logs";

        setLoading(true);
        getLogs();

        return () =>{
            setLoading(false);  
        }
    },[props.trading_id,props.ts]);

    async function getLogs(){
        let headerForm = new FormData();

        const url = "client_api/logs/getCashDeposit_copy.php";
    
        try {
            const response = await axiosInstance.post(url, headerForm);
            if (response.data.status === 201) {
                let logs = response.data.cash_deposit;
                logs = logs.map((item,index)=>{
                    return {...item,no:(index+1),id:index};
                });
                setLogs(logs);
                setResponse(response.data.interest.header);
            }
            else if(response.data.statusCode === 500){
                getLogs();
            }
            setLoading(false);
        }
        catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    function renderLoading(){
        return(
            <div className="text-center">
                <CircularProgress />
                <p>Fetching Data...</p>
            </div>
        )
    }

    function renderCustomToolbar(){
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    function renderNoRows(){
        return(
            <GridOverlay>
                <div>
                    <h6 style={{fontStyle:"italic"}}>No Transactions....</h6>
                </div>
            </GridOverlay>
        )
    }

    function loadingOverlay(){
        return(
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
                </div>
            </GridOverlay>
        )
    }

    function renderBoldText(props){
        if(props.field === "amount"){
            return(
                <span className="boldText" style={{fontSize:18,width:"100%"}}>RM {props.value}</span>
            );
        }else if(props.field === "transaction_type"){
            return(
                <span className={`boldText ${props.value === "Deposit"?"greenText":"redText"}`} style={{fontSize:18,width:"100%"}}>{props.value}</span>
            );
        }else{
            return(
                <span className="boldText" style={{fontSize:18,width:"100%"}}>{props.value}</span>
            );
        }
    }

    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    {isLoading? renderLoading():
                        <div style={{minHeight:"65vh",display:"flex"}}>
                            <div style={{ flexGrow: 1, backgroundColor: "white", marginTop: 20 }}>
                                <DataGridPro
                                    density="compact"
                                    loading={isLoading}
                                    columns={[
                                        {
                                            field: "no",
                                            headerName: "No.",
                                            flex: 1,
                                        },
                                        {
                                            field: "transaction_date",
                                            headerName: "Transaction Date",
                                            minWidth: 150,
                                            flex: 2,
                                            type:"date",
                                            renderCell:renderDate,
                                            valueGetter:({value})=>value && formatToDateObj(value)
                                        },
                                        {
                                            field: "transaction_type",
                                            headerName: "Transaction Type",
                                            minWidth: 150,
                                            flex: 2,
                                            renderCell:renderBoldText
                                        },
                                        {
                                            field: "transaction_detail",
                                            headerName: "Transaction Detail",
                                            minWidth: 200,
                                            flex: 2,
                                            renderCell:renderBoldText
                                        },
                                        {
                                            field: "amount",
                                            headerName: "Amount",
                                            minWidth: 140,
                                            flex: 2,
                                            renderCell:renderBoldText
                                        },
                                        {
                                            field: "remarks",
                                            headerName: "Remarks",
                                            minWidth: 300,
                                            flex: 2,
                                            renderCell:renderBoldText
                                        }
                                    ]}
                                    pagination
                                    rows={logs}
                                    components={{
                                        Toolbar: GridToolbar,
                                        LoadingOverlay: loadingOverlay,
                                        NoRowsOverlay: renderNoRows
                                    }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                />
                                </div></div>
                        }
                </div>
            </div>
        </div>
    );


}