import axios from "axios";

export const isProduction = false;

const domain = isProduction ? "https://api.equitiestracker.com/": "https://api.et-invest.com/";

// General axios instance
export const axiosJwt = axios.create({
  method: "POST",
  headers: { "content-type": "multipart/form-data" },
});

// General axios instance
export const axiosInstance = axios.create({
  baseURL: domain,
  method: "POST",
  headers: { "content-type": "multipart/form-data" },
});

axiosInstance.interceptors.request.use(
  (config) => {
    var token = localStorage.getItem("token");
    let clientCode = localStorage.getItem("clientCode");
    var productView = localStorage.getItem("productView");
    // var uid = localStorage.getItem("u_id");
    if (!config.data) {
      config.data = new FormData();
    }
    if(!config.data.get("token") && !config.data.get("reset_action")){
        config.data.append("token", token);
    }
    config.data.append("client_code", clientCode);
    config.data.append("productView", productView);
    return config;
  },
  (error) => {
    // Do something with request error
    return 400;
  }
);
let flag = false;
// Add a response interceptor
axiosInstance.interceptors.response.use(
  async (response) => {
    if (response.status === 200) {
      if (response.data.status === 600) {
        //logout
        if (!flag) {
          flag = true;
          // alert("Your session is already expired, please login again.");
          localStorage.clear();
          window.location.href = "/login";
        }
        flag = false;
      } else if (response.data.status === 400) {
        //perform a refresh
        if (!flag) {
          flag = true;
          alert("Your session is already expired, please login again.");
          localStorage.clear();
          window.location.href = "/login";
        }
      } else {
        return response;
      }
    }
  },
  (error) => {
    if (error.status === 401) {
      localStorage.clear();
      return (window.location.href = "/login");
    } else if (error.status === 400 || error.status === 500) {
      return alert("Something went wrong. Please try again later.");
    }
    return Promise.reject(error);
  }
);
