import React, {useEffect, useState} from "react";
import HeaderBar from "./header";
import Footer from "./footer";
import SideBar from "./sidebar";
import "./style.css";
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import MfaOnBoard from "./mfaBanner";
import PasswordChangeModal from "./passwordChangeModal";
import { axiosInstance } from "../../config/apiTemplate";

  const actions = [
    { icon: <FileCopyIcon />, name: 'Copy' },
    { icon: <SaveIcon />, name: 'Save' },
    { icon: <PrintIcon />, name: 'Print' },
    { icon: <ShareIcon />, name: 'Share' },
    { icon: <FavoriteIcon />, name: 'Like' },
  ];

function Screen(props){
    let {children,origin} = props;
    const [mfaOnboard, setMfaOnboard] = useState(localStorage.getItem("mfa_boarded"));
    let [parentUpdate, setParentUpdate] = useState(new Date().getTime());
    const [sideBarOpen,setSideBarOpen] = useState(localStorage.getItem("sidebar") !== undefined?JSON.parse(localStorage.getItem("sidebar")):true);
    const [showPasswordChange, setShowPasswordChange] = useState(false);
    const [products, setProducts] = useState([]);
    // const [productView, setProductView] = useState("snowball");

    useEffect(()=>{
        setMfaOnboard(localStorage.getItem("mfa_boarded"));
        checkPasswordChange();
        getProducts();
    },[parentUpdate]);

    // APIS ========================================================================
    async function checkPasswordChange(){
        let loginForm = new FormData();
        const url = "client_api/account/checkPasswordOnboard.php";
  
        try {
          const response = await axiosInstance.post(url, loginForm);
          if (response.data.status === 201) {
            if(response.data.isChangePass){
                setShowPasswordChange(false);
            }else{  
                setShowPasswordChange(true);
            }
          } else {
            window.alert(response.data.message);
          }
        } catch (e) {
          console.log(e);
        }
    }

    async function getProducts(){
      let loginForm = new FormData();
      const url = "client_api/account/getProducts.php";

      try {
        const response = await axiosInstance.post(url, loginForm);
        if (response.data.status === 201) {
          setProducts(response.data.data);
          if(!localStorage.getItem("productView") || localStorage.getItem("productView") === undefined){
            localStorage.setItem("productView",response.data.data[0]);
            // Direct to aggregated page is done at authenticate.php
            // if(response.data.data[0] === "Aggregated"){
            //   window.location.href = "/aggregated";
            // }
          }
          else{
            // Check if productView is Aggregated but the user is not on Aggregated page, update productView to next product after Aggregated
            if(localStorage.getItem("productView") === "Aggregated" && origin !== "aggregated"){
              let index = response.data.data.indexOf("Aggregated");
              if(index !== -1){
                localStorage.setItem("productView",response.data.data[index+1]);
              }
              else{
                localStorage.setItem("productView",response.data.data[0]);
              }
            }
          }
        } else {
          window.alert(response.data.message);
        }
      }
      catch (e) {
        console.log(e);
      }
    }

    // FUNCTIONS ========================================================================

    function toggleSideBar(){
      localStorage.setItem("sidebar",!sideBarOpen);
      setSideBarOpen(!sideBarOpen);
    }

    function parentSetProductView(product){
      // setProductView(product);
      localStorage.setItem("productView",product);

      if(product === "Aggregated"){
        // Direct to another page
        window.location.href = "/aggregated";
      }
      else{
        window.location.href = "/";
      }
    }

    // RENDER ========================================================================

    return(
        <>
           {(!mfaOnboard || mfaOnboard === "false") && <MfaOnBoard parentProp={setParentUpdate}/>}
           <HeaderBar isLogin={true} setPortfolio={props.setPortfolio} products={products} setProductView={parentSetProductView}/>
                <div className="screenContainer">
                    <div className={sideBarOpen?"sidebarComponentOpened":"sidebarComponentClosed"}>
                        <div className="sidebarToggleButton" onClick={()=>{ toggleSideBar(); }}>
                            {sideBarOpen && <>Collapse <FirstPageIcon /></>}
                            {!sideBarOpen && <><LastPageIcon /></>}
                        </div>
                        <SideBar origin={origin} invokeRefresh={props.invokeRefresh} toggle={sideBarOpen} products={products} setProductView={parentSetProductView}/>
                    </div>
                    <div className={sideBarOpen?"mainComponentOpened":"mainComponentClosed"}>
                        {children}
                        <PasswordChangeModal show={showPasswordChange} parentProp={setParentUpdate} />
                    </div>
                </div>
            <Footer />
        </>
    )
}

export default Screen;