import React, {useState,useEffect} from "react";
import {EtTable} from "../shared/table/table";
import {axiosInstance} from "../../config/apiTemplate";
import CircularProgress from '@mui/material/CircularProgress';

export default function CashDepositsLog(props){

    const [logs,setLogs] = useState([]);
    const [response, setResponse] = useState({});
    const [isLoading, setLoading] = useState(true);

    useEffect(()=>{
        document.title = "EPM - Cash Deposits & Withdrawals Recycle Bin";

        setLoading(true);
        getLogs();

        return () =>{
            setLoading(false);  
        }
    },[props.trading_id,props.ts]);

    async function getLogs(){
        let headerForm = new FormData();
  
        headerForm.append("t_id", props.trading_id);
        const url = "api/bin/cashlog.php";
    
        try {
          const response = await axiosInstance.post(url, headerForm);
          if (response.data.statusCode === 201) {
            let logs = response.data.logs;
            setLogs(logs);
            setResponse(response.data);
          }else if(response.data.statusCode === 500){
            getLogs();
          }
         setLoading(false);
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
    }

    function renderLoading(){
        return(
            <div className="text-center">
                <CircularProgress />
                <p>Fetching Data...</p>
            </div>
        )
    }

    async function deleteLog(id){
        let resp = window.confirm("Are you sure to recover this log entry?");
        if(resp){
            //delete log
        }
    }

    return(
        <div className="container-fluid pt-3">
            <div className="row">
                <div className="col">
                    <div className="card card-body">
                    {isLoading?renderLoading():<div className="container-fluid">
                            <br/>
                            <EtTable 
                                data={logs}
                                sortable={true}
                                headers={[
                                    {display:"No",sort:"no"},
                                    {display:"Transaction Date",sort:"tx_date_clean"},
                                    {display:"Transaction Type",sort:"tx_type"},
                                    {display:"Transaction Details",sort:"tx_detail"},
                                    {display:"Amount",sort:"amount_clean"},
                                    {display:"Payment Mode",sort:"payment_mode"},
                                    {display:"Remarks",sort:"remarks"},
                                    {display:"Recover",sort:"remove"},
                                ]}
                                displayFieldKeys={[
                                    {key:"no"},
                                    {key:"tx_date"},
                                    {key:"tx_type",styleKey:"tx_type_color"},
                                    {key:"tx_detail"},
                                    {key:"amount",prepend:"RM "},
                                    {key:"payment_mode"},
                                    {key:"remarks"},
                                    {key:"remove",style:{"fontWeight":"bold",color:"green"},isClickable:true,clickAction:deleteLog,clickProp:{"token":"removeToken"}},
                                ]}
                                showPagination={true}
                            />
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}