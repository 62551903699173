import React, {useState,useEffect} from "react";
import Screen from "../shared/screen";
import {numberWithCommas, stripCommaToNum} from "../shared/util";
import {axiosInstance} from "../../config/apiTemplate";
import CircularProgress from '@mui/material/CircularProgress';
import {
    withStyles,
  } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import "./style.css";

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'black',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'black',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'black',
        },
        '&:hover fieldset': {
          borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'black',
        },
        '&::placeholder': {
            color: 'black',
          },
      },
    },
  })(TextField);

function LadderPage(){

    const [portfolio, setPortfolio] = useState(localStorage.getItem("t_id"));
    const [stocklist, setStocklist] = useState([]);
    const [stock, setStock] = useState({klseName:"",klseCode:""});
    const [stockPrice, setPrice] = useState(0);
    const [search, setSearch] = useState("");
    const [buyLadderConfig, setBuyLadderConfig] = useState({step:2,priceFrom:"",priceToOpt:"price",priceTo:"",amtInvest:"",minBrokerage:8,brokerate:0.1});
    const [sellLadderConfig, setSellLadderConfig] = useState({step:2,priceFrom:"",priceToOpt:"price",priceTo:"",quantity:"",minBrokerage:8,brokerate:0.1});
    const [returns, setReturns] = useState({absPercent:"",absCash:"",numYear:1,cagr:""})
    const [buyLadder, setBuyLadder] = useState([]);
    const [sellLadder, setSellLadder] = useState([]);
    const [buyTotals, setBuyTotals] = useState({});
    const [sellTotals, setSellTotals] = useState({});
    const [ts,setTs] = useState(new Date().getTime());

    useEffect(()=>{
        document.title = "EPM - Ladders";
    },[]);

    useEffect(()=>{
        if(search != ""){
            getStocks();
        }
    },[search]);

    useEffect(()=>{
        if(stock.klseName != ""){
            getStockPrice();
        }
    },[stock])



    async function getStocks(){
        let headerForm = new FormData();
  
        headerForm.append("t_id", portfolio);
        headerForm.append("stockName", search);
        const url = "api/shared/stocklist.php";
    
        try {
          const response = await axiosInstance.post(url, headerForm);
          if (response.data.statusCode === 201) {
            let stocks = response.data.stocks;
            if(stocks.length > 0){
                if(stock.klseName === ""){
                    //means not selected / reset 
                    stocks.push(stock)
                }
                setStocklist(stocks);
            }
          }else if(response.data.statusCode === 500){
            getStocks();
          }
        } catch (e) {
          console.log(e);
        }
    }


    async function getStockPrice(){
        let headerForm = new FormData();
  
        headerForm.append("t_id", portfolio);
        headerForm.append("code", stock.klseCode);
        const url = "api/shared/stockprice.php";
    
        try {
          const response = await axiosInstance.post(url, headerForm);
          if (response.data.statusCode === 201) {
            let price = response.data.price;
                setPrice(price);
                let buyLadderConf = {};
                buyLadderConf = {...buyLadderConfig,priceFrom:price};
                let sellLadderConf = {}
                sellLadderConf = {...sellLadderConfig,priceFrom:price};
                setBuyLadderConfig(buyLadderConf);
                setSellLadderConfig(sellLadderConf);
          }else if(response.data.statusCode === 500){
            getStockPrice();
          }
        } catch (e) {
          console.log(e);
        }
    }

   function changePortfolio(t_id){
      setPortfolio(t_id);
    }


    function handleStockListOnChange(v){
        if(v){
            setStock(v);
        }else{
            setStocklist([]);
        }
    }

    function handleStockListInputChange(v){
        if(!v.includes("(") && !v.includes(")") && !v.length < 10){
            setSearch(v);
        }
    }

    function renderStockList(){
        return(

            <div className="row align-items-center">
                <div className="col-lg-3 col-md-4 col-sm-4">
                    <h5>Stock Name: </h5>
                </div>
                <div className="col-lg-9 col-md-8 col-sm-8">
                    <Autocomplete
                        id="select-portfolio"
                        className="portfolioDropDown"
                        options={stocklist}
                        defaultValue={stock}
                        value={stock}
                        onChange={(e,v)=>{ handleStockListOnChange(v);}}
                        onInputChange={(e,v)=>{ handleStockListInputChange(v);}}
                        popupIcon={<ArrowDropDownIcon />}
                        closeIcon={<CloseIcon fontSize="small" />}
                        getOptionLabel={(option) => { return option.klseName?option.klseName+" ("+option.klseCode+")":"" }}
                        getOptionSelected={(option,value)=>option.klseCode === value.klseCode}
                        renderOption={(option) => (
                        <React.Fragment>
                            {option.klseName} ({option.klseCode})
                        </React.Fragment>
                        )}
                        renderInput={(params) => (
                        <CssTextField
                            {...params}
                            label="Select Stock"
                            variant="outlined"
                            className="dropdownTextField"
                            inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                        )}
                    />
                </div>
            </div>
        )
    }

    function onChangeInput(origin,e){
        let val = e.target.value;
        if(origin !== "buyPriceToOpt" && origin !== "sellPriceToOpt"){
            if(isNaN(val)){
                return false;
            }
        }

        let buyLadderConf = buyLadderConfig;
        let sellLadderConf = sellLadderConfig;
        let returnsTemp = returns;

        switch(origin){
            case "buyStep":buyLadderConf={...buyLadderConfig,step:val};break;
            case "buyPriceFrom":buyLadderConf={...buyLadderConfig,priceFrom:val};break;
            case "buyPriceToOpt":buyLadderConf={...buyLadderConfig,priceToOpt:val};break;
            case "buyPriceTo":buyLadderConf={...buyLadderConfig,priceTo:val};break;
            case "buyAmtInvest":buyLadderConf={...buyLadderConfig,amtInvest:val};break;
            case "buyMinBrokerage":buyLadderConf={...buyLadderConfig,minBrokerage:val};break;
            case "buyBrokerate":buyLadderConf={...buyLadderConfig,brokerate:val};break;

            case "sellStep":sellLadderConf={...sellLadderConfig,step:val};break;
            case "sellPriceFrom":sellLadderConf={...sellLadderConfig,priceFrom:val};break;
            case "sellPriceToOpt":sellLadderConf={...sellLadderConfig,priceToOpt:val};break;
            case "sellPriceTo":sellLadderConf={...sellLadderConfig,priceTo:val};break;
            case "sellQuantity":sellLadderConf={...sellLadderConfig,quantity:val};break;
            case "sellMinBrokerage":sellLadderConf={...sellLadderConfig,minBrokerage:val};break;
            case "sellBrokerate":sellLadderConf={...sellLadderConfig,brokerate:val};break;

            case "numYear": returnsTemp={...returns,numYear:val};break;
        }

        setBuyLadderConfig(buyLadderConf);
        setSellLadderConfig(sellLadderConf);
        setReturns(returnsTemp);
    }

    function onBlurInputs(){
        //sanitize and format numbers to with commas
        let buyLadderConf = buyLadderConfig;
        let sellLadderConf = sellLadderConfig;

        for (const [key, value] of Object.entries(buyLadderConf)) {
            if(!isNaN(value) && value !== ""){
                buyLadderConf[key] = numberWithCommas(value);
            }
        }
        for (const [key, value] of Object.entries(sellLadderConf)) {
            if(!isNaN(value)  && value !== ""){
                sellLadderConf[key] = numberWithCommas(value);
            }
        }

        setBuyLadderConfig(buyLadderConf);
        setSellLadderConfig(sellLadderConf);
        setTs(new Date().getTime())

    }

    function onFocusInputs(){
           //sanitize and format numbers to with commas
           let buyLadderConf = buyLadderConfig;
           let sellLadderConf = sellLadderConfig;
   
           for (const [key, value] of Object.entries(buyLadderConf)){
               if(key !== "priceToOpt"){
                   if(value !== ""){
                    buyLadderConf[key] = stripCommaToNum(value);
                   }
               }    
           }
           for (const [key, value] of Object.entries(sellLadderConf)) {
                if(key !== "priceToOpt"){
                    if(value !== ""){
                        sellLadderConf[key] = stripCommaToNum(value);
                       }
                }
               
           }
   
           setBuyLadderConfig(buyLadderConf);
           setSellLadderConfig(sellLadderConf);
           setTs(new Date().getTime())
    }

    function calculateLadder(type){

        if(type === "buy"){
            let numStep = parseInt(buyLadderConfig.step);
            let priceFrom = stripCommaToNum(buyLadderConfig.priceFrom);
            let brokerAmt = stripCommaToNum(buyLadderConfig.minBrokerage);
            let brokerate = stripCommaToNum(buyLadderConfig.brokerate);
            let amtInvest = stripCommaToNum(buyLadderConfig.amtInvest);
            let priceTo = 0;
            if(buyLadderConfig.priceToOpt === "price"){
                priceTo = buyLadderConfig.priceTo;
            }else{
                priceTo = priceFrom - (priceFrom*(buyLadderConfig.priceTo/100))
            }

            let SumofStep = (numStep*(1+numStep))/2;

            let StepMinusOne = numStep-1;
        
            let FirstMinusLastBuy = priceFrom-priceTo;
 
            let buyLadders = [];
            let totalAmt = 0;
            let TotalSharesQty = 0;
            let SumofPrice = 0;
            let totalBrk = 0;
            let AvgBuyPrice = 0;
            let price = 0;
            for(let i = 1; i <= numStep; i++){	
                if(i == 1){
                    price= priceFrom;
                }else if(i === numStep){
                     price=priceTo;
                }else{
                    price=price-(FirstMinusLastBuy/StepMinusOne);
                }


            
                
                let AmountToInvest=amtInvest*(i/SumofStep);
        
                if(i == numStep){
                    AmountToInvest=amtInvest-totalAmt;
                }

                let a = AmountToInvest/price;

                let QuantityOfShares=Math.round((a/100)-0.5)*100;
     

                //rounded Amount For Each Steps

                let AmountToInvestNew=QuantityOfShares*price;

                //Total Amount

                totalAmt=totalAmt+AmountToInvestNew;

                //Total Shares Quantity

                TotalSharesQty=TotalSharesQty+QuantityOfShares;

                //Avg Buy Price

                SumofPrice=SumofPrice+price;

                //Brokerage
                let brk = 0;
                if((AmountToInvest*(brokerate/100))<brokerAmt){
                    brk=brokerAmt;
                }else{
                    brk=AmountToInvest*(brokerate/100);
                }

                //Total BRK
                totalBrk=totalBrk+brk;

                if(i == numStep){
                    AvgBuyPrice=(totalAmt+totalBrk)/TotalSharesQty;
                }
                

                let obj = {
                    no:i,
                    price:numberWithCommas(price),
                    amtInvest:numberWithCommas(AmountToInvestNew,2),
                    quantity:numberWithCommas(QuantityOfShares,0),
                    brokerage:numberWithCommas(brk,2)
                };

                buyLadders.push(obj);
            }
            let obj = {
                AvgBuyPrice:numberWithCommas(AvgBuyPrice),
                totalBrk:numberWithCommas(totalBrk,2),
                TotalSharesQty:numberWithCommas(TotalSharesQty,0),
                totalAmt:numberWithCommas(totalAmt,2)
            };
            setBuyTotals(obj);
            setBuyLadder(buyLadders);
        }else{
            //sell
            let numStep = parseInt(sellLadderConfig.step);
            let priceFrom = stripCommaToNum(sellLadderConfig.priceFrom);
            let brokerAmt = stripCommaToNum(sellLadderConfig.minBrokerage);
            let brokerate = stripCommaToNum(sellLadderConfig.brokerate);
            let quantity = stripCommaToNum(sellLadderConfig.quantity);
            let priceTo = 0;
            if(sellLadderConfig.priceToOpt === "price"){
                priceTo = sellLadderConfig.priceTo;
            }else{
                priceTo = priceFrom + (priceFrom*(sellLadderConfig.priceTo/100))
            }

            let sellLadders = [];
            let SumofStep = (numStep*(1+numStep))/2;
            let StepMinusOne = numStep-1;
            let LastMinusFirstSell = priceTo-priceFrom;
            let totalQty = 0;
            let TotalValueOfSales = 0;
            let SumofPrice = 0;
            let totalBrk = 0;
            let AvgSellPrice = 0;
            let price = 0;
            for(let i = 1; i <= numStep; i++){	
                if(i === 1){
                    price=priceFrom;
                }else if(i === numStep){
                    price=priceTo;
                }else{
                    price=price+(LastMinusFirstSell/StepMinusOne);

                    price=numberWithCommas(price);
                }


                //Quantity For Each Steps
                let QuantityOfShares = Math.round((quantity*(i/SumofStep))/100)*100;

                if(i === numStep){
                    QuantityOfShares=quantity-totalQty;
                }

                //Value Of Shares For Each Steps
                let ValueOfShares=price*QuantityOfShares;

                //Total Value of Sales
                TotalValueOfSales=TotalValueOfSales+ValueOfShares;
    
                //Total Value of Sales
                totalQty=totalQty+QuantityOfShares;

                //Avg Sell Price
                SumofPrice=SumofPrice+price;

                //Brokerage
                let brk = 0;
                if((ValueOfShares*(brokerate/100))<brokerAmt){
                    brk=brokerAmt;
                }else{
                    brk=ValueOfShares*(brokerate/100);
                }

            
                //Total BRK
                totalBrk=totalBrk+brk;

                AvgSellPrice=(TotalValueOfSales-totalBrk)/quantity;

                let obj = {
                    no:i,
                    price:numberWithCommas(price),
                    quantity:numberWithCommas(QuantityOfShares,0),
                    value:numberWithCommas(ValueOfShares,2),
                    brokerage:numberWithCommas(brk,2)
                };

                sellLadders.push(obj);
            }
            let obj = {
                AvgSellPrice:numberWithCommas(AvgSellPrice,3),
                totalBrk:numberWithCommas(totalBrk,2),
                TotalValueOfSales:numberWithCommas(TotalValueOfSales,2),
                totalQty:numberWithCommas(totalQty,0),
                quantity
            };
            setSellTotals(obj);
            setSellLadder(sellLadders);
        }
    }


    function checkReturns(){

        if(buyTotals.totalAmt && sellTotals.TotalValueOfSales){ 
            return true;
        }else{
            return false;
        }
    }

    function computeReturns(type = "cagr"){
        let ending_amt = stripCommaToNum(sellTotals.TotalValueOfSales);
        let initial_amt = stripCommaToNum(buyTotals.totalAmt);
        
        let ar_m = numberWithCommas(ending_amt - initial_amt,2);
        let ar_p = numberWithCommas(((ending_amt - initial_amt)/initial_amt)*100,2);
        let cagr = (Math.pow((ending_amt / initial_amt), (1 / returns.numYear)) - 1)*100;

        if(!isFinite(cagr)){
            cagr = cagr;
        }else{
            cagr = numberWithCommas(cagr,2);
        }

        if(type === "ar_m"){
            return ar_m;
        }else if(type === "ar_p"){
            return ar_p;
        }else if(type === "cagr"){
            return cagr;
        }
    }


    function resetCalculation(type){
        if(type === "buy"){
            setBuyLadderConfig({step:2,priceFrom:"",priceToOpt:"price",priceTo:"",amtInvest:"",minBrokerage:8,brokerate:0.1});
            setBuyLadder([]);
            setBuyTotals({});
        }else{
            setSellLadderConfig({step:2,priceFrom:"",priceToOpt:"price",priceTo:"",quantity:"",minBrokerage:8,brokerate:0.1});
            setSellLadder([]);
            setSellTotals({});
        }
        setReturns({absPercent:"",absCash:"",numYear:1,cagr:""});
    }


    function renderBuySellTable(){
        return(
            <div className="row no-gutters">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <div className="ladderTitleBanner buyLadder">
                        BUY LADDER
                    </div>
                    <br />
                    <table>
                        <tbody>
                            <tr>
                                <td>No of Steps:</td>
                                <td>
                                    <select className="form-control" value={buyLadderConfig.step} onChange={(e)=>{ onChangeInput("buyStep",e);}}>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Price From($):</td>
                                <td><input type="text" className="form-control" value={buyLadderConfig.priceFrom} onChange={(e)=>{ onChangeInput("buyPriceFrom",e);}} onFocus={()=>{onFocusInputs();}} onBlur={(e)=>{onBlurInputs();}}/></td>
                            </tr>
                            <tr>
                                <td>
                                    <select className="form-control" value={buyLadderConfig.priceToOpt} onChange={(e)=>{ onChangeInput("buyPriceToOpt",e);}}>
                                        <option value="price">Price to ($):</option>
                                        <option value="buffer">Buffer (%):</option>
                                    </select>
                                </td>
                                <td><input type="text" className="form-control" value={buyLadderConfig.priceTo} onChange={(e)=>{ onChangeInput("buyPriceTo",e);}} onFocus={()=>{onFocusInputs();}} onBlur={(e)=>{onBlurInputs();}}/></td>
                            </tr>
                            <tr>
                                <td>Amount to Invest ($):</td>
                                <td><input type="text" className="form-control" value={buyLadderConfig.amtInvest} onChange={(e)=>{ onChangeInput("buyAmtInvest",e);}} onFocus={()=>{onFocusInputs();}} onBlur={(e)=>{onBlurInputs();}}/></td>
                            </tr>
                            <tr>
                                <td>Minimum Brokerage :</td>
                                <td><input type="text" className="form-control" value={buyLadderConfig.minBrokerage} onChange={(e)=>{ onChangeInput("buyMinBrokerage",e);}} onFocus={()=>{onFocusInputs();}} onBlur={(e)=>{onBlurInputs();}}/></td>
                            </tr>
                            <tr>
                                <td>Brokerage Rate (%):</td>
                                <td><input type="text" className="form-control" value={buyLadderConfig.brokerate} onChange={(e)=>{ onChangeInput("buyBrokerate",e);}} onFocus={()=>{onFocusInputs();}} onBlur={(e)=>{onBlurInputs();}}/></td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-block btn-success" onClick={()=>{
                                calculateLadder("buy");
                            }}>CALCULATE</button>
                        </div>
                        <div className="col mr-1">
                            <button className="btn btn-block btn-info" onClick={()=>{
                                resetCalculation("buy");
                            }}>RESET</button>
                        </div>
                    </div>
                    <table className="mt-2">
                        <tbody>
                            <tr style={{height:65}}>
                                <td>Absolute Return($):</td>
                                <td style={{color:stripCommaToNum(computeReturns("ar_m")) >= 0 ? "green": "red",fontWeight:"bold"}}>{!checkReturns()?"Please complete both Buy & Sell ladders to calculate this portion":"RM "+computeReturns("ar_m")}</td>
                            </tr>
                            <tr style={{height:65}}>
                                <td>No of Years:</td>
                                <td><input type="number" className="form-control" value={returns.numYear} onChange={(e)=>{ onChangeInput("numYear",e);}}/></td>
                            </tr>
                        </tbody>
                    </table>
                    {buyTotals.AvgBuyPrice && <div className="text-center mt-3">
                            <p style={{fontWeight:"bold"}}>Average Buy Price <span className="ml-5">RM {buyTotals.AvgBuyPrice}</span></p>
                        </div>}
                    {buyLadder.length > 0 && 
                    <div className="table-responsive">
                        <table className="table">
                                <thead>
                                    <tr style={{backgroundColor:"green",color:"white"}}>
                                        <th>No</th>
                                        <th>Price</th>
                                        <th>Amount to Invest</th>
                                        <th>Quantity of Shares</th>
                                        <th>Brokerage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        buyLadder.map((item,index)=>{
                                            return(
                                                <tr key={index}>
                                                    <td>{item.no}</td>
                                                    <td>RM {item.price}</td>
                                                    <td>RM {item.amtInvest}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>RM {item.brokerage}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <td colSpan="2" style={{backgroundColor:"#191d63",color:"white",fontWeight:"bold"}}>
                                            TOTAL
                                        </td>
                                        <td className="bold">RM {buyTotals.totalAmt}</td>
                                        <td className="bold">{buyTotals.TotalSharesQty}</td>
                                        <td className="bold">RM {buyTotals.totalBrk}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="ladderTitleBanner sellLadder">
                        SELL LADDER
                    </div>
                    <br/>
                    <table>
                        <tbody>
                            <tr>
                                <td>No of Steps:</td>
                                <td>
                                    <select className="form-control" value={sellLadderConfig.step} onChange={(e)=>{ onChangeInput("sellStep",e);}}>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Price From($):</td>
                                <td><input type="text" className="form-control" value={sellLadderConfig.priceFrom} onChange={(e)=>{ onChangeInput("sellPriceFrom",e);}} onFocus={()=>{onFocusInputs();}} onBlur={(e)=>{onBlurInputs();}}/></td>
                            </tr>
                            <tr>
                                <td>
                                    <select className="form-control" value={sellLadderConfig.priceToOpt} onChange={(e)=>{ onChangeInput("sellPriceToOpt",e);}}>
                                        <option value="price">Price to ($):</option>
                                        <option value="buffer">Buffer (%):</option>
                                    </select>
                                </td>
                                <td><input type="text" className="form-control" value={sellLadderConfig.priceTo} onChange={(e)=>{ onChangeInput("sellPriceTo",e);}} onFocus={()=>{onFocusInputs();}} onBlur={(e)=>{onBlurInputs();}}/></td>
                            </tr>
                            <tr>
                                <td>Quantity of Shares:</td>
                                <td><input type="text" className="form-control" value={sellLadderConfig.quantity} onChange={(e)=>{ onChangeInput("sellQuantity",e);}} onFocus={()=>{onFocusInputs();}} onBlur={(e)=>{onBlurInputs();}}/></td>
                            </tr>
                            <tr>
                                <td>Minimum Brokerage :</td>
                                <td><input type="text" className="form-control" value={sellLadderConfig.minBrokerage} onChange={(e)=>{ onChangeInput("sellMinBrokerage",e);}} onFocus={()=>{onFocusInputs();}} onBlur={(e)=>{onBlurInputs();}}/></td>
                            </tr>
                            <tr>
                                <td>Brokerage Rate (%):</td>
                                <td><input type="text" className="form-control" value={sellLadderConfig.brokerate} onChange={(e)=>{ onChangeInput("sellBrokerate",e);}} onFocus={()=>{onFocusInputs();}} onBlur={(e)=>{onBlurInputs();}}/></td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <div className="row">
                        <div className="col ml-1">
                            <button className="btn btn-block btn-success" onClick={()=>{
                                calculateLadder("sell");
                            }}>CALCULATE</button>
                        </div>
                        <div className="col">
                            <button className="btn btn-block btn-info" onClick={()=>{
                                resetCalculation("sell");
                            }}>RESET</button>
                        </div>
                    </div>
                    <table className="mt-2">
                        <tbody>
                            <tr style={{height:65}}>
                                <td>Absolute Return(%):</td>
                                <td style={{color:stripCommaToNum(computeReturns("ar_p")) >= 0 ? "green": "red",fontWeight:"bold"}}>{!checkReturns()?"Please complete both Buy & Sell ladders to calculate this portion":computeReturns("ar_p")+" %"}</td>
                            </tr>
                            <tr style={{height:65}}>
                                <td>CAGR % :</td>
                                <td style={{color:stripCommaToNum(computeReturns("cagr")) >= 0 ? "green": "red",fontWeight:"bold"}}>{!checkReturns()?"Please complete both Buy & Sell ladders to calculate this portion":computeReturns("cagr")+" %"}</td>
                            </tr>
                        </tbody>
                    </table>
                    {sellTotals.AvgSellPrice && <div className="text-center mt-3">
                            <p style={{fontWeight:"bold"}}>Average Sell Price <span className="ml-5">RM {sellTotals.AvgSellPrice}</span></p>
                        </div>}
                    {sellLadder.length > 0 && <div className="table-responsive">
                        <table className="table">
                                <thead>
                                    <tr style={{backgroundColor:"#b52f2b",color:"white"}}>
                                        <th>No</th>
                                        <th>Price</th>
                                        <th>Quantity of Shares</th>
                                        <th>Value of Shares</th>
                                        <th>Brokerage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sellLadder.map((item,index)=>{
                                            return(
                                                <tr key={index}>
                                                    <td>{item.no}</td>
                                                    <td>RM {item.price}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>RM {item.value}</td>
                                                    <td>RM {item.brokerage}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <td colSpan="2" style={{backgroundColor:"#191d63",color:"white",fontWeight:"bold"}}>
                                            TOTAL
                                        </td>
                                        <td className="bold">{sellTotals.totalQty}</td>
                                        <td className="bold">RM {sellTotals.TotalValueOfSales}</td>
                                        <td className="bold">RM {sellTotals.totalBrk}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        )
    }




    function renderBody(){
        return(
            <div className="row">
                <div className="col">
                    <div className="card card-body">
                        <div className="container-fluid">
                            <h4><strong>Trade Set Up</strong></h4>
                            <br/>
                            {renderStockList()}
                            <hr/>
                            {renderBuySellTable()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div>
            <Screen origin="ladders" setPortfolio={changePortfolio}>
            <div className="container-fluid pt-4">
                {renderBody()}
                <br/>
            </div>
            </Screen>
        </div>
    )
}

export default LadderPage;