import React, { useState } from "react";
import "./style.css";
import { Modal, Button, Form } from "react-bootstrap";

function Footer(){
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [showModal4, setShowModal4] = useState(false);

    return(
        <>
            <footer className="footer">
                <br/>
                <div className='container'>
                <div className='row justify-content-center'>

                <div className='col-md-4 text-white'>
                    <h4>About Us</h4><br/>
                    <a className='footer-item' href='https://equitiestracker.com/et' target="_blank">Corporate Profile</a><br/><br/>
                    <a className='footer-item' href='https://equitiestracker.com/media-coverage' target="_blank">Media Coverage</a><br/><br/>
                    <a className='footer-item' href='https://equitiestracker.com/press-release' target="_blank">Press Releases</a><br/><br/>
                </div>

                <div className='col-md-4 text-white'>
                    <h4>Policies</h4><br/>
                    <a className='footer-item' onClick={()=>setShowModal1(true)}>Anti Money Laundering Policy</a><br/><br/>
                    <a className='footer-item' onClick={()=>setShowModal2(true)}>Anti Bribery and Anti Corruption Policy</a><br/><br/>
                    <a className='footer-item' onClick={()=>setShowModal3(true)}>WhistleBlower Policy</a><br/><br/>
                    <a className='footer-item' onClick={()=>setShowModal4(true)}>Privacy Policy</a><br/><br/>
                </div>

                {/* <div className='col-md-4 text-white'>
                    <h4>Everything Else</h4><br/>
                    <a className='footer-item' href='https://equitiestracker.com/definitions' target="_blank">Definitions</a><br/><br/>
                    <a className='footer-item' href='https://equitiestracker.com/calendar' target="_blank">Our Schedule</a><br/><br/>
                    <a className='footer-item' href='https://equitiestracker.com/privacy' target="_blank">Privacy Policy</a><br/><br/>
                    <a className='footer-item' href='https://equitiestracker.com/terms-and-conditions' target="_blank">Terms and Conditions</a><br/><br/>
                </div> */}

                <div className='col-md-4 text-white'>
                    <h4>Contact Us</h4><br/>
                    <a className='footer-item' href='https://equitiestracker.com/location-map/' target="_blank">Location Map</a><br/><br/>
                    {/* <a className='footer-item' href='https://wa.me/60384082070?text=Hello%20I%27m%20interested' target="_blank">WhatsApp</a><br/><br/>
                    <a className='footer-item' href='https://equitiestracker.com/faq' target="_blank">FAQ</a><br/><br/> */}
                </div>


                </div>
                <p style={{textAlign:"left",color:"white",fontSize:14}}>&copy; {new Date().getFullYear()} ET Smart Wealth Sdn Bhd</p>
                </div>
                <br/>
            </footer>

            <Modal show={showModal1} onHide={() => setShowModal1(false)} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Anti Money Laundering Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        ET Smart Wealth Sdn Bhd ("ETSW"), a licensed Fund Manager regulated under the Securities Commission Malaysia, is steadfast in its commitment to combat the scourge of money laundering and terrorist financing, both of which are criminal acts that inflict untold harm on society.
                    </p>
                    <p>
                        We recognize that most countries have implemented Anti-Money Laundering (AML) and Counter Terrorism Financing (CFT) legislation that imposes stringent obligations on financial service providers to prevent these criminal activities. ETSW remains committed to adhering to the highest standards of regulatory compliance and industry best practices. We are guided by the Anti-Money Laundering, Anti-Terrorism Financing and Proceeds of Unlawful Activities Act 2001 ("AMLATFPUAA"), along with any relevant laws, regulations and guidelines with regards to anti-money laundering and anti-terrorism financing in Malaysia. As such, we have put in place processes and procedures to:
                    </p>
                    <ul>
                        <li>guard against, detect, and report any incidents of money laundering and financing of terrorism to Bank Negara Malaysia;</li>
                        <li>mitigate and manage actual and/or potential exposure to financial crime and breaches of legislation or regulation;</li>
                        <li>ensure that there are robust procedures and controls in place; and</li>
                        <li>protect the assets and reputation of ETSW.</li>
                    </ul>
                </Modal.Body>
            </Modal>

            <Modal show={showModal2} onHide={() => setShowModal2(false)} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Anti Bribery and Anti Corruption Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        ET Smart Wealth Sdn Bhd ("ETSW") is committed to ensure that all its businesses and operations are conducted lawfully, ethically and with integrity. ETSW takes a zero-tolerance approach against all forms of fraud, bribery and corruption and requires the same approach from its Board of Directors, senior management as well as its employees. Similarly, ETSW expects the same commitment from all third parties that it deals with on any matter and in any manner whether they are with private or public sector organisations or government entities.
                    </p>
                    <p>
                        ETSW does not tolerate any person from ETSW as well as any third party seeking or doing business with ETSW from offering, giving, receiving or soliciting any payments to or from any persons whether or not explicitly prohibited by the Anti-Bribery & Anti-Corruption Policy ("ABAC") or by-laws. All ETSW's employees are prohibited to give or offer anything of value including gifts, hospitality or entertainment or except otherwise permitted by this ABAC Policy to anyone for the purpose of improperly obtaining or retaining a business or personal advantage.
                    </p>
                    <p>
                        Should you have any knowledge of or reasonably believe the existence of an attempted, suspected or actual bribery and have any reason to suspect any violation of the ABAC Policy and the related laws, you are expected to report it in good faith via one of the following channels:
                    </p>
                    <ol type="a">
                        <li>A disclosure of improper conduct may be made in writing to the ETSW's Compliance Officer and the disclosure shall be marked as 'Private & Confidential' to the following address:<br/><br/>Compliance Department ET Smart Wealth Sdn Bhd Suite 662, Block A1, Leisure Commerce Square, No. 9, Jalan PJS 8/9, 46150 Petaling Jaya Selangor Darul Ehsan</li>
                        <li>
                            Meet personally with ETSW's Compliance Officer or its officer(s).<br/>Contact the following telephone number(s):
                            <ol type="i">
                                <li>Compliance Officer, Compliance Dept (03-7876 4837)</li>
                                <li>Disclosures of Improper Conduct may also be reported directly via electronic e-mail to andre.gan@equitiestracker.com</li>
                            </ol>
                        </li>
                    </ol>
                </Modal.Body>
            </Modal>

            <Modal show={showModal3} onHide={() => setShowModal3(false)} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>WhistleBlower Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <h3>General</h3>
                    <p>
                        ET Smart Wealth Sdn. Bhd. ("ETSW") is committed to maintaining a culture of the highest ethics and integrity in compliance with all applicable laws, regulations, and internal policies.
                    </p>
                    <p>
                        As part of this commitment, ETSW has in place an avenue for disclosure of any improper conduct. One way for ETSW to detect and deal with improper conduct is through information provided by whistleblowers. Generally, a whistleblower is an insider of an organisation (e.g. employee, consultant or vendor) who reports improper conduct that has occurred within that same organisation.
                    </p>
                    <p>
                        In an effort to encourage whistleblowers to come forward with information on any alleged improper conduct, the Whistleblower Protection Act 2010 provides safe avenues for them to make disclosures of such alleged improper conduct (whistleblowing) to the relevant authorities in good faith, by protecting their identities, providing them with immunity from civil and criminal proceedings and protecting them from detrimental action.
                    </p>
                    <h3>Who is a Whistleblower?</h3>
                    <p>
                        Under the Policy, any of the following persons can be a Whistleblower:
                    </p>
                    <ol type="a">
                        <li>Any employee, agent, or office-bearer in ETSW; and</li>
                        <li>External parties, such as ETSW's customers, service providers, vendors, consultants, or shareholders.</li>
                    </ol>
                    <h3>Confidentiality and Protection of Whistleblower</h3>
                    <p>
                        The identity of a whistleblower who discloses in good faith will be kept confidential and will only be disclosed on a strictly need-to-know basis. Employees who whistleblow in good faith will also be protected by ETSW from any repercussions.
                        <br/>To facilitate the investigation, details, and information on the concern made by the Whistleblower should include name(s) of the person(s) involved, date and location of the event, and provide supporting documents/proof.
                        <br/>Any reports of improper conduct committed by market participants and/or any breach of the securities laws by any market participant should be channelled to the Securities Commission which is an "enforcement agency" under the Whistleblower Protection Act 2010 ("Act").
                    </p>
                </Modal.Body>
            </Modal>

            <Modal show={showModal4} onHide={() => setShowModal4(false)} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Privacy Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>General</h3>
                    <p>
                        At ET Smart Wealth Sdn Bhd ("ETSW"), we value your privacy and strive to protect your personal information in compliance with the laws of Malaysia.<br/>
                        This Privacy Policy explains: 
                    </p>
                    <ul>
                        <li>The type of Data we collect and how we collect it;</li>
                        <li>How we use your Data;</li>
                        <li>The parties that we disclose the Data to;</li>
                        <li>How we store your Data; and</li>
                        <li>How we intend to deliver the rights and protection that you are entitled to, including how to access and update your Data</li>
                    </ul>
                    <p>By providing to us your personal information, you hereby consent to the processing of your personal information in accordance with this Privacy Policy.</p>
                    <p>If you are acting on behalf of a corporation supplying personal information of the corporation's directors, shareholders, and/or officers to us, please do ensure that you have obtained their consent and bring this Privacy Policy to the attention of the said directors, shareholders and/or officers whose personal information you are supplying/have been supplied to us.</p>
                    <p>Please further note that by supplying the personal data to us, the corporation is deemed to have warranted that it has obtained or shall obtain the consent of the aforesaid individuals for the processing of their personal data by us.</p>
                    <h3>Personal Information We Collect</h3>
                    <p>ETSW will process your personal data as listed below which have/has been provided when you make a general inquiry or provide feedback, when you make an appointment to see our Marketing Representatives or where you are applying for new products or services to ETSW such as:</p>
                    <ol style={{listStyleType:'lower-alpha'}}>
                        <li>Name, identity card number/passport number, and date of birth;</li>
                        <li>Contact details including address, phone number(s), and email address;</li>
                        <li>Occupation, income and source of wealth; and</li>
                        <li>Any other personal data required for the purpose as set out below.</li>
                    </ol>
                    <p>ETSW may obtain your personal data from a variety of sources, including but not limited to:</p>
                    <ol style={{listStyleType:'lower-alpha'}}>
                        <li>When you sign up to purchase our products and services from us;</li>
                        <li>When you register your interest with us to purchase the products and services;</li>
                        <li>From third parties connected with you, such as employers, joint account holders, security providers or guarantors, representatives, other partners in your partnership, or through our corporate customers or corporate security providers where you are a director, shareholder, officer, or authorised signatory;</li>
                        <li>When you visit our websites or social networking sites;</li>
                        <li>Pursuant to any transaction or inquiry or communication made with or to us or our business partners; and/or</li>
                        <li>From third-party sources such as credit reference agencies, fraud prevention agencies, publicly available sources of information, industry/financial related associations, social networks, or other organisations.</li>
                    </ol>
                    <p>ETSW may use your personal information for one or more of the following purposes:</p>
                    <ol style={{listStyleType:'lower-alpha'}}>
                        <li>to establish a relationship between you and ETSW and/or companies related to ETSW, ETSW's service providers, business partners, and vendors;</li>
                        <li>To assess your application(s) for our products and services; </li>
                        <li>For carrying out your instructions and the provision of products and services to you, whether provided personally or through telephone or electronic means, including processing of receipts and payments;</li>
                        <li>for the research and development of products and services for customers' use;</li>
                        <li>to evaluate and monitor provision of services;</li>
                        <li>to meet legal and regulatory requirements, which may include disclosure, notification, and record retention requirements;</li>
                        <li>for audit and risk management; </li>
                        <li>to provide you with information on and/or to market ETSW's products, services, and offers which may be of interest to you;</li>
                        <li>any subcontractors which any of our service providers, agents, suppliers, delegates or contractors may appoint from time to time to provide them with services in connection with the Platform or the Services that we offer to you, and their directors, officers, employees, representatives, agents or delegates</li>
                        <li>to respond to your enquiries and complaints;</li>
                        <li>to produce data, reports, and statistics which have been anonymised or aggregated in a manner that does not identify you as an individual;</li>
                        <li>to conduct anti-money laundering checks;</li>
                        <li>for crime detection, prevention and prosecution;</li>
                        <li>for any other purpose that is required or permitted by any law, regulations, standards, guidelines, and/or by relevant regulatory authorities;</li>
                        <li>for such other purposes that are incidental or ancillary or in furtherance to the above purposes.</li>
                    </ol>
                    <p>We will ask for your consent before using your personal information for a purpose other than those that are set out in this Privacy Policy and the privacy terms of any of your agreement(s) with ETSW.</p>
                    <h3>Disclosure of Your Personal Information</h3>
                    <p>During the provision of our products and services to you and the management and/or operation of the same, we may disclose your personal information (to the extent necessary) to the following third parties:</p>
                    <ol style={{listStyleType:'lower-alpha'}}>
                        <li>ETSW including the directors and officers ;</li>
                        <li>companies and/or organisations that act as our agents and/or professional advisers;</li>
                        <li>companies and/or organisations that assist us in processing and/or otherwise fulfilling transactions that you have requested;</li>
                        <li>companies and/or organisations that assist us in providing value added services that you have requested;</li>
                        <li>ETSW's advisers (including but not limited to accountants, auditors, lawyers, financial advisers, consultants or other professional advisers);</li>
                        <li>ETSW's merchants and strategic partners;</li>
                        <li>any person notified by you as authorised to give instructions or to use the account(s)/facility(ies) or products or services on your behalf;</li>
                        <li>any person intending to settle any money outstanding under the facility(ies) granted by us to you subject to your prior authorisation; i.e. any rating agency or credit reporting agency;</li>
                        <li>any person connected to the enforcement or preservation of any of our rights under your agreement(s) with us;</li>
                        <li>any third party service provider, agent or contractor who have been appointed by us to provide services to us whether in or outside Malaysia subject to sufficient security controls over the information;</li>
                        <li>regulatory bodies such as Bank Negara Malaysia, Securities Commission, Bursa Malaysia Securities Berhad, clearing house and other regulatory bodies which have oversight over us and our activities;</li>
                        <li>Credit Bureau established by Bank Negara Malaysia;</li>
                        <li>any other parties to whom such disclosure is required by law or regulatory requirement or under a Court order; and/or</li>
                        <li>any other parties that we may have business or dealings with, subject at all time to any laws (including regulations, guidelines, and/or obligations) applicable to ETSW (whether in or outside Malaysia).</li>
                    </ol>
                    <p>All parties mentioned in above may, in turn, disclose your personal information to other parties such as companies within their group of companies, their affiliates and Directors, shareholders, employees of such companies and affiliates, their professional advisers, service providers, strategic partners, regulators and other parties on a "need to know basis" who or which may have business or dealings with them.</p>
                    <p>We will otherwise treat your personal information as private and confidential and will not disclose your information to anyone outside the ETSW except:</p>
                    <ol style={{listStyleType:'lower-alpha'}}>
                        <li>where you have given permission and/or otherwise consented;</li>
                        <li>where we are required or permitted to do so by law;</li>
                        <li>where required or authorised by any order of the Court;</li>
                        <li>where we may transfer rights and obligations under an agreement; and/or 6</li>
                        <li>where we are required to meet our obligations to any relevant regulatory authority (e.g. Bank Negara Malaysia, Securities Commission, Bursa Malaysia Securities Berhad) and/or under a contract.</li>
                    </ol>
                    <h3>Security of Your Personal Information</h3>
                    <p>Your information is our asset and therefore ETSW places great importance on ensuring the security of your personal information. We regularly review and implement up-to-date technical and organisational security measures when processing your personal information.</p>
                    <p>Employees of ETSW are trained to handle the personal information securely and with strict confidentiality, failing which they may be subject to disciplinary action.<br/>
                    We also impose compliance with this Privacy Policy on our agents, third party service providers, consultants and professional advisors. This serves to ensure that your personal information is safeguarded from unauthorised or accidental loss, misuse, modification, disclosure or destruction.</p>
                    <h3>Retention of Your Personal Information</h3>
                    <p>ETSW will retain your personal information in compliance with this Privacy Policy and/or the terms and conditions of your agreement(s) with ETSW:</p>
                    <ol style={{listStyleType:'lower-alpha'}}>
                        <li>for the duration of your relationship with us;</li>
                        <li>for such period as may be necessary to protect the interests of ETSW and/or our customers notwithstanding that your relationship with us has been terminated;</li>
                        <li>where otherwise required by the law; and/or</li>
                        <li>where required by ETSW's relevant policies.</li>
                    </ol>
                    <h3>Accessing / Correcting / Updating Your Personal Information</h3>
                    <p>You may request for access to, correction, or deletion of your personal information at any time hereafter. Any inquiries or complaints concerning your personal information may also be channelled to ETSW by submitting such request to ETSW via post, email, or facsimile transmission to the following address:<br/><br/>
                    ET Smart Wealth Sdn Bhd<br/>
                    Email address: et.smartwealth@equitiestracker.com</p>
                    <h3>Revisions to the Privacy Policy</h3>
                    <p>Our Privacy Policy may be revised from time to time.<br/>
                    Where the Privacy Policy is revised, revised Privacy Policy will be posted on our website and/or communicated to you via any other means of communication deemed suitable by us. If you continue to use our services/products, you shall be deemed to have agreed to accept such changes.</p>
                    <h3>B. Personal Data Protection Act (PDPA)</h3>
                    <p>We take your personal data privacy seriously. By using our ET Smart Wealth Sdn Bhd ("ETSW") services, you acknowledge and agree to the processing of your personal data in accordance with the Personal Data Protection Act 2010 (PDPA). We collect, use, and disclose your personal data only for the purposes of providing our services to you and in accordance with the law. Your personal data may also be used for internal business purposes, such as data analysis, auditing, and developing new products and services. By using our services, you consent to our collection, use, and disclosure of your personal data in this manner.</p>
                    <ol style={{listStyleType:'lower-roman'}}>
                        <li>Collection of Personal Data<br/>We may collect personal information, such as name, email address, and phone number when you visit our website. We will only collect personal data that is relevant to the purposes for which it is being collected.</li>
                        <li>Use of Personal Data<br/>We will use your personal data for the purposes for which it was collected. This may include responding to your inquiries, providing you with products or services you have requested, or sending you marketing communications.</li>
                        <li>Security<br/>We take reasonable steps to protect the personal data we collect from unauthorised access, use, and disclosure. We use appropriate technical, administrative, and physical safeguards to secure your personal data.</li>
                        <li>Disclosure of Personal Data<br/>We may disclose your personal data to third parties only if required by law, or if we believe that such disclosure is necessary to protect our rights or property, or to prevent harm to others.</li>
                        <li>Retention of Personal Data<br/>We will retain your personal data only for as long as necessary to fulfil the purposes for which it was collected, or as required by law.</li>
                        <li>Access and Correction of Personal Data<br/>You have the right to access and correct your personal data that we hold. If you wish to access or correct your personal data, please contact us.</li>
                        <li>Changes to this Policy<br/>We may update this PDPA policy from time to time to reflect changes to our practices. We encourage you to review this policy periodically.</li>
                    </ol>
                    <p>By using our website, you consent to our collection and use of your personal data in accordance with this PDPA policy.</p>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Footer;