import React, {useState, useEffect} from "react";
import Screen from "../shared/screen";
import StarIcon from '@mui/icons-material/Star';
import {EtTable} from "../shared/table/table";
import "./style.css";
import {axiosInstance} from "../../config/apiTemplate";

const currentYear = new Date().getFullYear();
const windowWidthOffset = 300;

function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

function DivCalPage(){

    const [portfolio, setPortfolio] = useState(localStorage.getItem("t_id"));
    const [calendar,setCalendar] = useState({jan:[],feb:[],mar:[],apr:[],may:[],jun:[],jul:[],aug:[],sep:[],oct:[],nov:[],dec:[]});
    const [auxCal, setAuxCal] = useState({jan:[],feb:[],mar:[],apr:[],may:[],jun:[],jul:[],aug:[],sep:[],oct:[],nov:[],dec:[]});
    const [numYear, setNumYear] = useState(currentYear-1);
    const [display,setDisplay] = useState("AllFrmPortfolio");
    const [currentHover,setCurrentHover] = useState("");
    const [quarter,setQuarter] = useState("all");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [cellWidth, setCellWidth] = useState((window.innerWidth-windowWidthOffset)/12);
    const [stockList, setStockList] = useState([]);

    useEffect(()=>{
        document.title = "EPM - Dividend Calendar";
        getDivCal();
        getAllStocks();
    },[portfolio]);

    useEffect(()=>{
        getDivCal();
    },[numYear,display]);

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setWindowWidth(window.innerWidth);
          }, 1000)
      
          window.addEventListener('resize', debouncedHandleResize)
      
          return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
      }
    })

    useEffect(()=>{
        if(quarter === "all"){
            setCellWidth((windowWidth-windowWidthOffset)/12);
        }else{
            setCellWidth((windowWidth-windowWidthOffset)/3);
        }
    },[windowWidth])

    function changePortfolio(t_id){
        setPortfolio(t_id);
    }

    async function getDivCal(){
        let headerForm = new FormData();
  
        headerForm.append("t_id", portfolio);
        headerForm.append("num_years",numYear);
        headerForm.append("display",display);

        const url = "api/alerts/getDivCal.php";
    
        try {
          const response = await axiosInstance.post(url, headerForm);
          if (response.data.statusCode === 201) {
            setCalendar(response.data.calendar);
            setAuxCal(response.data.calendar);
          }else if(response.data.statusCode === 500){
            getDivCal();
          }
        } catch (e) {
          console.log(e);
        }
    }

    async function getAllStocks(){
        let headerForm = new FormData();
  
        headerForm.append("t_id", portfolio);

        const url = "api/shared/allstocklist.php";
    
        try {
          const response = await axiosInstance.post(url, headerForm);
          if (response.data.statusCode === 201) {
            setStockList(response.data.stocks);
          }else if(response.data.statusCode === 500){
            getAllStocks();
          }
        } catch (e) {
          console.log(e);
        }
    }

    function mouseEnter(item){
        setCurrentHover(item.histId);
    }

    function mouseLeave(){
        setCurrentHover("");
    }

    function changeQuarter(qType){
        setQuarter(qType);
        if(qType === "all"){
            setCellWidth((windowWidth-windowWidthOffset)/12);
        }else{
            setCellWidth((windowWidth-windowWidthOffset)/3);
        }
    }

    function changeType(dType){
        if(dType === "all"){
            setAuxCal(calendar);
        }else{
            let localCal = {...calendar};
            localCal.jan = filterByDType(dType,localCal.jan);
            localCal.feb = filterByDType(dType,localCal.feb);
            localCal.mar = filterByDType(dType,localCal.mar);
            localCal.apr = filterByDType(dType,localCal.apr);
            localCal.may = filterByDType(dType,localCal.may);
            localCal.jun = filterByDType(dType,localCal.jun);
            localCal.jul = filterByDType(dType,localCal.jul);
            localCal.aug = filterByDType(dType,localCal.aug);
            localCal.sep = filterByDType(dType,localCal.sep);
            localCal.oct = filterByDType(dType,localCal.oct);
            localCal.nov = filterByDType(dType,localCal.nov);
            localCal.dec = filterByDType(dType,localCal.dec);

            setAuxCal(localCal);
        }
    }

    function filterByDType(dType, arr){
        return arr.filter((item)=> item.dtype === dType);
    }

    function handleOnChange(origin,e){
        let value = e.target.value;
        switch(origin){
            case "num_years": setNumYear(value); break;
            case "display": setDisplay(value); break;
        }
    }

    function renderHeaderSection(){
        return(
            <table style={{width:"100%"}}>
                <thead>
                    <tr style={{backgroundColor:"#191d63",color:"white"}}>
                        <th>Select View</th>
                        <th>Number of Years</th>
                        <th>Display</th>
                        <th>Quarter View</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className="btn-group" role="group" aria-label="View Btn">
                                <button type="button" className="btn btn-success" onClick={()=>{ changeType("ann"); }}>Announcement Date</button>
                                <button type="button" className="btn btn-danger" onClick={()=>{ changeType("ex"); }}>Ex Date</button>
                                <button type="button" className="btn btn-info" onClick={()=>{ changeType("pay"); }}>Pay Date</button>
                                <button type="button" className="btn btn-primary" onClick={()=>{ changeType("all"); }}>Show All</button>
                            </div>
                        </td>
                        <td>
                            <select className="form-control" value={numYear} onChange={(e)=>{ handleOnChange("num_years",e) }}>
                                <option value={currentYear-1}>Latest 12 Month</option>
                                <option value={currentYear-2}>2-Year</option>
                                <option value={currentYear-3}>3-Year</option>
                                <option value={currentYear-4}>4-Year</option>
                                <option value={currentYear-5}>5-Year</option>
                            </select>
                        </td>
                        <td>
                            <select className="form-control" value={display} onChange={(e)=>{ handleOnChange("display",e) }}>
                                <option value={"AllFrmPortfolio"}>All Stocks in my Portfolio</option>
                                <option value={"AllFrmWatch"}>All Stocks in my Stock Categories List</option>
                                <optgroup label="Individual Stocks">
                                    {
                                        stockList.map((item,index)=>{
                                            return(
                                                <option key={index} value={item.klseCode}>{item.klseName}</option>
                                            )
                                        })
                                    }
                                </optgroup>
                            </select>
                        </td>
                        <td>
                            <div className="btn-group" role="group" aria-label="View Btn">
                                <button type="button" className="btn btn-secondary" onClick={()=>changeQuarter("q1")}>Q1</button>
                                <button type="button" className="btn btn-secondary" onClick={()=>changeQuarter("q2")}>Q2</button>
                                <button type="button" className="btn btn-secondary" onClick={()=>changeQuarter("q3")}>Q3</button>
                                <button type="button" className="btn btn-secondary" onClick={()=>changeQuarter("q4")}>Q4</button>
                                <button type="button" className="btn btn-secondary" onClick={()=>changeQuarter("all")}>FULL</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
   
    function renderCalendarView(){
        return(
            <table style={{width:"100%", tableLayout: "fixed"}}>
                <thead>
                    <tr style={{backgroundColor:"#191d63",color:"white"}}>
                        {(quarter === "all" || quarter === "q1") && <th className="calendarViewMonth" style={{width:cellWidth}}>January</th>}
                        {(quarter === "all" || quarter === "q1") && <th className="calendarViewMonth" style={{width:cellWidth}}>February</th>}
                        {(quarter === "all" || quarter === "q1") && <th className="calendarViewMonth" style={{width:cellWidth}}>March</th>}
                        {(quarter === "all" || quarter === "q2") && <th className="calendarViewMonth" style={{width:cellWidth}}>April</th>}
                        {(quarter === "all" || quarter === "q2") && <th className="calendarViewMonth" style={{width:cellWidth}}>May</th>}
                        {(quarter === "all" || quarter === "q2") && <th className="calendarViewMonth" style={{width:cellWidth}}>June</th>}
                        {(quarter === "all" || quarter === "q3") && <th className="calendarViewMonth" style={{width:cellWidth}}>July</th>}
                        {(quarter === "all" || quarter === "q3") && <th className="calendarViewMonth" style={{width:cellWidth}}>August</th>}
                        {(quarter === "all" || quarter === "q3") && <th className="calendarViewMonth" style={{width:cellWidth}}>September</th>}
                        {(quarter === "all" || quarter === "q4") && <th className="calendarViewMonth" style={{width:cellWidth}}>October</th>}
                        {(quarter === "all" || quarter === "q4") && <th className="calendarViewMonth" style={{width:cellWidth}}>November</th>}
                        {(quarter === "all" || quarter === "q4") && <th className="calendarViewMonth" style={{width:cellWidth}}>December</th>}
                    </tr>
                </thead>
                <tbody style={{verticalAlign:"top"}} className="calendarViewTBody">
                    <tr>
                      {(quarter === "all" || quarter === "q1") && <td>
                         { 
                            auxCal.jan.map((item,index)=>{
                                return(
                                    <div key={index} className={item.histId === currentHover ? item.css+" dividendCard borderBlack" : item.css+" dividendCard"} onMouseEnter={()=>{ mouseEnter(item) }} onMouseLeave={()=>{ mouseLeave() }}>
                                        {item.type === "normal" && <p style={{marginBottom:1}}>{item.klsename}</p>}
                                        {item.type === "special" && <p style={{marginBottom:1}}><StarIcon style={{color:"white",fontSize:13}}/>{item.klsename}</p>}
                                        <p style={{marginBottom:1}}>RM {item.dividend}</p>
                                        <p style={{marginBottom:1}}>({item.date})</p>
                                    </div>
                                )
                            })
                         }
                      </td>}
                      {(quarter === "all" || quarter === "q1") && <td>
                         {
                            auxCal.feb.map((item,index)=>{
                                return(
                                    <div key={index} className={item.histId === currentHover ? item.css+" dividendCard borderBlack" : item.css+" dividendCard"} onMouseEnter={()=>{ mouseEnter(item) }} onMouseLeave={()=>{ mouseLeave() }}>
                                        {item.type === "normal" && <p style={{marginBottom:1}}>{item.klsename}</p>}
                                        {item.type === "special" && <p style={{marginBottom:1}}><StarIcon style={{color:"white",fontSize:13}}/>{item.klsename}</p>}
                                        <p style={{marginBottom:1}}>RM {item.dividend}</p>
                                        <p style={{marginBottom:1}}>({item.date})</p>
                                    </div>
                                )
                            })
                         }
                      </td>}
                      {(quarter === "all" || quarter === "q1") && <td>
                         {
                            auxCal.mar.map((item,index)=>{
                                return(
                                    <div key={index} className={item.histId === currentHover ? item.css+" dividendCard borderBlack" : item.css+" dividendCard"} onMouseEnter={()=>{ mouseEnter(item) }} onMouseLeave={()=>{ mouseLeave() }}>
                                        {item.type === "normal" && <p style={{marginBottom:1}}>{item.klsename}</p>}
                                        {item.type === "special" && <p style={{marginBottom:1}}><StarIcon style={{color:"white",fontSize:13}}/>{item.klsename}</p>}
                                        <p style={{marginBottom:1}}>RM {item.dividend}</p>
                                        <p style={{marginBottom:1}}>({item.date})</p>
                                    </div>
                                )
                            })
                         }
                      </td>}
                      {(quarter === "all" || quarter === "q2") &&<td>
                         {
                            auxCal.apr.map((item,index)=>{
                                return(
                                    <div key={index} className={item.histId === currentHover ? item.css+" dividendCard borderBlack" : item.css+" dividendCard"} onMouseEnter={()=>{ mouseEnter(item) }} onMouseLeave={()=>{ mouseLeave() }}>
                                        {item.type === "normal" && <p style={{marginBottom:1}}>{item.klsename}</p>}
                                        {item.type === "special" && <p style={{marginBottom:1}}><StarIcon style={{color:"white",fontSize:13}}/>{item.klsename}</p>}
                                        <p style={{marginBottom:1}}>RM {item.dividend}</p>
                                        <p style={{marginBottom:1}}>({item.date})</p>
                                    </div>
                                )
                            })
                         }
                      </td>}
                      {(quarter === "all" || quarter === "q2") &&<td>
                         {
                            auxCal.may.map((item,index)=>{
                                return(
                                    <div key={index} className={item.histId === currentHover ? item.css+" dividendCard borderBlack" : item.css+" dividendCard"} onMouseEnter={()=>{ mouseEnter(item) }} onMouseLeave={()=>{ mouseLeave() }}>
                                        {item.type === "normal" && <p style={{marginBottom:1}}>{item.klsename}</p>}
                                        {item.type === "special" && <p style={{marginBottom:1}}><StarIcon style={{color:"white",fontSize:13}}/>{item.klsename}</p>}
                                        <p style={{marginBottom:1}}>RM {item.dividend}</p>
                                        <p style={{marginBottom:1}}>({item.date})</p>
                                    </div>
                                )
                            })
                         }
                      </td>}
                      {(quarter === "all" || quarter === "q2") &&<td>
                         {
                            auxCal.jun.map((item,index)=>{
                                return(
                                    <div key={index} className={item.histId === currentHover ? item.css+" dividendCard borderBlack" : item.css+" dividendCard"} onMouseEnter={()=>{ mouseEnter(item) }} onMouseLeave={()=>{ mouseLeave() }}>
                                        {item.type === "normal" && <p style={{marginBottom:1}}>{item.klsename}</p>}
                                        {item.type === "special" && <p style={{marginBottom:1}}><StarIcon style={{color:"white",fontSize:13}}/>{item.klsename}</p>}
                                        <p style={{marginBottom:1}}>RM {item.dividend}</p>
                                        <p style={{marginBottom:1}}>({item.date})</p>
                                    </div>
                                )
                            })
                         }
                      </td>}
                      {(quarter === "all" || quarter === "q3") &&<td>
                         {
                            auxCal.jul.map((item,index)=>{
                                return(
                                    <div key={index} className={item.histId === currentHover ? item.css+" dividendCard borderBlack" : item.css+" dividendCard"} onMouseEnter={()=>{ mouseEnter(item) }} onMouseLeave={()=>{ mouseLeave() }}>
                                        {item.type === "normal" && <p style={{marginBottom:1}}>{item.klsename}</p>}
                                        {item.type === "special" && <p style={{marginBottom:1}}><StarIcon style={{color:"white",fontSize:13}}/>{item.klsename}</p>}
                                        <p style={{marginBottom:1}}>RM {item.dividend}</p>
                                        <p style={{marginBottom:1}}>({item.date})</p>
                                    </div>
                                )
                            })
                         }
                      </td>}
                      {(quarter === "all" || quarter === "q3") &&<td>
                         {
                            auxCal.aug.map((item,index)=>{
                                return(
                                    <div key={index} className={item.histId === currentHover ? item.css+" dividendCard borderBlack" : item.css+" dividendCard"} onMouseEnter={()=>{ mouseEnter(item) }} onMouseLeave={()=>{ mouseLeave() }}>
                                        {item.type === "normal" && <p style={{marginBottom:1}}>{item.klsename}</p>}
                                        {item.type === "special" && <p style={{marginBottom:1}}><StarIcon style={{color:"white",fontSize:13}}/>{item.klsename}</p>}
                                        <p style={{marginBottom:1}}>RM {item.dividend}</p>
                                        <p style={{marginBottom:1}}>({item.date})</p>
                                    </div>
                                )
                            })
                         }
                      </td>}
                      {(quarter === "all" || quarter === "q3") &&<td>
                         {
                            auxCal.sep.map((item,index)=>{
                                return(
                                    <div key={index} className={item.histId === currentHover ? item.css+" dividendCard borderBlack" : item.css+" dividendCard"} onMouseEnter={()=>{ mouseEnter(item) }} onMouseLeave={()=>{ mouseLeave() }}>
                                        {item.type === "normal" && <p style={{marginBottom:1}}>{item.klsename}</p>}
                                        {item.type === "special" && <p style={{marginBottom:1}}><StarIcon style={{color:"white",fontSize:13}}/>{item.klsename}</p>}
                                        <p style={{marginBottom:1}}>RM {item.dividend}</p>
                                        <p style={{marginBottom:1}}>({item.date})</p>
                                    </div>
                                )
                            })
                         }
                      </td>}
                      {(quarter === "all" || quarter === "q4") &&<td>
                         {
                            auxCal.oct.map((item,index)=>{
                                return(
                                    <div key={index} className={item.histId === currentHover ? item.css+" dividendCard borderBlack" : item.css+" dividendCard"} onMouseEnter={()=>{ mouseEnter(item) }} onMouseLeave={()=>{ mouseLeave() }}>
                                        {item.type === "normal" && <p style={{marginBottom:1}}>{item.klsename}</p>}
                                        {item.type === "special" && <p style={{marginBottom:1}}><StarIcon style={{color:"white",fontSize:13}}/>{item.klsename}</p>}
                                        <p style={{marginBottom:1}}>RM {item.dividend}</p>
                                        <p style={{marginBottom:1}}>({item.date})</p>
                                    </div>
                                )
                            })
                         }
                      </td>}
                      {(quarter === "all" || quarter === "q4") &&<td>
                         {
                            auxCal.nov.map((item,index)=>{
                                return(
                                    <div key={index} className={item.histId === currentHover ? item.css+" dividendCard borderBlack" : item.css+" dividendCard"} onMouseEnter={()=>{ mouseEnter(item) }} onMouseLeave={()=>{ mouseLeave() }}>
                                        {item.type === "normal" && <p style={{marginBottom:1}}>{item.klsename}</p>}
                                        {item.type === "special" && <p style={{marginBottom:1}}><StarIcon style={{color:"white",fontSize:13}}/>{item.klsename}</p>}
                                        <p style={{marginBottom:1}}>RM {item.dividend}</p>
                                        <p style={{marginBottom:1}}>({item.date})</p>
                                    </div>
                                )
                            })
                         }
                      </td>}
                      {(quarter === "all" || quarter === "q4") &&<td>
                         {
                            auxCal.dec.map((item,index)=>{
                                return(
                                    <div key={index} className={item.histId === currentHover ? item.css+" dividendCard borderBlack" : item.css+" dividendCard"} onMouseEnter={()=>{ mouseEnter(item) }} onMouseLeave={()=>{ mouseLeave() }}>
                                        {item.type === "normal" && <p style={{marginBottom:1}}>{item.klsename}</p>}
                                        {item.type === "special" && <p style={{marginBottom:1}}><StarIcon style={{color:"white",fontSize:13}}/>{item.klsename}</p>}
                                        <p style={{marginBottom:1}}>RM {item.dividend}</p>
                                        <p style={{marginBottom:1}}>({item.date})</p>
                                    </div>
                                )
                            })
                         }
                      </td>}
                    </tr>
                </tbody>
            </table>
        )
    }

    

    function renderBody(){
        return(
            <div className="row">
                <div className="col">
                    <div className="card card-body">
                        <div className="container-fluid">
                            <h4><strong>Dividend Calendar</strong></h4>
                            <br/>
                            <div className="table-responsive">
                                {renderHeaderSection()}
                            </div>
                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:5,marginBottom:10}}>
                                <StarIcon style={{color:"red"}}/>
                                <p style={{color:"red",marginBottom:0}}>Special Dividend</p>
                            </div>
                            <div className="table-responsive">
                            {renderCalendarView()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

   
    return(
        <div>
            <Screen origin="divcal" setPortfolio={changePortfolio}>
            <div className="container-fluid pt-4">
                {renderBody()}
                <br/>
            </div>
            </Screen>
        </div>
    )
}

export default DivCalPage;