import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Screen from "../shared/screen";
import "./style.css";
import { axiosInstance } from "../../config/apiTemplate";
import Skeleton from "@mui/material/Skeleton";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { stripCommaToNum } from "../shared/util";

am4core.useTheme(am4themes_animated);

function AggregatedPage() {
    const navChart = useRef(null);
    const [aggregatedData, setAggregatedData] = useState({
        initialTpv: 0,
        currentTpv: 0,
        abs_return: 0,
        abs_return_percentage: 0,
        aggregatedTPV: []
    });
    let productView = localStorage.getItem("productView");

    useEffect(() => {
        getAggregatedData();
    }, []);

    useEffect(() => {
        let chart = am4core.create("chartdivTpv", am4charts.XYChart);

        chart.padding(0, 15, 0, 15);
        chart.colors.step = 3;
        chart.dateFormatter.dateFormat = "yyyy-MM-dd";
        var tpvData = aggregatedData.aggregatedTPV;
    
        chart.data = tpvData;
        // the following line makes value axes to be arranged vertically.
        chart.leftAxesContainer.layout = "vertical";
    
        // uncomment this line if you want to change order of axes
        //chart.bottomAxesContainer.reverseOrder = true;
    
        var dateAxisTpv = chart.xAxes.push(new am4charts.DateAxis());
        dateAxisTpv.renderer.grid.template.location = 0;
        dateAxisTpv.renderer.ticks.template.length = 8;
        dateAxisTpv.renderer.ticks.template.strokeOpacity = 0.1;
        dateAxisTpv.renderer.grid.template.disabled = true;
        dateAxisTpv.renderer.ticks.template.disabled = false;
        dateAxisTpv.renderer.ticks.template.strokeOpacity = 0.2;
        dateAxisTpv.renderer.minLabelPosition = 0.01;
        dateAxisTpv.renderer.maxLabelPosition = 0.99;
        dateAxisTpv.keepSelection = true;
        dateAxisTpv.dateFormats.setKey("day", "MMM d, yyyy");
        dateAxisTpv.groupData = true;
        dateAxisTpv.minZoomCount = 5;
    
        // these two lines makes the axis to be initially zoomed-in
        // dateAxis.start = 0.7;
        // dateAxis.keepSelection = true;
    
        var valueAxisTpv = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxisTpv.tooltip.disabled = true;
        valueAxisTpv.zIndex = 1;
        valueAxisTpv.renderer.baseGrid.disabled = true;
        // height of axis
        valueAxisTpv.height = am4core.percent(65);
    
        valueAxisTpv.renderer.gridContainer.background.fill = am4core.color("#000000");
        valueAxisTpv.renderer.gridContainer.background.fillOpacity = 0.05;
        valueAxisTpv.renderer.inside = true;
        valueAxisTpv.renderer.labels.template.verticalCenter = "bottom";
        valueAxisTpv.renderer.labels.template.padding(2, 2, 2, 2);
    
        //valueAxis.renderer.maxLabelPosition = 0.95;
        valueAxisTpv.renderer.fontSize = "0.8em";
        
        if(aggregatedData.aggregatedTPV.length > 0){
            let firstData = aggregatedData.aggregatedTPV[0];
            let keys = Object.keys(firstData);
            let newSeriesArray = [];
            newSeriesArray = keys.filter((item)=>item !== "date");

            newSeriesArray.map((item)=>{
                var seriesTpv = chart.series.push(new am4charts.LineSeries());
                seriesTpv.dataFields.dateX = "date";
                seriesTpv.dataFields.valueY = item;
                seriesTpv.tooltipText = "{name}: {valueY.formatNumber('RM #,###.##')}";
                seriesTpv.name = item.toUpperCase();
                seriesTpv.strokeWidth = 3;
                seriesTpv.tooltip.getFillFromObject = false;
                seriesTpv.tooltip.getStrokeFromObject = true;
                seriesTpv.tooltip.background.fill = am4core.color("#fff");
                seriesTpv.tooltip.background.strokeWidth = 2;
                // seriesTpv.stroke = am4core.color("#ff1616");
                seriesTpv.tooltip.label.fill = seriesTpv.stroke;
            });
        }
    
        chart.legend = new am4charts.Legend();
        chart.cursor = new am4charts.XYCursor();
    
        navChart.current = chart;
    
        return () => {
            chart.dispose();
        };
    }, [aggregatedData]);

    async function getAggregatedData(){
        let loginForm = new FormData();
        const url = "client_api/aggregated/getAggregatedData.php";
    
        try {
            const response = await axiosInstance.post(url, loginForm);
            if (response.data.status === 201) {
                setAggregatedData(response.data.data);
            } else {
                window.alert(response.data.message);
            }
        } catch (e) {
            console.log(e);
        }
    }

    function renderAggregatedBody(){
        return(
            <div className="aggregatedDashboardContainer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <div className="aggStatBox">
                                <h3 className="boldText mb-3">Total Initial Portfolios Value</h3>
                                <h4 className="boldText text-center">RM {aggregatedData.initialTpv}</h4>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <div className="aggStatBox">
                                <h3 className="boldText mb-3">Total Current Portfolios Value</h3>
                                <h4 className="boldText text-center">RM {aggregatedData.currentTpv}</h4>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <div className="aggStatBox">
                                <h3 className="boldText mb-3">Total Absolute Return</h3>
                                <h4 className="boldText text-center" style={{color:stripCommaToNum(aggregatedData.abs_return) >= 0 ?"green":"red"}}>RM {aggregatedData.abs_return}</h4>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <div className="aggStatBox">
                                <h3 className="boldText mb-3">Total Absolute Return %</h3>
                                <h4 className="boldText text-center" style={{color:stripCommaToNum(aggregatedData.abs_return) >= 0 ?"green":"red"}}>{aggregatedData.abs_return_percentage} %</h4>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="text-center">
                        {aggregatedData.aggregatedTPV.length === 0
                        ?
                            <div className="text-center" style={{paddingTop:30,paddingBottom:15}}>
                                <h1 style={{color:'rgba(0, 0, 0, 0.6)'}}>No Data</h1>
                            </div>
                        :
                            <div
                                id='chartdivTpv'
                                style={{ width: "100%", minHeight: 500, height: "100%" }}
                            />
                        }
                    </div>
                </div>

                
            </div>
        )
    }

    return (
        <div>
            <Screen
                origin='aggregated'
            >
                <div style={{textAlign:'center',fontSize:30}}>
                    <span>Total Portfolio Overview</span>
                </div>
                {renderAggregatedBody()}
                <br />
            </Screen>
        </div>
    );
}

export default AggregatedPage;
