import React, {useState, useEffect} from "react";
import Screen from "../shared/screen";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {EtTable} from "../shared/table/table";
import "./style.css";
import {axiosInstance} from "../../config/apiTemplate";

function AlertsPage(){

    const [portfolio, setPortfolio] = useState(localStorage.getItem("t_id"));
    const [capToggle, setCapToggle] = useState(true);
    const [divAnnToggle, setdivAnnToggle] = useState(true);
    const [latestDivToggle, setlatestDivToggle] = useState(true);
    const [divNeToggle, setdivNeToggle] = useState(true);
    const [alerts,setAlerts] = useState({capChange:{data:[]},divAnn:{data:[],total:0},divEx:{data:[],total:0},divPay:{data:[],total:0}})
    const [isAllChecked, setIsAllChecked] = useState(false);

    useEffect(()=>{
        document.title = "EPM - Alerts";
        getDivAlerts();
    },[portfolio]);

    function changePortfolio(t_id){
        setPortfolio(t_id);
    }

    async function getDivAlerts(){
        let headerForm = new FormData();
  
        headerForm.append("t_id", portfolio);

        const url = "client_api/alerts/getDivAlerts.php";
    
        try {
          const response = await axiosInstance.post(url, headerForm);
          if (response.data.statusCode === 201) {
            setAlerts(response.data.alerts);
            if(response.data.alerts.capChange.data.length === 0){
                setCapToggle(false);
            }
            if(response.data.alerts.divAnn.data.length === 0){
                setdivAnnToggle(false);
            }
            if(response.data.alerts.divEx.data.length === 0){
                setlatestDivToggle(false);
            }
            if(response.data.alerts.divPay.data.length === 0){
                setdivNeToggle(false);
            }
          }else if(response.data.statusCode === 500){
            getDivAlerts();
          }
        } catch (e) {
          console.log(e);
        }
    }

    function handleOnCheckboxChange(){
        let localAlerts = {...alerts};
        localAlerts.divPay.data = localAlerts.divPay.data.map((item)=>{
            return {...item,isSelected:!isAllChecked};
        });
        setAlerts(localAlerts);
        setIsAllChecked(!isAllChecked);
    }

    function handleSingleCheck(index){
        let localAlerts = {...alerts};
        localAlerts.divPay.data[index].isSelected = !localAlerts.divPay.data[index].isSelected;
        setAlerts(localAlerts);
    }


    function renderCapChange(){
        return(
            <div>
                <div className="etBlueBanner" onClick={()=>{ setCapToggle(!capToggle) }}>
                    <div className="etBannerTitleFlex">
                        <h6 className="etBannerTitle">Latest Rolling 12 Month Capital Changes</h6>
                    </div>
                    <div className="etBannerToggleIconFlex">
                        {capToggle?<KeyboardArrowUpIcon className="etBannerToggleIcon" />:<KeyboardArrowDownIcon className="etBannerToggleIcon" />}
                    </div>
                </div>
                {capToggle && <div className="etBannerContent">
                        <EtTable 
                            limit={15}
                            data={alerts.capChange.data}
                            sortable={false}  
                            headers={[
                                {display:"No"},
                                {display:"Ex-Date"},
                                {display:"Stock Name"},
                                {display:"Capital Change Type"},
                                {display:"Issue Price"},
                                {display:"Ratio"},
                                {display:"Description"},
                                {display:"Days Till Ex-Date"},
                            ]}
                            displayFieldKeys={[
                                {key:"no"},
                                {key:"exDate"},
                                {key:"stockName",style:{"fontWeight":"bold"}},
                                {key:"type"},
                                {key:"issuePrice",prepend:"RM "},
                                {key:"ratio"},
                                {key:"desc"},
                                {key:"daysEx",styleKey:"daysExColor"},
                            ]}
                            showPagination={true}
                        />
                </div>}
            </div>
        );
    }

    function renderDivAnnounced(){
        return(
            <div>
                <div className="etBlueBanner" onClick={()=>{ setdivAnnToggle(!divAnnToggle) }}>
                    <div className="etBannerTitleFlex">
                        <h6 className="etBannerTitle">Latest 20 Dividends Announced</h6>
                    </div>
                    <div className="etBannerToggleIconFlex">
                        {divAnnToggle?<KeyboardArrowUpIcon className="etBannerToggleIcon" />:<KeyboardArrowDownIcon className="etBannerToggleIcon" />}
                    </div>
                </div>
                {divAnnToggle && <div className="etBannerContent">
                    <EtTable 
                                limit={15}
                                data={alerts.divAnn.data}
                                sortable={false}
                                headers={[
                                    {display:"No"},
                                    {display:"Ex-Date"},
                                    {display:"Payment Date"},
                                    {display:"Stock Name"},
                                    {display:"Dividend Desc"},
                                    {display:"Dividend Amount"},
                                    {display:"Days Till Ex-Date"},
                                    // {display:"Quantity On Hand"},
                                    {display:"Net Dividend Amount"},
                               ]}
                                displayFieldKeys={[
                                    {key:"no"},
                                    {key:"exDate"},
                                    {key:"paymentDate"},
                                    {key:"stockName",style:{"fontWeight":"bold"}},
                                    {key:"divDesc"},
                                    {key:"divAmt",prepend:"RM "},
                                    {key:"daysEx",styleKey:"daysExColor"},
                                    // {key:"quantity"},
                                    {key:"netDivAmt",prepend:"RM "},
                                ]}
                                showPagination={true}
                    />
                     <div style={{display:"flex",padding:10, alignItems:"center", justifyContent:"flex-end"}}>
                        <h6 style={{fontWeight:"bold"}}>TOTAL DIVIDEND AMOUNT PAYABLE : RM {alerts.divAnn.total}</h6>
                    </div>
                </div>}
            </div>
        );
    }

    function renderLatestDiv(){
        return(
            <div>
                <div className="etBlueBanner" onClick={()=>{ setlatestDivToggle(!latestDivToggle) }}>
                    <div className="etBannerTitleFlex">
                        <h6 className="etBannerTitle">Latest 20 Dividends After Ex Date</h6>
                    </div>
                    <div className="etBannerToggleIconFlex">
                        {latestDivToggle?<KeyboardArrowUpIcon className="etBannerToggleIcon" />:<KeyboardArrowDownIcon className="etBannerToggleIcon" />}
                    </div>
                </div>
                {latestDivToggle && <div className="etBannerContent">
                    <EtTable 
                                limit={15}
                                data={alerts.divEx.data}
                                sortable={false}
                                headers={[
                                    {display:"No"},
                                    {display:"Ex-Date"},
                                    {display:"Payment Date"},
                                    {display:"Stock Name"},
                                    {display:"Dividend Desc"},
                                    {display:"Dividend Amount"},
                                    {display:"Days Till Payment"},
                                    // {display:"Quantity On Ex-Date"},
                                    {display:"Net Dividend Payable"},
                               ]}
                                displayFieldKeys={[
                                    {key:"no"},
                                    {key:"exDate"},
                                    {key:"paymentDate"},
                                    {key:"stockName",style:{"fontWeight":"bold"}},
                                    {key:"divDesc"},
                                    {key:"divAmt",prepend:"RM "},
                                    {key:"daysEx"},
                                    // {key:"quantity"},
                                    {key:"netDivAmt",prepend:"RM "},
                                ]}
                                showPagination={true}
                    />
                    <div style={{display:"flex",padding:10, alignItems:"center", justifyContent:"flex-end"}}>
                        <h6 style={{fontWeight:"bold"}}>TOTAL DIVIDEND AMOUNT PAYABLE : RM {alerts.divEx.total}</h6>
                    </div>
                </div>}
            </div>
        );
    }

    function renderDivNotEntered(){
        return(
            <div>
                <div className="etBlueBanner" onClick={()=>{ setdivNeToggle(!divNeToggle) }}>
                    <div className="etBannerTitleFlex">
                        <h6 className="etBannerTitle">Dividends Not Entered</h6>
                    </div>
                    <div className="etBannerToggleIconFlex">
                        {divNeToggle?<KeyboardArrowUpIcon className="etBannerToggleIcon" />:<KeyboardArrowDownIcon className="etBannerToggleIcon" />}
                    </div>
                </div>
                {divNeToggle && <div className="etBannerContent">
                    <div className="card card-body table-responsive" style={{padding:0}}>
                        <table className="table summaryTable">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Stock Name</th>
                                    <th>Ex-Date</th>
                                    <th>Dividend Desc</th>
                                    <th>Dividend Amount</th>
                                    {/* <th>Quantity On Ex-Date</th> */}
                                    <th>Net Dividend Payable</th>
                                    <th>SELECT ALL <input type="checkbox" checked={isAllChecked?true:false} onChange={()=>{ handleOnCheckboxChange() }}/></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    alerts.divPay.data.map((item,index)=>{
                                        return(
                                            <tr key={index}>
                                                <td>{item.no}</td>
                                                <td>{item.stockName}</td>
                                                <td>{item.exDate}</td>
                                                <td>{item.divDesc}</td>
                                                <td>{item.divAmt}</td>
                                                {/* <td>{item.quantity}</td> */}
                                                <td>{item.netDivAmt}</td>
                                                <td><input type="checkbox" checked={item.isSelected?true:false} onChange={()=>{ handleSingleCheck(index) }}/></td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td colSpan={4}></td>
                                    <td colSpan={2}><h6 style={{fontWeight:"bold"}}>TOTAL DIVIDEND AMOUNT PAYABLE :</h6></td>
                                    <td style={{fontWeight:"bold"}}>RM {alerts.divPay.total}</td>
                                    <td><button className="btn btn-block btn-success">INSERT SELECTED DIVIDENDS</button></td>
                                </tr>
                            </tbody>
                        </table>
                        {
                            alerts.divPay.data.length === 0 && <p className="text-center" style={{fontStyle:"italic"}}>NO RECORDS FOUND</p>
                        }
                    </div>
                </div>}
            </div>
        );
    }
    

    function renderBody(){
        return(
            <div className="row">
                <div className="col">
                    <div className="card card-body">
                        <div className="container-fluid">
                            <h4><strong>Dividend & Capital Change Alerts</strong></h4>
                            <br/>
                            {renderCapChange()}
                            <br/>
                            {renderDivAnnounced()}
                            <br/>
                            {renderLatestDiv()}
                            <br/>
                            {renderDivNotEntered()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div>
            <Screen origin="alerts" setPortfolio={changePortfolio}>
            <div className="container-fluid pt-4">
                {renderBody()}
                <br/>
            </div>
            </Screen>
        </div>
    )
}

export default AlertsPage;