import './App.css';
import Navigator from '../src/navigator/navigator';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { red,indigo,grey } from '@mui/material/colors';

const theme = createTheme({
    palette: {
      error: {
        main: red[500],
        contrastText:"#fff"
      },
      muted:{
        main: grey[500],
        contrastText:"#fff"
      },
      google:{
        main:"#DB4437",
        contrastText:"#fff"
      },
      white:{
        main:"#fff",
        contrastText:"#fff"
      },
      youtube:{
        main:"#FF0000",
        contrastText:"#fff"
      },
      whatsapp:{
        main:"#128C7E"
      },
      mainBgDark:{
        main:"rgba(29,40,53,.95)",
        contrastText:"#fff"
      },
      themegreen:{
        main:"#3cd2a5",
        contrastText:"#fff"
      },
      drawerIconInactive:{
        main:"hsla(0,0%,100%,.5)"
      },
      drawerIconActive:{
        main:"#4e9cff",
        contrastText:"#fff"
      }
    },
    components:{
        MuiTextField:{
            styleOverrides:{
                root:{
                    fieldset:{
                        legend:{
                            width: "unset",
                        }
                    }
                }
            }
        }
    }
});

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider  theme={theme}>
            <Navigator />
        </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
