import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import StatusPage from "../components/status/status";
import DashboardPage from "../components/dashboard/dashboard";
import LoginPage from "../components/login/login";
import ErrorPage from "../components/error/error";
import AccountPage from "../components/account/account";
import LadderPage from "../components/ladders/ladder";
import LogsPage from "../components/logs/log";
import BinPage from "../components/bin/bin";
import AlertsPage from "../components/alerts/alert";
import DivCalPage from "../components/divcal/divcal";
import MStatementPage from "../components/statements/statement";
import ResetPasswordPage from "../components/reset-password/reset-password";
import ReportsPage from "../components/reports/reports";
import RecordingPage from "../components/recordings/recordings";
import AggregatedPage from "../components/aggregated/aggregated";

import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
    'ebeeb52ce886ea4caa53e5d01b23ba01Tz00OTAwNixFPTE2OTE5MTM0NTA0ODQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

function checkLogin(){
  let token = localStorage.getItem("token");
  let refreshToken = localStorage.getItem("rt")

  if(token && refreshToken){
    return true;
  }else{
    return false;
  }
}

export default function Navigator(){
  
      return (
        <Router style={{ height: "100%" }}>
          <Switch>
          <Route exact path="/" render={()=> checkLogin()?<DashboardPage />:<LoginPage/>} />
          <Route path="/dashboard" render={()=> checkLogin()?<DashboardPage />:<LoginPage/>} />
          <Route path="/status" render={()=> checkLogin()?<StatusPage />:<LoginPage/>} />
          <Route path="/home" render={()=> checkLogin()?<DashboardPage />:<LoginPage/>} />
          <Route path="/logs" render={()=> checkLogin()?<LogsPage />:<LoginPage/>} />
          <Route path="/ladders" render={()=> checkLogin()?<LadderPage />:<LoginPage/>} />
          <Route path="/account" render={()=> checkLogin()?<AccountPage />:<LoginPage/>} />
          <Route path="/bin" render={()=> checkLogin()?<BinPage />:<LoginPage/>} />
          <Route path="/alerts" render={()=> checkLogin()?<AlertsPage />:<LoginPage/>} />
          <Route path="/divcal" render={()=> checkLogin()?<DivCalPage />:<LoginPage/>} />
          <Route path="/monthly_statement" render={()=> checkLogin()?<MStatementPage />:<LoginPage/>} />
          <Route path="/login" render={()=><LoginPage/>} />
          <Route path="/reset-password" render={()=><ResetPasswordPage/>} />
          <Route path="/reports" render={()=> checkLogin()?<ReportsPage />:<LoginPage/>} />
          <Route path="/recordings" render={()=> checkLogin()?<RecordingPage />:<LoginPage/>} />
          <Route path="/aggregated" render={()=> <AggregatedPage/>} />
          <Route path="/404" component={ErrorPage} />
          <Route path="*">
            <Redirect to="/404" />
          </Route>
          </Switch>
        </Router>
      );
  }