import React, {useState, useEffect} from "react";
import { Modal,Button } from "react-bootstrap";
import { axiosInstance } from "../../config/apiTemplate";
import CircularProgress from '@mui/material/CircularProgress';

function PasswordChangeModal(props){
    const [passwordObj, setPasswordObjChange] = useState({password:"",confirm_password:""});
    const [isLoading, setIsLoading] = useState(false);

    // APIS ======================================================================================

    async function submitPasswordChangeApi(){
        let loginForm = new FormData();
        loginForm.append("password",passwordObj.password);
        loginForm.append("confirm_password",passwordObj.confirm_password);
        const url = "client_api/account/changePasswordOnboard.php";
  
        try {
          const response = await axiosInstance.post(url, loginForm);
          if (response.data.status === 201) {
            props.parentProp(new Date().getTime());
          } else {
            window.alert(response.data.message);
          }
          setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.log(e);
        }
    }


    // FUNCTION ======================================================================================

    function submitPasswordChange(){
        if(passwordObj.password === ""){
            window.alert("Password cannot be empty!");
        }else if(passwordObj.confirm_password === ""){
            window.alert("Confirm Password cannot be empty!");
        }else if(passwordObj.password !== passwordObj.confirm_password){
            window.alert("Password and Confirm Password does not match!");
        }else{
            setIsLoading(true);
            submitPasswordChangeApi();
        }
    }

    // RENDER ======================================================================================

    return(
        <>
            <Modal show={props.show} backdrop="static" size="lg">
                <Modal.Header>
                <Modal.Title>Password Change</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="form-group">
                        <label className="marginBottomZero">New Password<span className="mandatory">*</span></label>
                        <p className="text-muted marginBottomZero" style={{fontSize:13}}>Password must be <strong>minimum 9 characters</strong>, consist of <strong>Uppercase</strong>, <strong>Lowercase</strong>, <strong>Symbol</strong> and <strong>Number</strong></p>
                        <input type="password" className="form-control" value={passwordObj.password} onChange={(e)=>{ setPasswordObjChange({...passwordObj,password:e.target.value}); }}/>
                    </div>

                    <div className="form-group">
                        <label className="marginBottomZero">New Confirm Password<span className="mandatory">*</span></label>
                        <p className="text-muted marginBottomZero" style={{fontSize:13}}>Password must be <strong>minimum 9 characters</strong>, consist of <strong>Uppercase</strong>, <strong>Lowercase</strong>, <strong>Symbol</strong> and <strong>Number</strong></p>
                        <input type="password" className='form-control' value={passwordObj.confirm_password} onChange={(e)=>{ setPasswordObjChange({...passwordObj,confirm_password:e.target.value}); }}/>
                    </div>
                  
                </Modal.Body>
                <Modal.Footer>
                {!isLoading && <Button variant='primary' onClick={() => submitPasswordChange()}>
                    Submit
                </Button>}
                {isLoading && <CircularProgress />}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PasswordChangeModal;