import React,{useState,useEffect} from "react";
import "./style.css";
import TableChartIcon from '@mui/icons-material/TableChart';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import RefreshIcon from '@mui/icons-material/Refresh';
import DescriptionIcon from '@mui/icons-material/Description';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ArticleIcon from '@mui/icons-material/Article';
import Tooltip from '@mui/material/Tooltip';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { set } from "date-fns";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import BusinessIcon from '@mui/icons-material/Business';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

function redirect(index,products){    
    // Check if productView is Aggregated, update productView to next product after Aggregated
    let productView = localStorage.getItem("productView");
    if(productView === "Aggregated"){
        let index = products.indexOf("Aggregated");
        if(index !== -1){
            localStorage.setItem("productView",products[index+1]);
        }
        else{
            localStorage.setItem("productView",products[0]);
        }
    }

    switch(index){
        case 0: window.location.href="/";break;
        case 1: window.location.href="/status";break;
        case 2: window.location.href="/logs";break;
        case 3: window.location.href="/account";break;
        case 4: window.location.href="/monthly_statement";break;
        case 5: window.location.href="/alerts";break;
        case 6: window.location.href="/divcal";break;
        case 7: window.location.href="/reports";break;
        case 8: window.location.href='/recordings';break;
        default: window.location.href="/";break;
    }
}

function SideBar(props){
    // console.log(props);
    let {origin, products} = props;

    let productView = localStorage.getItem("productView");

    // console.log(products);

    // Init state
    const [showViewSwitchModal,setShowViewSwitchModal] = useState(false);

    function renderSidebar(){
        if(props.toggle){
            return(
                <ul className="sideBarMenu">
                    {
                        productView === "Aggregated" 
                        ?
                        (
                            <>
                                <li style={{height:'56px'}}>&nbsp;</li>
                                <li style={{height:'56px'}}>&nbsp;</li>
                                <li style={{height:'56px'}}>&nbsp;</li>
                                <li style={{height:'56px'}}>&nbsp;</li>
                            </>
                        )
                        :
                        (
                            <>
                                <li className={origin === "dashboard" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(0,products)}>
                                    <DashboardIcon className={origin === "dashboard" ? "sidebarIconSelected" :"sidebarIcon"}/>Dashboard
                                </li>
                                <li className={origin === "status" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(1,products)}>
                                    <TableChartIcon className={origin === "status" ? "sidebarIconSelected" :"sidebarIcon"}/>Status
                                </li>
                                <li  className={origin === "logs" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(2,products)}>
                                    <DescriptionIcon className="sidebarIcon"/>Logs
                                </li>
                                <li className={origin === "reports" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(7,products)}>
                                    <ArticleIcon className="sidebarIcon"/>Reports
                                </li>
                            </>
                        )
                    }
                    

                {/* <li className={origin === "alerts" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(5)}>
                    <NotificationsIcon className="sidebarIcon"/>Alerts
                </li>
                <li className={origin === "divcal" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(6)}>
                    <DateRangeIcon className="sidebarIcon"/>Div. Cal.
                </li> */}
                {/* <li className={origin === "ladders" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(2)}>
                    <ClearAllIcon className="sidebarIcon"/>Ladders
                </li> */}
                {/* <li className={origin === "statement" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(4)}>
                    <ReceiptIcon className="sidebarIcon"/>Statement
                </li> */}

                <li  className={origin === "account" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(3,products)}>
                    <AccountBoxIcon className="sidebarIcon"/>Account Info
                </li>
                <li  className={origin === "recordings" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(8,products)}>
                    <OndemandVideoIcon className="sidebarIcon"/>Recordings
                </li>
                <hr/>
                <li  className="sideBarItem refreshItem" onClick={()=>{
                    if(typeof props.invokeRefresh === "function"){
                        props.invokeRefresh();
                    }
                }}>
                    <RefreshIcon className="sidebarIcon refreshIcon" /> Refresh
                </li>
            </ul>
            )
        }else{
            return(
                <ul className="sideBarMenu">
                    {
                        productView === "Aggregated" 
                        ?
                        (
                            <>
                                <li style={{height:'56px'}}>&nbsp;</li>
                                <li style={{height:'56px'}}>&nbsp;</li>
                                <li style={{height:'56px'}}>&nbsp;</li>
                                <li style={{height:'56px'}}>&nbsp;</li>
                            </>
                        )
                        :
                        (
                            <>
                                <li className={origin === "dashboard" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(0,products)} style={{textAlign:"center"}}>
                                    <Tooltip title="Dashboard">
                                        <DashboardIcon className={origin === "dashboard" ? "sidebarIconSelected" :"sidebarIcon"}/>
                                    </Tooltip>
                                </li>
                                <li className={origin === "status" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(1,products)} style={{textAlign:"center"}}>
                                    <Tooltip title="Status">
                                        <TableChartIcon className={origin === "status" ? "sidebarIconSelected" :"sidebarIcon"}/>
                                    </Tooltip>
                                </li>
                                <li  className={origin === "logs" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(2,products)} style={{textAlign:"center"}}>
                                    <Tooltip title="Logs">
                                        <DescriptionIcon className="sidebarIcon"/>
                                    </Tooltip>
                                </li>
                                <li className={origin === "reports" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(7,products)}  style={{textAlign:"center"}}>
                                    <Tooltip title="Reports">
                                        <ArticleIcon className="sidebarIcon"/>
                                    </Tooltip>
                                </li>
                            </>
                        )
                    }
                    


                    {/* <li className={origin === "alerts" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(5)} style={{textAlign:"center"}}>
                        <NotificationsIcon className="sidebarIcon"/>
                    </li>
                    <li className={origin === "divcal" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(6)} style={{textAlign:"center"}}>
                        <DateRangeIcon className="sidebarIcon"/>
                    </li> */}
                    {/* <li className={origin === "ladders" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(2)} style={{textAlign:"center"}}>
                        <ClearAllIcon className="sidebarIcon"/>
                    </li> */}
                     {/* <li className={origin === "statement" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(4)}  style={{textAlign:"center"}}>
                        <Tooltip title="Statement">
                            <ReceiptIcon className="sidebarIcon"/>
                        </Tooltip>
                    </li> */}

                    <li  className={origin === "account" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(3,products)} style={{textAlign:"center"}}>
                        <Tooltip title="Account Info">
                            <AccountBoxIcon className="sidebarIcon"/>
                        </Tooltip>
                    </li>
                    <li  className={origin === "recordings" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(8,products)} style={{textAlign:"center"}}>
                        <OndemandVideoIcon className="sidebarIcon"/>
                    </li>
                    {/* <li className={origin === "bin" ? "sideBarItemSelected" : "sideBarItem"} onClick={()=>redirect(4)} style={{textAlign:"center"}}>
                        <DeleteIcon className="sidebarIcon"/>
                    </li> */}
                    <hr/>
                    {/* <li  className="sideBarItem" style={{textAlign:"center"}} onClick={()=> setShowViewSwitchModal(true) }>
                        <Tooltip title="Switch Display">
                            <SwitchAccountIcon className="sidebarIcon" />
                        </Tooltip>
                    </li> */}
                    <li  className="sideBarItem refreshItem" style={{textAlign:"center"}} onClick={()=>{
                        if(typeof props.invokeRefresh === "function"){
                            props.invokeRefresh();
                        }
                    }}>
                        <Tooltip title="Refresh">
                            <RefreshIcon className="sidebarIcon refreshIcon" />
                        </Tooltip>
                    </li>
                </ul>
            )
        }
    }

    return(
        <div className="sideBarContainer">
            {renderSidebar()}

            {/* <Dialog onClose={() => setShowViewSwitchModal(false)} open={showViewSwitchModal}>
                <DialogTitle>Switch Display</DialogTitle>
                <List sx={{ pt: 0 }}>
                    {products.map((product) => (
                        <ListItem disableGutters key={product}>
                            <ListItemButton onClick={() => {
                                props.setProductView(product);
                                setShowViewSwitchModal(false);
                            }}>
                                <ListItemAvatar style={{minWidth:'40px'}}>
                                    {product === "Aggregated" && <MergeTypeIcon />}
                                    {product === "Snowball" && <BusinessIcon />}
                                    {product === "Digital Asset Tracker" && <CurrencyBitcoinIcon />}
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={product}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Dialog> */}
        </div>
    )

}



export default SideBar;