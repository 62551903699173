import React, {useState,useEffect} from "react";
import Screen from "../shared/screen";
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {axiosInstance} from "../../config/apiTemplate";
import Button from '@mui/material/Button';

const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    },
  });


function AccountPage(){

    const [portfolio, setPortfolio] = useState(localStorage.getItem("t_id"));
    const [timeStamp, setTS] = useState(new Date().getTime());
    const [userInfo,setUserInfo] = useState({email:localStorage.getItem("email"),password:"",new_pass:"",confirm_pass:""})
    const [portfolioName, setPortfolioName] = useState("");

    useEffect(()=>{
        document.title = "ETSW - My Account";

    },[portfolio,timeStamp]);


    function changePortfolio(t_id){
        setPortfolio(t_id);
    }

    function invokeRefresh(){
        setTS(new Date().getTime());
    }


    // async function submitPassChange(){

    //     if(userInfo.password === ""){
    //         window.alert("Password cannot be empty!");
    //     }else if(userInfo.new_pass !== userInfo.confirm_pass){
    //         window.alert("New password mismatch!");
    //     }else{
    //         let dashboardForm = new FormData();

    //         const url = "client_api/account/changePassword.php";
    //         dashboardForm.append("old_password",userInfo.password);
    //         dashboardForm.append("new_password",userInfo.new_pass);
    
    //         try {
    //           const response = await axiosInstance.post(url, dashboardForm);
    //           if (response.data.status === 201) {
    //             window.alert(response.data.message);
    //           }
    //         } catch (e) {
    //           console.log(e);
    //         }
    //     }
    // }

    async function resetPassword(){
        let dashboardForm = new FormData();

        const url = "client_api/account/requestPasswordChange.php";
        try {
            const response = await axiosInstance.post(url, dashboardForm);
            if (response.data.status === 201) {
                window.alert(response.data.message);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function updatePortfolioNameApi(){
        let dashboardForm = new FormData();
        dashboardForm.append("portfolio_name",portfolioName);
        const url = "client_api/account/updatePortfolioName.php";
        try {
            const response = await axiosInstance.post(url, dashboardForm);
            if (response.data.status === 201) {
                window.alert(response.data.message);
                invokeRefresh();
            }
        } catch (e) {
            console.log(e);
        }
    }


    function updatePortfolioName(){
        if(portfolioName === ""){
            window.alert("Portfolio name cannot be empty!");
        }else if(portfolioName.length > 50){
            window.alert("Portfolio name cannot be more than 50 characters!");
        }else{
            updatePortfolioNameApi();
        }
    }

    function renderBody(){
        return(
            <div>
                <div className="row">
                    <div className="col">
                        <div className="container-fluid">
                            <div className="statusCardHeader row align-items-center justify-content-between" onClick={()=>{

                            }}>
                                <div className="col">
                                    <h6 className="statusCardHeaderTitle">CHANGE PORTFOLIO NAME</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col bg-white text-center" style={{marginLeft:15,marginRight:15}}>
                                    <div className="form-group mt-2">
                                        <label>Portfolio Name</label>
                                        <input className="form-control" type="text" value={portfolioName} maxLength="50" max={50} onChange={(e)=>setPortfolioName(e.target.value)}/>
                                    </div>
                                    <Button variant="contained" color="primary" onClick={()=>updatePortfolioName()}>Submit</Button>
                                    <br/><br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <div className="container-fluid">
                            <div className="statusCardHeader row align-items-center justify-content-between" onClick={()=>{

                            }}>
                                <div className="col">
                                    <h6 className="statusCardHeaderTitle">REQUEST PASSWORD RESET</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col bg-white text-center" style={{marginLeft:15,marginRight:15}}>
                                <br/>
                

                                    <Button variant="contained" color="primary" onClick={()=>resetPassword()}>Request Password RESET</Button>
                                    <br/><br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    return(
        <div>
            <Screen origin="account" setPortfolio={changePortfolio} invokeRefresh={invokeRefresh}>
            <div className="container pt-4">
                {renderBody()}
            </div>
            <br/>
            </Screen>
        </div>
    )
}

export default AccountPage;