import React,{useState, useEffect} from 'react'
import HeaderBar from "../shared/header";
import Footer from "../shared/footer";
import CircularProgress from "@mui/material/CircularProgress";
import { Form } from "react-bootstrap";
import { axiosInstance } from "../../config/apiTemplate";

export default function ResetPasswordPage() {

    const [password, setPassword] = useState({password:"",confirm_password:""});
    const [isLoading, setIsLoading ] = useState(false);
    const [resetToken, setResetToken ] = useState("");

    useEffect(()=>{
        //get token from url, if dont have, straight redirect
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if(urlParams.has("token")){
            const token = urlParams.get("token");
            setResetToken(token);
        }else{
            window.location.href = "/login";
        }
    });


    useEffect(()=>{
        if(resetToken !== ""){
            checkResetToken();
        }
    },[resetToken]);

    // APIS ================================================================================

    async function doReset(e){
        e.preventDefault();
        if(password.password === password.confirm_password && password.password !== "" && password.confirm_password !== ""){
            //proceed to call api
            const url = "client_api/account/resetPassword.php";
            const resetForm = new FormData();
            resetForm.append("token",resetToken);
            resetForm.append("newpassword1",password.password);
            resetForm.append("newpassword2",password.confirm_password);
            try {
                const response = await axiosInstance.post(url, resetForm);
                if (response.data.status === 201) {
                    window.alert("Password successfully reset! Click OK to be redirected to login page");
                    window.location.href = "/login";
                } else {
                    window.alert(response.data.message);
                }
            } catch (e) {
                console.log(e);
            }
        }else{
            window.alert("New password not match with confirm password!");
        }
    }

    async function checkResetToken(){
        const url = "client_api/account/checkPasswordResetToken.php";
        const resetForm = new FormData();
        resetForm.append("token",resetToken);
        try {
            const response = await axiosInstance.post(url, resetForm);
            if (response.data.status === 201) {
                //do nothing
            } else {
                window.location.href = "/login";
            }
        } catch (e) {
            console.log(e);
        }
    }

    // FUNCTIONS ================================================================================


    function handleOnChange(e,origin){
        let temp = {...password};
        if(origin === "password"){
            temp.password = e.target.value;
        }else if(origin === "confirm_password"){
            temp.confirm_password = e.target.value;
        }
        setPassword(temp);
    }


    // RENDERS ================================================================================

    return (
        <div>
            <HeaderBar isLogin={false} />
            <div className='loginBg'>
                <div className='container'>
                <div className='row'>
                    <div className='col-lg-2 col-md-2 col-sm-2 col-xs-4'></div>
                    <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8 login-form'>
                    <div className='text-center mt-5'>
                        <h3>Reset Password</h3>
                    </div>
                    {!isLoading ? (
                        <>
                            <Form onSubmit={doReset} noValidate>
                                <div className='form-group'>
                                <label htmlFor='pwd'>
                                    New Password<span className='mandatory'>*</span>:
                                </label>
                                <input
                                    type='password'
                                    className='form-control'
                                    autoComplete='current-password'
                                    value={password.password}
                                    onChange={(e) => {
                                        handleOnChange(e, "password");
                                    }}
                                />
                                </div>
                                <div className='form-group'>
                                <label htmlFor='pwd'>
                                    Confirm Password<span className='mandatory'>*</span>:
                                </label>
                                <input
                                    type='password'
                                    className='form-control'
                                    autoComplete='current-password'
                                    value={password.confirm_password}
                                    onChange={(e) => {
                                        handleOnChange(e, "confirm_password");
                                    }}
                                />
                                </div>

                                <button className='btn btn-block btn-primary btn-et'>
                                    Submit
                                </button>
                                <br />
                            </Form>
                        </>
                    ) : (
                        <div className='text-center'>
                        <CircularProgress />
                        </div>
                    )}
                    </div>

                    <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4'></div>
                </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
