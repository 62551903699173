import React, { useState, useEffect } from 'react';
import ErrorImg from "../../assets/images/error.png";
import "./style.css";


function redirection(){
    window.location.href="/";
}


function ErrorPage(){

    useEffect(()=>{
        document.title = "EPM - 404 Not Found";
    });

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col text-center">
                    <img src={ErrorImg} alt="Error Not Found" className="errorImg" />
                    <h5>Page not found, please click this button below to be redirected...</h5>
                    <button className="btn btn-md btn-primary" onClick={()=>{redirection() }}>Click Me</button>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage;