import React, {useState,useEffect} from "react";
import {EtTable} from "../shared/table/table";
import CircularProgress from '@mui/material/CircularProgress';
import {axiosInstance} from "../../config/apiTemplate";


export default function DivLog(props){

    const [logs,setLogs] = useState([]);
    const [response, setResponse] = useState({});
    const [isLoading, setLoading] = useState(true);


    useEffect(()=>{
        document.title = "EPM - Cash Dividends Logs Recycle Bin";
        setLoading(true);
        getLogs();

        return () =>{
            setLoading(false);  
        }
    },[props.trading_id,props.ts]);


    async function getLogs(){
        let headerForm = new FormData();
  
        headerForm.append("t_id", props.trading_id);
        const url = "api/bin/divlog.php";
    
        try {
          const response = await axiosInstance.post(url, headerForm);
          if (response.data.statusCode === 201) {
            let logs = response.data.logs;
            setLogs(logs);
            setResponse(response.data);
          }else if(response.data.statusCode === 500){
            getLogs();
          }
         setLoading(false);
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
    }

    function renderLoading(){
        return(
            <div className="text-center">
                <CircularProgress />
                <p>Fetching Data...</p>
            </div>
        )
    }

    async function deleteLog(id){
        let resp = window.confirm("Are you sure to recover this log entry?");
        if(resp){
            //delete log
        }
    }


    return(
        <div className="container-fluid pt-3">
            <div className="row">
                <div className="col">
                    <div className="card card-body">
                        {isLoading ?renderLoading():<div className="container-fluid">
                            <br/>
                            <EtTable 
                                data={logs}
                                sortable={true}
                                headers={[
                                    {display:"No",sort:"no"},
                                    {display:"Ex Date",sort:"exDate_clean",style:{minWidth:140}},
                                    {display:"Payment Date",sort:"payDate_clean",style:{minWidth:140}},
                                    {display:"Stock Name",sort:"stock_name"},
                                    // {display:"Quantity of Shares",sort:"quantity_clean"},
                                    {display:"Dividend Description",sort:"divDesc"},
                                    {display:"Div. Per Share",sort:"pershare_clean",style:{minWidth:140}},
                                    {display:"Taxes and Charges",sort:"tax_clean"},
                                    {display:"Net Dividend Amount",sort:"net_amount_clean",style:{minWidth:140}},
                                    {display:"Recover",sort:"remove"},
                                ]}
                                displayFieldKeys={[
                                    {key:"no"},
                                    {key:"exDate"},
                                    {key:"payDate"},
                                    {key:"stock_name",style:{"fontWeight":"bold"}},
                                    // {key:"quantity"},
                                    {key:"divDesc"},
                                    {key:"pershare",prepend:"RM "},
                                    {key:"tax",prepend:"RM "},
                                    {key:"net_amount",prepend:"RM "},
                                    {key:"remove",style:{"fontWeight":"bold",color:"green"},isClickable:true,clickAction:deleteLog,clickProp:{"token":"removeToken"}},
                                ]}
                                showPagination={true}
                            />
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );


}