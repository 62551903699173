import React, {useState,useEffect} from "react";
import {axiosInstance} from "../../config/apiTemplate";
import CircularProgress from '@mui/material/CircularProgress';
import {numberWithCommas, stripCommaToNum} from "../shared/util";
import Screen from "../shared/screen";
import Grid from '@mui/material/Grid';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

export default function RecordingPage(props){

    const [portfolio, setPortfolio] = useState(localStorage.getItem("t_id"));
    const [isLoading, setLoading] = useState(true);
    const [recordings, setRecordings] = useState([]);
    const [viewMode, setViewMode] = useState("list");
    const [currVideo, setCurrVideo] = useState("");

    useEffect(()=>{
        document.title = "ETSW - Recordings";

        getRecordings();
    },[portfolio]);

    function renderLoading(){
        return(
            <div className="text-center">
                <CircularProgress />
                <p>Fetching Data...</p>
            </div>
        )
    }

    function changePortfolio(t_id){
        setPortfolio(t_id);
    }    

    function invokeRefresh(){
        setLoading(true);
    }

    async function getRecordings(){
        let resetForm = new FormData();
        const url = "client_api/recordings/getRecordings.php";
        try {
            const response = await axiosInstance.post(url, resetForm);
            if (response.data.status === 201) {
                setRecordings(response.data.recordings);
            }
        } catch (e) {
            console.log(e);
        }
      }

    function renderBody(){
        return(
            <div className="row mt-3 px-4">
                <h5>Video Recordings</h5>
                {viewMode === "list" && (
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {recordings.map((recording, index) => (
                            <Grid item xs={4} key={index}
                                onClick={()=>{
                                    setCurrVideo(recording.vimeo_id);
                                    setViewMode("watch");
                                }}
                            >
                                <Card>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="300"
                                            style={{objectFit: 'cover', objectPosition: 'top'}}
                                            image={recording.thumbnail}
                                            alt="thumbnail"
                                        />
                                        <CardContent>
                                            <h5 style={{fontWeight:'bold'}}>{recording.description}</h5>
                                            <span style={{color:'gray'}}>{recording.date}</span>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}
                {viewMode === "watch" && (
                    <iframe src={`https://player.vimeo.com/video/${currVideo}`} width="100%" height="400" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                )}
                {/* <iframe src="https://player.vimeo.com/video/938142986" width="100%" height="400" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> */}
            </div>
        );
    }

    return(
        <div>
            <Screen origin="recordings" setPortfolio={changePortfolio} invokeRefresh={invokeRefresh}>
                <div className="container-fluid">
                    {renderBody()}
                </div>
                <br/>
            </Screen>
        </div>
    );
}