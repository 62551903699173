import React, {useState,useEffect} from "react";
import Screen from "../shared/screen";
import {axiosInstance} from "../../config/apiTemplate";

import "./style.css";

function MStatementPage(){

    const [portfolio, setPortfolio] = useState(localStorage.getItem("t_id"));
    const [isLoading, setLoading] = useState(true);
    const [months, setMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState("");
    const [statement, setStatement] = useState({header:{},gain_loss:{},management_fees:{}});

    useEffect(()=>{
        document.title = "ETSW - Monthly Statements";

        getMonths();
        // getBoiData();
    },[portfolio]);


    useEffect(()=>{
        if(selectedMonth !== ""){
            getStatement();
        }
    },[selectedMonth]);



    async function getMonths(){
        let dashboardForm = new FormData();
  
        const url = "client_api/statements/getMonths.php";
    
        try {
          const response = await axiosInstance.post(url, dashboardForm);
          if (response.data.status === 201) {
            setMonths(response.data.months);
            setSelectedMonth(response.data.months[response.data.months.length-1].value);
          }
         setLoading(false);
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
        console.log(statement.header);
    }

    async function getStatement(){
        let dashboardForm = new FormData();
  
        const url = "client_api/statements/getMonthlyStatements.php";
        dashboardForm.append("date",selectedMonth);

        try {
          const response = await axiosInstance.post(url, dashboardForm);
          if (response.data.status === 201) {
            setStatement(response.data.statement);
          }
         setLoading(false);
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
    }   

    function getStringByMonthValue(){
        let filtered = months.filter((item)=>item.value === selectedMonth);
        if(filtered.length > 0)
            return filtered[0].string;
    }


    function renderBody(){
        return(
            <div className="row mt-3">
                <div className="col">
                    <div className="container-fluid">
                        <div className="statusCardHeader row align-items-center justify-content-between" onClick={()=>{

                        }}>
                            <div className="col">
                                <h6 className="statusCardHeaderTitle">MONTHLY STATEMENT REPORT</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col bg-white" style={{marginLeft:15,marginRight:15,minHeight:300}}>
                                <div style={{display:"flex",flexDirection:"row",marginTop:10,alignItems:"center"}}>
                                    <div style={{flex:0.2}}>
                                        <h5 style={{margin:0}}>Select Period :</h5>
                                    </div>
                                    <div style={{flex:0.4}}>
                                        <select className="form-control" value={selectedMonth} onChange={(e)=>{setSelectedMonth(e.target.value)}}>
                                            {
                                                months.map((item,index)=>{
                                                    return(
                                                        <option value={item.value} key={index}>{item.string}</option>
                                                    )
                                                })
                                            }
                                            
                                        </select>
                                    </div>
                                </div>
                                <br/>
                                {Object.keys(statement.header).length === 0
                                ?
                                    // DIsplay no data
                                    <div className="container-fluid">
                                        <h1 style={{marginTop:15,textAlign:'center',color:'rgba(0, 0, 0, 0.6)'}}>No Data</h1>
                                    </div>
                                :
                                    <div className="container-fluid">
                                        <div className="row" >
                                            <div className="col text-center" style={{position:"relative"}}>
                                                <h4>Statement For - {getStringByMonthValue()}</h4>
                                                <div style={{position:"absolute",right:15,bottom:10}}>
                                                    <p className="marginBottomZero">{statement.header.beginning_period} - {statement.header.ending_period}</p> 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className="leftAlign">Balance at Beginning of Period</td>
                                                            <td className="RightAlign">RM {statement.header.balance_at_beginning}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="leftAlign">Balance at End of Period</td>
                                                            <td className="RightAlign">RM {statement.header.balance_at_end}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="leftAlign">No. of Days</td>
                                                            <td className="RightAlign">{statement.header.no_of_days}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="statusCardHeader row align-items-center justify-content-between" onClick={()=>{

                                        }}>
                                            <div className="col">
                                                <h6 className="statusCardHeaderTitle">MONTHLY GAIN/LOSS</h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className="leftAlign">Money Market</td>
                                                            <td className="RightAlign">RM {statement.gain_loss.interest_income}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="leftAlign">Dividend</td>
                                                            <td className="RightAlign">RM {statement.gain_loss.dividend_income}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="leftAlign">Realised / Unrealised Gain or (Loss)</td>
                                                            <td className="RightAlign" style={{color:statement.gain_loss.unformatted_gain_or_loss > 0?"green":"red"}}>{statement.gain_loss.unformatted_gain_or_loss > 0 ? `RM ${statement.gain_loss.gain_or_loss}`:`(RM ${statement.gain_loss.gain_or_loss})`}</td>
                                                        </tr>
                                                        <tr style={{borderTop:"solid 2px black",borderBottom:"solid 2px black"}}>
                                                            <td className="leftAlign">Sub Total</td>
                                                            <td className="RightAlign" style={{color:statement.gain_loss.unformatted_sub_total > 0?"green":"red"}}>{statement.gain_loss.unformatted_sub_total > 0 ? `RM ${statement.gain_loss.sub_total}` : `(RM ${statement.gain_loss.sub_total})`} </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="statusCardHeader row align-items-center justify-content-between" onClick={()=>{

                                        }}>
                                            <div className="col">
                                                <h6 className="statusCardHeaderTitle">FEES AND CHARGES</h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className="leftAlign">Management Fee</td>
                                                            <td className="RightAlign" style={{color:"red"}}>(RM {statement.management_fees.management_fee})</td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td className="leftAlign">Sales and Service Tax (SST)</td>
                                                            <td className="RightAlign" style={{color:"red"}}>(RM {statement.management_fees.sst})</td>
                                                        </tr> */}
                                                        <tr>
                                                            <td className="leftAlign">Total Trading Fees</td>
                                                            <td className="RightAlign" style={{color:"red"}}>(RM {statement.management_fees.brokerage_fees})</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="leftAlign">Custodian Fee</td>
                                                            <td className="RightAlign" style={{color:"red"}}>(RM {statement.management_fees.custodian_fee || 0.00})</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="leftAlign">Custodian Trading Fee</td>
                                                            <td className="RightAlign" style={{color:"red"}}>(RM {statement.management_fees.custodian_trading_fee || 0.00})</td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td className="leftAlign">Annual Performance Fee</td>
                                                            <td className="RightAlign" style={{color:"red"}}>(RM {statement.management_fees.annual_performance_bonus})</td>
                                                        </tr> */}
                                                        <tr style={{borderTop:"solid 2px black",borderBottom:"solid 2px black"}}>
                                                            <td className="leftAlign">Sub Total</td>
                                                            <td className="RightAlign" style={{color:"red"}}>(RM {statement.management_fees.sub_total})</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col">
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        <tr style={{borderTop:"solid 2px black",borderBottom:"solid 2px black"}}>
                                                            <td className="leftAlign">Net Gains / (Loss) for Month</td>
                                                            <td className="RightAlign" style={{color:statement.management_fees.unformatted_disp_c > 0 ?"green":"red"}}>RM {statement.management_fees.gains_loss}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function changePortfolio(t_id){
        setPortfolio(t_id);
    }    

    function invokeRefresh(){
        setLoading(true);
    }


    return(
        <div>
            <Screen origin="statement" setPortfolio={changePortfolio} invokeRefresh={invokeRefresh}>
                <div className="container-fluid">
                    {renderBody()}
                </div>
                <br/>
            </Screen>
        </div>
    )

}

export default MStatementPage;