import React, { useState, useEffect } from "react";
import HeaderBar from "../shared/header";
import Footer from "../shared/footer";
import { Modal, Button, Form } from "react-bootstrap";
import { axiosInstance, axiosJwt } from "../../config/apiTemplate";
import CircularProgress from "@mui/material/CircularProgress";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function handleOnChange(e, origin, user, setUser) {
  switch (origin) {
    case "email":
      setUser({ ...user, email: e.target.value });
      break;
    case "password":
      setUser({ ...user, password: e.target.value });
      break;
    case "name":
      setUser({ ...user, name: e.target.value });
    case "phoneNumber":
      setUser({ ...user, phoneNumber: e.target.value });
    case "preferredCallTime":
      setUser({ ...user, preferredCallTime: e.target.value });
    default:
      break;
  }
}

function LoginPage() {
  let [user, setUser] = useState({ email: "", password: "" });
  let [reset2faDetails, setReset2faDetails] = useState({ name: "", phoneNumber: "", preferredCallTime: "" });
  let [showPasswordModal, setShowPasswordModal] = useState(false);
  let [show2faResetModal, setShow2faResetModal] = useState(false);
  let [showMfaModal, setShowMfaModal] = useState(false);
  let [tempResponse, setTempResponse] = useState({});
  let [hideButton, setHideButton] = useState(false);
  let [mfaInput, setMfaInput] = useState("");
  let [isLoading, setLoading] = useState(false);
  let [jailTimer, setJailTimer] = useState(10);
  let [isViewPassword,setIsViewPassword] = useState(false);

  useEffect(() => {
    document.title = "EPM - Login";

    // if (localStorage.getItem("t_id") && localStorage.getItem("token")) {
    //   window.location.href = "/dashboard";
    // }
  });

  useEffect(() => {
    const interval = setInterval(() => {
        setJailTimer(jailTimer - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [jailTimer]);

  async function doLogin(e) {
    console.log(e);
    e.preventDefault();
    setLoading(true);
    if (user.email === "" || user.password === "") {
      alert("Email or Password cannot be empty!");
      setLoading(false);
    } else {
      let loginForm = new FormData();
      loginForm.append("email", user.email);
      loginForm.append("password", user.password);
      const url = "https://api.et-invest.com/client_api/authenticate.php";

      let productView = "";

      try {
        const response = await axiosJwt.post(url, loginForm);
        if (response.data.status === 201) {
          if(response.data.member_data.is_2fa){
            //means onboarded, then need to request code, else onboard
            setTempResponse(response.data);
            setShowMfaModal(true);
            setLoading(false);

            if(!localStorage.getItem("productView") || localStorage.getItem("productView") === undefined){
              localStorage.setItem("productView",response.data.availableProducts[0]);
              productView = response.data.availableProducts[0];

              if(response.data.availableProducts[0] === "Aggregated"){
                // window.location.href = "/aggregated";
              }
            }
            else{
              // Check if productView is Aggregated but the user is not on Aggregated page, update productView to next product after Aggregated
              if(localStorage.getItem("productView") === "Aggregated" && origin !== "aggregated"){
                let index = response.data.availableProducts.indexOf("Aggregated");
                if(index !== -1){
                  localStorage.setItem("productView",response.data.availableProducts[index+1]);
                  productView = response.data.availableProducts[index+1];
                }
                else{
                  localStorage.setItem("productView",response.data.availableProducts[0]);
                  productView = response.data.availableProducts[0];
                }
              }
            }
          }else{
            // perform calling api to onboard later
            localStorage.setItem("email", user.email);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("rt", response.data.refresh_token);
            localStorage.setItem("t_id", response.data.member_data.trading_ac_id);
            localStorage.setItem("clientCode",response.data.member_data.client_code);
            localStorage.setItem("name", response.data.member_data.firstname);
            localStorage.setItem("mfa_boarded", "false");

            if(!localStorage.getItem("productView") || localStorage.getItem("productView") === undefined){
              localStorage.setItem("productView",response.data.availableProducts[0]);
              productView = response.data.availableProducts[0];

              if(response.data.availableProducts[0] === "Aggregated"){
                // window.location.href = "/aggregated";
              }
            }
            else{
              // Check if productView is Aggregated but the user is not on Aggregated page, update productView to next product after Aggregated
              if(localStorage.getItem("productView") === "Aggregated" && origin !== "aggregated"){
                let index = response.data.availableProducts.indexOf("Aggregated");
                if(index !== -1){
                  localStorage.setItem("productView",response.data.availableProducts[index+1]);
                  productView = response.data.availableProducts[index+1];
                }
                else{
                  localStorage.setItem("productView",response.data.availableProducts[0]);
                  productView = response.data.availableProducts[0];
                }
              }
            }

            if(productView == "Aggregated"){
              window.location.href = "/aggregated?v=1";
            }
            else{
              window.location.href = "/dashboard?v=1";
            }
            
          }
        } else {
          alert(response.data.message);
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
  }

  async function authMfa(e){
    e.preventDefault();
    setLoading(true);
    if(hideButton){
        return;
    }
    if(mfaInput === ""){
        window.alert("2FA Code cannot be empty!");
        setLoading(false);
    }else{
        let loginForm = new FormData();
        loginForm.append("token", tempResponse.token);
        loginForm.append("inc_code", mfaInput);
        loginForm.append("isInitial","false");
        const url = "https://api.et-invest.com/client_api/account/verify2fa.php";
  
        try {
          const response = await axiosJwt.post(url, loginForm);
          if (response.data.status === 201) {
            if(response.data.authenticated){
                let productView = localStorage.getItem("productView");

                localStorage.setItem("email", user.email);
                localStorage.setItem("token", tempResponse.token);
                localStorage.setItem("rt", tempResponse.refresh_token);
                localStorage.setItem("t_id", tempResponse.member_data.trading_ac_id);
                localStorage.setItem("clientCode",tempResponse.member_data.client_code);
                localStorage.setItem("name", tempResponse.member_data.firstname);
                localStorage.setItem("mfa_boarded", "true");

                console.log(productView);

                if(productView === "Aggregated"){
                  window.location.href = "/aggregated?v=1";
                }
                else{
                  window.location.href="/dashboard?v=1";
                }
            }else{
                window.alert("Incorrect 2FA code supplied! Please try again in 10s later.");
                setHideButton(true);
                setLoading(false);
                setTimeout(()=>{
                    setHideButton(false);
                },10000);
                setJailTimer(10);
            }
          } else {
            alert(response.data.message);
            setLoading(false);
          }
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
    }
  }

  async function requestPasswordReset(){
    let resetForm = new FormData();
    resetForm.append("email", user.email);
    resetForm.append("reset_action", "resetPassword");
    const url = "client_api/account/requestPasswordChange.php";
    try {
        const response = await axiosInstance.post(url, resetForm);
        if (response.data.status === 201) {
            window.alert(response.data.message);
            setShowPasswordModal(false);
        }
    } catch (e) {
        console.log(e);
    }
  }

  async function submit2faReset(){
    let resetForm = new FormData();
    resetForm.append("email", user.email);
    resetForm.append("name", reset2faDetails.name);
    resetForm.append("phone_number", reset2faDetails.phoneNumber);
    resetForm.append("preferred_call_time", reset2faDetails.preferredCallTime);

    const url = "client_api/account/request2faReset.php";
    try {
        const response = await axiosInstance.post(url, resetForm);
        if (response.data.status === 201) {
            window.alert(response.data.message);
            setShow2faResetModal(false);
        }
    } catch (e) {
        console.log(e);
    }
  }

  function renderPasswordModal() {
    return (
      <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-group'>
            <label>
              Email<span className='mandatory'>*</span>
            </label>
            <input
              type='text'
              className='form-control'
              autoComplete='username'
              value={user.email}
              onChange={(e) => {
                handleOnChange(e, "email", user, setUser);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowPasswordModal(false)}>
            Close
          </Button>
          <Button variant='primary' onClick={() => requestPasswordReset()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function render2faResetModal(){
    return (
      <Modal show={show2faResetModal} onHide={() => setShow2faResetModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Request 2FA Reset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-group'>
            <label>
              Email<span className='mandatory'>*</span>
            </label>
            <input
              type='text'
              className='form-control'
              autoComplete='username'
              value={user.email}
              onChange={(e) => {
                handleOnChange(e, "email", user, setUser);
              }}
              required
            />
          </div>
          <div className='form-group'>
            <label>
              Name<span className='mandatory'>*</span>
            </label>
            <input
              type='text'
              className='form-control'
              value={reset2faDetails.name}
              onChange={(e) => {
                setReset2faDetails({ ...reset2faDetails, name: e.target.value });
              }}
              required
            />
          </div>
          <div className='form-group'>
            <label>
              Phone Number<span className='mandatory'>*</span>
            </label>
            <input
              type='text'
              className='form-control'
              value={reset2faDetails.phoneNumber}
              onChange={(e) => {
                setReset2faDetails({ ...reset2faDetails, phoneNumber: e.target.value });
              }}
              required
            />
          </div>
          <div className='form-group'>
            <label>
              Preferred Call Time<span className='mandatory'>*</span>
            </label>
            <select
              className='form-control'
              value={reset2faDetails.preferredCallTime}
              onChange={(e) => {
                setReset2faDetails({ ...reset2faDetails, preferredCallTime: e.target.value });
              }}
              required
            >
              <option value="">Please select</option>
              <option value="asap">As soon as possible</option>
              <option value="09:00">9:00 am</option>
              <option value="09:30">9:30 am</option>
              <option value="10:00">10:00 am</option>
              <option value="10:30">10:30 am</option>
              <option value="11:00">11:00 am</option>
              <option value="11:30">11:30 am</option>
              <option value="12:00">12:00 pm</option>
              <option value="12:30">12:30 pm</option>
              <option value="13:00">1:00 pm</option>
              <option value="13:30">1:30 pm</option>
              <option value="14:00">2:00 pm</option>
              <option value="14:30">2:30 pm</option>
              <option value="15:00">3:00 pm</option>
              <option value="15:30">3:30 pm</option>
              <option value="16:00">4:00 pm</option>
              <option value="16:30">4:30 pm</option>
              <option value="17:00">5:00 pm</option>
              <option value="17:30">5:30 pm</option>
              <option value="18:00">6:00 pm</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShow2faResetModal(false)}>
            Close
          </Button>
          <Button variant='primary' onClick={() => submit2faReset()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  function request2faReset(){
    setShow2faResetModal(true);
  }

  return (
    <div>
      <HeaderBar isLogin={false} />
      <div className='loginBg'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-2 col-md-2 col-sm-2 col-xs-4'></div>
            <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8 login-form'>
              <div className='text-center mt-5'>
                <h3>Login</h3>
              </div>
              {!isLoading ? (
                <>
                  {!showMfaModal && <Form onSubmit={doLogin} noValidate>
                    <div className='form-group'>
                      <label htmlFor='email'>
                        Email Address:
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        autoComplete='username'
                        value={user.email}
                        onChange={(e) => {
                          handleOnChange(e, "email", user, setUser);
                        }}
                      />
                    </div>
                    <p className="marginBottomZero">Password<span className='mandatory'>*</span>:</p>
                    <div className='input-group mb-3'>
                        <input
                          type={isViewPassword?'text':'password'}
                          className='form-control'
                          autoComplete='current-password'
                          value={user.password}
                          onChange={(e) => {
                            handleOnChange(e, "password", user, setUser);
                          }}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-outline-secondary" type="button" onClick={()=>setIsViewPassword(!isViewPassword)}>
                            {isViewPassword?<VisibilityOffIcon fontSize="13" />:<VisibilityIcon fontSize="13"/>}
                          </button>
                         
                        </div>
                    </div>

                    <button
                      className='btn btn-block btn-primary btn-et'>
                      Submit
                    </button>
                    <br />
                    <div className='row justify-content-end'>
                      <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3 smaller-bp'></div>

                      <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2 smaller-bp'></div>

                      <div
                        className='col-lg-5 col-md-5 col-sm-5 col-xs-5 smaller-bp'
                        style={{ textAlign: "right" }}>
                        <p 
                          onClick={()=>{ setShowPasswordModal(true); }}
                          className='cursorHover marginBottomZero'>
                          Forgot Password?
                        </p>
                      </div>
                    </div>
                  </Form>}
                  {showMfaModal && <Form onSubmit={authMfa} noValidate>
                    <div className='form-group'>
                      <label htmlFor='2fa'>
                        2FA Code<span className='mandatory'>*</span>:
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        autoFocus={true}
                        autoComplete='2fa'
                        value={mfaInput}
                        onChange={(e) => {
                            setMfaInput(e.target.value);
                        }}
                      />
                      <a href="#" style={{color:'red'}} onClick={(e)=>request2faReset()}>Having issues? Send a request to reset your 2FA</a>
                    </div>
                    {!hideButton && <button
                      className='btn btn-block btn-primary btn-et'
                    >
                      Submit
                    </button>}
                    {hideButton && <div className="text-center">
                        {`Please wait for ${jailTimer} seconds`}
                    </div>}
                  </Form>}
                </>
              ) : (
                <div className='text-center'>
                  <CircularProgress />
                </div>
              )}
            </div>

            <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4'></div>
          </div>
        </div>
      </div>
      {renderPasswordModal()}
      {render2faResetModal()}
      <Footer />
    </div>
  );
}

export default LoginPage;
