import moment from "moment";

export function numberWithCommas(x,decimals=3){
    let decpoint = '.' // Or Number(0.1).toLocaleString().substring(1, 2)
    let thousand = ',' // Or Number(10000).toLocaleString().substring(2, 3)
    if(typeof x === "string")
        x = x.replaceAll(",","");
    x = Number(x);
    let n = Math.abs(x).toFixed(decimals).split('.')
    n[0] = n[0].split('').reverse().map((c, i, a) =>
    i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c
    ).reverse().join('')
    let final = (Math.sign(x) < 0 ? '-' : '') + n.join(decpoint)

    return final;
}

export function stripCommaToNum(x){
    if(typeof x === "string"){
        if(x.split(" ").length > 1){
            let split = x.split(" ");
            let replacingX = split[1];
            if(Number(replacingX) === 0){
                return "";
            }else{
                return Number(replacingX.replaceAll(",",""));
            }
        }else if(x === "" || x === "0"){
            return "";
        }else{
            if(isNaN(Number(x[0])) && x[0] !== "-"){
                let charArray = x.split(".");
                if(charArray.length > 1){
                    return Number("0."+charArray[1]);
                }else{
                    return "";
                }
            }else{
                return Number(x.replaceAll(",",""));
            }
        }
    }else{
        return x;
    }
}

export function getLastDateOfMonth(y,m){
    let year = new Date(y,m+1,0).getFullYear();
    let month = ("0"+(new Date(y, m +1, 0).getMonth()+1)).slice(-2);
    let lastDate = new Date(y, m +1, 0).getDate();

    //check if this month end is bigger than current timestamp;
    if(new Date().getTime() > new Date(year+"-"+month+"-"+lastDate).getTime()){
        return year+"-"+month+"-"+lastDate ;   
    }else{
        let globalDate = new Date();
        return globalDate.getFullYear()+"-"+("0"+(globalDate.getMonth()+1)).slice(-2)+"-"+("0"+globalDate.getDate()).slice(-2)
    }
}

export function renderDate(props){
    return(
        <span className="boldText" style={{fontSize:18,width:"100%"}}>{moment(props.value).format("DD-MM-YYYY")}</span>
    );
}

export function formatToDateObj(date){
    return moment(date,"DD-MM-YYYY").toDate();
}