import React, {useState, useEffect} from "react";
import ETLogo from "../../assets/images/logo.png";
import "./style.css";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {
    withStyles,
  } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from "@mui/icons-material/Edit";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from "@mui/material/Tooltip";
import {axiosInstance} from "../../config/apiTemplate";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import BusinessIcon from '@mui/icons-material/Business';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';


const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
        '&::placeholder': {
            color: 'white',
          },
      },
    },
  })(TextField);

const portfolios = [
    { trading_ac_id: 1, display_name: 'Kenny Ng', client_code: 'EPM0001', broker:"EPMBROKER" },
  ];


function logout(){
   let resp = window.confirm("Are you sure to logout?");
   if(resp){
      localStorage.clear();
      window.location.href = "/login";
   }
}


function HeaderBar(props){
    const name = localStorage.getItem("name");
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
      });
    const [portfolios,setPortfolios] = useState([]);
    const [portfolio,setPortfolio] = useState({});
    const [showViewSwitchModal,setShowViewSwitchModal] = useState(false);
    const { products } = props;

    let productView = localStorage.getItem("productView");
    let localClientCode = localStorage.getItem("clientCode");

    useEffect(()=>{
        //call api
        async function getPortfolios(){
          let loginForm = new FormData();

          const url = "client_api/profile/getAllPortfolios.php";
      
          try {
            const response = await axiosInstance.post(url, loginForm);
            if (response.data.status === 201) {
              setPortfolios(response.data.portfolios);
              
              let clientCodeFound = false;
              let portfolio = response.data.portfolios.filter((item)=>{
                // if(item.trading_ac_id === localStorage.getItem("t_id")){
                //   return item;
                // }
                if(item.client_code === localClientCode){
                  clientCodeFound = true;
                  return item;
                }
              });

              if(!clientCodeFound){
                portfolio = response.data.portfolios[0];
                localStorage.setItem("t_id",portfolio.trading_ac_id);
                localStorage.setItem("clientCode",portfolio.client_code);
                setPortfolio(portfolio);
              }
              else{
                setPortfolio(portfolio[0]);
              }
            }else if(response.data.status === 500){
              //recall this function again;
              getPortfolios();
            }
          } catch (e) {
            console.log(e);
          }
        }

        props.isLogin && getPortfolios();
        
        function handleResize(){
            setWindowSize({
                width:window.innerWidth
            })
        }

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    },[]);

     function handleOnChange(v){
      setPortfolio(v);
      localStorage.setItem("t_id",v.trading_ac_id);
      localStorage.setItem("clientCode",v.client_code);
      if(props.setPortfolio){
        props.setPortfolio(v.trading_ac_id);
      }
     }


      function renderDropdown(){
          return(
            productView !== "Aggregated" && (
              <Autocomplete
                id="select-portfolio"
                className="portfolioDropDown"
                options={portfolios}
                autoHighlight
                disableClearable
                value={portfolio}
                onChange={(e,v)=>{ handleOnChange(v);}}
                popupIcon={<ArrowDropDownIcon style={{color:"white"}}/>}
                getOptionLabel={(option) => option.display_name+" ("+option.client_code+")"}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    label="Choose Your Portfolio"
                    variant="outlined"
                    className="dropdownTextField"
                    InputLabelProps={{style:{color:"white"}}}
                    inputProps={{
                      ...params.inputProps,
                      style:{
                        color:"white"
                    },
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )
          )
      }


    return(
        <nav className="navbar navbar-expand-lg navbar-light navbarCustom">
            <a className="navbar-brand" href="/">
                <img src={ETLogo} alt="EquitiesTracker" className="headerLogo" />
            </a>
            {props.isLogin && <>
            <button className="navbar-toggler bg-light" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto align-items-center">
                <hr className="bg-white fullWidth" />
                {/* <li className="nav-item text-center">
                    <p className="nav-link text-white text-bold marginZero" style={{minWidth:190}} href="#">Welcome, {name} <span className="sr-only">(current)</span></p>
                </li> */}
                {/* <hr className="bg-white fullWidth" /> */}
                <li className="nav-item mr-2">
                    {renderDropdown()}
                </li>
                {
                  productView !== "Aggregated" && (
                    <li className="nav-item">
                      <Tooltip title="Edit Portfolio Name">
                          <IconButton color="white" onClick={()=>window.location.href="/account"}>
                              <EditIcon />
                          </IconButton>
                      </Tooltip>
                    </li>
                  )
                }
                
                <li className="nav-item">
                  <button className="btn btn-md text-white" style={{backgroundColor:'#00A3E0',width:'150px',height:'56px',marginLeft:10}} onClick={()=>setShowViewSwitchModal(true)}>
                    Select Strategy
                  </button>
                </li>
                
                {/* <li className="nav-item mr-2 text-white text-center" style={{minWidth:'200px'}}>
                    Current View: Snowball
                </li> */}
                </ul>
                {/* <hr className="bg-white fullWidth" />
                <div className="chipGreen float-right">
                  <h6 className="marginBottomZero boldText">+ RM 1000</h6>
                </div> */}
                <hr className="bg-white fullWidth" />
                <div className="text-center">
                    {windowSize.width > 991 && <ExitToAppIcon className="text-white sign-out" onClick={logout} />}
                    {windowSize.width <= 991 && <button className="btn btn-md btn-danger" onClick={logout}>LOG OUT</button>}
                </div>
            </div>
            </>}

            <Dialog onClose={() => setShowViewSwitchModal(false)} open={showViewSwitchModal}>
                <DialogTitle>Select Strategy</DialogTitle>
                <List sx={{ pt: 0 }}>
                    {products?.map((product) => (
                        <ListItem disableGutters key={product}>
                            <ListItemButton onClick={() => {
                                props.setProductView(product);
                                setShowViewSwitchModal(false);
                                localStorage.setItem("logIndex",0);
                            }}>
                                <ListItemAvatar style={{minWidth:'40px'}}>
                                    {product === "Aggregated" && <MergeTypeIcon />}
                                    {product === "Snowball" && <BusinessIcon />}
                                    {product === "Digital Asset Tracker" && <CurrencyBitcoinIcon />}
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={
                                      product === "Snowball"
                                      ?
                                      "Snowball Strategy"
                                      :
                                      product === "Digital Asset Tracker"
                                      ?
                                      "ET Digital Assets Tracker"
                                      :
                                      product === "Aggregated"
                                      ?
                                      "Show All"
                                      :
                                      ""
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Dialog>

        </nav>
    )
}

export default HeaderBar;