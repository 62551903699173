import React, {useState,useEffect} from "react";
import Screen from "../shared/screen";
import {axiosInstance} from "../../config/apiTemplate";
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DataGridPro,GridToolbarContainer,GridToolbarColumnsButton,GridToolbarFilterButton,GridToolbarExport,GridToolbarDensitySelector, GridOverlay } from "@mui/x-data-grid-pro";
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress  from "@mui/material/LinearProgress";
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import "./style.css";

function ReportsPage(){

    const [portfolio, setPortfolio] = useState(localStorage.getItem("t_id"));
    const [tabIndex, setTabIndex] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [reports, setReports] = useState([]);

    useEffect(()=>{
        document.title = "ETSW - Reports";
        getReports();
    },[portfolio,tabIndex]);





    async function getReports(){
        setLoading(true);
        let dashboardForm = new FormData();
        dashboardForm.append("type",tabIndex);
        dashboardForm.append("t_id",portfolio);

        const url = "client_api/reports/getFileReports.php";
    
        try {
            const response = await axiosInstance.post(url, dashboardForm);
            if (response.data.status === 201) {
                setReports(response.data.reports);
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    } 

    async function downloadFile(val){
        setLoading(true);
        let dashboardForm = new FormData();
        dashboardForm.append("filename",val.value);
        dashboardForm.append("t_id",portfolio);
        dashboardForm.append("type",tabIndex);

        const url = "client_api/reports/getAuthUrl.php";
    
        try {
            const response = await axiosInstance.post(url, dashboardForm);
            if (response.data.status === 201) {
                window.open(response.data.url,"_blank");
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    async function requestImaFile(){
        setLoading(true);
        let dashboardForm = new FormData();
        dashboardForm.append("t_id",portfolio);
        const url = "client_api/reports/requestImaFile.php";
    
        try {
            const response = await axiosInstance.post(url, dashboardForm);
            if (response.data.status === 201) {
                window.alert("IMA file requested successfully. Please check your email inbox.");
            }else{
                window.alert("IMA file not ready, please request in next business day.");
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    // functions 

    function prepareTableRows(){
        return reports.map((item,index)=>{
            return {...item,id:index,no:index+1};
        });
    }




    // renders

    function renderTabs(){
        return (
            <Paper>
              <Tabs
                className="logTabs"
                value={tabIndex}
                onChange={(e,val)=>{
                    setTabIndex(val);
                }}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{
                    style: {
                      backgroundColor: "#191d63",
                    },
                  }}
              >
                <Tab label="IMA" />
                <Tab label="Balance Sheet" />
                <Tab label="Customer Statement" />
                {/* <Tab label="Equity Purchased" /> */}
                <Tab label="Investment Portfolio" />
                <Tab label="Income & Expenditures" />
                {/* <Tab label="Money Market" /> */}
              </Tabs>
            </Paper>
        );
    }

    function renderLoading(){
        return(
            <div className="text-center">
                <CircularProgress />
                <p>Fetching Data...</p>
            </div>
        )
    }

    function renderCustomToolbar(){
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                {/* <GridToolbarExport /> */}
            </GridToolbarContainer>
        );
    }

    function renderNoRows(){
        return(
            <GridOverlay>
                <div>
                    <h6 style={{fontStyle:"italic"}}>No Transactions....</h6>
                </div>
            </GridOverlay>
        )
    }

    function loadingOverlay(){
        return(
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
                </div>
            </GridOverlay>
        )
    }

    function renderActionCell(props){
        return(
            <div>
                <IconButton color="primary" onClick={()=>{
                    downloadFile(props);
                }}>
                    <DownloadIcon />
                </IconButton>
            </div>
        )
    }

    function renderBody(){
        return(
            <div className="row mt-3">
                <div className="col">
                    {isLoading?renderLoading():<div style={{minHeight:"65vh",display:"flex"}}>
                        <div style={{ flexGrow: 1, backgroundColor: "white", marginTop: 20 }}>
                            <DataGridPro
                                density="compact"
                                loading={isLoading}
                                columns={[
                                {
                                    field: "no",
                                    headerName: "No.",
                                    flex: 1,
                                },
                                {
                                    field: "report_name",
                                    headerName: "Report Name",
                                    minWidth: 150,
                                    flex: 2,
                                },
                                {
                                    field: "report_url",
                                    headerName: "Action",
                                    minWidth: 150,
                                    flex: 2,
                                    renderCell:renderActionCell
                                },
                                ]}
                                
                                pagination
                                rows={prepareTableRows()}
                                components={{
                                    Toolbar: renderCustomToolbar,
                                    LoadingOverlay: loadingOverlay,
                                    NoRowsOverlay: renderNoRows
                                }}
                                
                            />
                            </div></div>}
                </div>  
            </div>
        )
    }

    function renderImaButton(){
        return(
            <div className="text-center mt-5">
                {reports.length > 0 && <Button variant="contained" color="primary" onClick={()=>{
                    requestImaFile();
                }}>Request for IMA</Button>}
                {reports.length === 0 && <p>IMA not ready</p>}
            </div>
        )
    }

    function changePortfolio(t_id){
        setPortfolio(t_id);
    }    

    function invokeRefresh(){
        setLoading(true);
        getReports();
    }


    return(
        <div>
            <Screen origin="reports" setPortfolio={changePortfolio} invokeRefresh={invokeRefresh}>
                <div className="container-fluid">
                    <div className="mt-3 mb-3">
                        {renderTabs()}
                    </div>
                    {tabIndex !== 0 && renderBody()}
                    {tabIndex === 0 && renderImaButton()}
                </div>
                <br/>
            </Screen>
        </div>
    )

}

export default ReportsPage;